import React from 'react';
import './DiagnoseAndCureDealPage.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';
import FooterAddition from '../../footer/FooterAddition';
import blogImage from '../../../content/blog/diagnoseandcuredeal/DiagnoseAndCureDeal.png';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';

const DiagnoseAndCureDealPage: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
        <Container fluid className='justify-content-center'>

            <Row style={{paddingLeft: "20px", paddingTop: "50px", paddingRight: "20px", paddingBottom: "40px"}} className='justify-content-center'>
                <Row>
                    <Col className='diagnose-and-cure-deal-title'><h2>Diagnose and Cure <span className="main-color">Deal Stalls</span></h2></Col>
                </Row>
                <Row style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={blogImage} className="diagnose-and-cure-deal-title-image" alt="diagnoseAndCureDealImage" />
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>Deals stall.</b></h5>
                    <p>
                        It’s just how it is. How we look at where and why deals stall is often neglected.
                        Studies show that up to 60% of pipeline deals end up stalling at some point in the sales cycle.[^1] The key to unlocking those stuck opportunities? Taking a hard look at your sales process.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>Uncover the Hidden Bottlenecks with 'Time by Stage'</b></h5>
                    <p>
                        One of the most insightful ways to analyze your sales process is by examining the "time by stage" metrics. This means tracking how long deals tend to spend in each phase of your sales cycle - from initial contact to close.
                    </p>
                    <p>
                        When you start to see unusual spikes in time spent at certain stages, that's your cue that something is amiss. According to a SiriusDecisions report, the average B2B sales cycle is 102 days, but high-performing sales teams are able to reduce that to just 84 days.[^2] So if your deals are taking significantly longer than that industry benchmark, you've got some work to do.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>Prescribe the Right Remedies for Stalled Deals</b></h5>
                    <p>
                        Once you've identified the problem areas in your sales cycle, it's time to start troubleshooting. Here are some common "time by stage" challenges and how to fix them:
                        <br/><br/>
                        <b>Challenge:</b> Demo Stage Stall
                        <br/>
                        <b>Symptoms:</b> Demos are taking way too long, and deals are getting stuck in this phase.
                        <br/>
                        <b>Solution:</b> Revisit your demo structure and content. Are you overwhelming prospects with too much information? Research shows that the optimal demo length is just 18-25 minutes.[^3] Streamline your presentation to hit the key value points efficiently. Empower reps to take a more conversational, discovery-driven approach.
                        
                        <br/><br/>
                        <b>Challenge:</b> Proposal Paralysis 
                        <br/>
                        <b>Symptoms:</b> Proposals are sitting idle for weeks without movement.
                        <br/>
                        <b>Solution:</b> Analyze where the bottlenecks occur. Are legal/procurement teams slowing things down? A survey by IACCM found that contract negotiations added an average of 24 days to the sales cycle.[^4] See if you can get faster legal/contract approvals. Are reps failing to follow up promptly? Implement better cadence management and accountability.

                        <br/><br/>
                        <b>Challenge:</b> Evaluation Elongation
                        <br/>
                        <b>Symptoms:</b> Deals are getting stuck in the evaluation phase, with prospects taking forever to make a decision.
                        <br/>
                        <b>Solution:</b> Dig into what's causing the delay. Are there unanswered technical questions? Schedule follow-up calls to address concerns. Are there multiple stakeholders that need to sign off? A study by Gartner found that the average B2B buying group has 6-10 decision makers involved.[^5] Map the buying committee and apply pressure at each level.

                        <br/><br/>
                        <b>Challenge:</b> Close Call Conundrum
                        <br/>
                        <b>Symptoms:</b> Deals that were almost across the finish line suddenly stall out.
                        <br/>
                        <b>Solution:</b> Revisit your closing process. Are reps skillfully handling objections? Research by Gong.io shows that top-performing reps are 2.8x more effective at handling objections.[^6] Provide additional training on overcoming last-minute roadblocks. Are there unexpected budget/approval issues? Uncover these earlier in the sales cycle.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>Optimize Your Way to Sales Greatness</b></h5>
                    <p>
                        By vigilantly monitoring your "time by stage" metrics, you can identify the friction points that are slowing down your sales process. With the right targeted solutions, you can unstick those stalled deals and keep your pipeline moving at maximum velocity.
                    </p>
                    <p>
                        Remember, sales process optimization is an ongoing effort. Continue to analyze, experiment, and refine - your relentless pursuit of efficiency will pay off in the form of faster closes, higher win rates, and a more robust revenue engine. According to a study by McKinsey, companies that excel at sales process optimization see a 10-20% improvement in win rates and a 15-30% increase in sales productivity.[^7]
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                        [^1]: Hubspot, "The Ultimate List of Sales Statistics for 2022"
                        <br/>
                        [^2]: SiriusDecisions, "2018 B-to-B Buying Study"
                        <br/>
                        [^3]: Gong.io, "The Ideal Demo Length for SaaS Companies"
                        <br/>
                        [^4]: IACCM, "Contract Negotiation Benchmarking Report"
                        <br/>
                        [^5]: Gartner, "The New B2B Buying Journey"
                        <br/>
                        [^6]: Gong.io, "Winning Sales Conversations"
                        <br/>
                        [^7]: McKinsey, "Transforming Sales: The Three Essential Capabilities"
                    </p>
                </Row>
                <Row className='justify-items-start' style={{ marginTop: '35px', marginBottom: '25px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
    }

export default DiagnoseAndCureDealPage;
