import React from 'react';
import './FAQComponent.css';
import '../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';
import HorizontalLineSimple from '../atoms/lines/HorizontalLineSimple';


const FAQComponent: React.FC = () => {
    return (
        <>
        <Container className='faq-styled-text'>
            <Row style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px", paddingBottom: "40px"}}>
                <Row>
                    <h5 className="main-color faq-styled-text-title" style={{fontFamily: 'Raleway'}}>Most Common Questions</h5>
                </Row>
                <Row>
                    <Col><h2 className='faq-styled-text-title'>Frequently Asked <span className="main-color">Questions</span>?</h2></Col>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h4>How does Introductable work?</h4>
                    <p>
                        Introductable connects those looking to make a connection and have a meeting with a trusted third party 
                        (ex. family, friends, and career/industry peers) who can arrange that meeting.
                    </p>
                    <HorizontalLineSimple />
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h4>Who benefits from using Introductable?</h4>
                    <p>
                        Introductable benefits both businesses and individuals. Businesses can connect with 
                        the right people and secure important meetings, while individuals are financially compensated for making valuable introductions.
                    </p>
                    <HorizontalLineSimple />
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h4>What sets Introductable apart from other networking platforms?</h4>
                    <p>
                        Introductable's unique pay-per-meeting model and focus on high-quality introductions guarantees that you only pay 
                        for the meetings that occur. and that individuals are rewarded for making introductions that lead to successful outcomes.
                        If Introductable can’t provide a trusted third party Introductor, our AI working with our innovative relationship strength 
                        algorithm can suggest people who may the close relationship we are looking for. Introductable will then reach out to those suggested to gauge their interest.
                    </p>
                    <HorizontalLineSimple />
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h4>How do I get started with Introductable?</h4>
                    <p>
                        To get started with Introductable, simply create a profile and start searching to start the meeting generation process.
                    </p>
                    <HorizontalLineSimple />
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h4>How much does Introductable cost?</h4>
                    <p>
                        Introductable is free to start an account. For those looking to make introductions, there is no cost.For those looking for meetings 
                        Introductable offers three plans: Essentials, Professional, and Enterprise. The free Essentials plan,the Professional plan for those with 
                        ongoing meeting requirements is $599/mo and the Enterprise plan for those with robust meeting generation needs $1499/month.
                    </p>
                    <HorizontalLineSimple />
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h4>What is an Introductor’s compensation?</h4>
                    <p>
                        That depends on a few things including Introductor rating. In general, Introductor’s are paid between $400-600.

                    </p>
                    <HorizontalLineSimple />
                </Row>
            </Row>
        </Container>
        </>
    );
    }

export default FAQComponent;
