import React, { useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { ActionFormProps } from './ActionFormTypes';
import '../../../../Main.css';
import { TimeProposal } from '../../../../types/meeting';
import { newTimeProposals } from '../../../../api/meetingApi';
import StyledLabel from '../../../atoms/label/StyledLabel';
import StyledActionButton from '../../../atoms/button/StyledActionButton';
import TimeProposalComponent from '../../timeproposal/TimeProposalComponent';

const PendingMeetingRescheduleNeededForm: React.FC<ActionFormProps> = ({ meeting, onSubmit }) => {
    const [timeProposals, setTimeProposals] = useState<TimeProposal[]>([]);

    const submitNewTimes = () => {
        if (meeting.meetingId) {
            newTimeProposals(meeting.meetingId, timeProposals).then(
                () => {
                    onSubmit();    
                }
            );
        }
    };

    const handleTimeProposalChange = (newProposals: TimeProposal[]) => {
        setTimeProposals(newProposals);
    };

    return (
        <>
            <Container>
                <Row>
                    <h4 style={{fontFamily: 'Raleway'}}>Suggest Meeting Times</h4>
                </Row>
                <Row style={{marginTop: '20px'}}>
                    <TimeProposalComponent 
                        timeProposalsProp={timeProposals}
                        onTimeProposalsChange={handleTimeProposalChange} 
                        title={<p><span style={{fontWeight: '600', fontSize: '20px'}}>Introductor Proposed Times</span> (the more times you suggest, the better the chance the meeting is set!)</p>}                            
                    />
                </Row>
                <Row style={{marginTop: '20px'}}>
                    <StyledActionButton onClick={submitNewTimes} label={'Confirm new time proposals'} /> 
                </Row>
                <Row style={{marginTop: '20px', marginBottom: '20px'}}>
                    <StyledLabel label={'Requestor\'s Meeting Description'} />
                    <p>{meeting.description}</p>
                </Row>
            </Container>
        </>
    );
}

export default PendingMeetingRescheduleNeededForm;
