import React from 'react';
import './IntroductorRolePage.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';
import leadGenerationImage from '../../../content/blog/leadgeneration/meeting.png';
import FooterAddition from '../../footer/FooterAddition';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';
import { GoDotFill } from "react-icons/go";


const IntroductorRolePage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
        <Container fluid>
        <Row className='introductor-role-title-wrapper'>
                <h5 style={{fontFamily: 'Raleway'}} className="main-color">Introductor</h5>
                <h2 className='introductor-role-mobile-text' >Looking to arrange meetings and be paid for it? <span className="main-color">Become an Introductor and create your free account</span> to be matched with people looking for introductions into your network.</h2>
            </Row>
            <Row className='introductor-role-wrapper'>
               <Row style={{marginTop: '20px'}}>
                    <h5 style={{fontFamily: 'Raleway'}}>At Introductable, we revolutionize B2B networking through the power of warm introductions facilitated by real human connections.</h5>
               </Row>
               <Row style={{marginTop: '20px'}} className='introductor-role-reasons'>
                    <Col xs={12} md="auto" className='introductor-role-rectangle' style={{backgroundColor: '#E7F8F2'}}>
                        <h5>The Requestor:</h5>
                        <p>
                            The Requestor: Individuals or companies seeking to connect with specific decision-makers or companies (Meeting Targets) for potential opportunities. 
                            They will provide context for the meeting. 
                        </p>
                    </Col>
                    <Col xs={12} md="auto" className='introductor-role-rectangle' style={{backgroundColor: '#DBDBDB'}}>
                        <h5><b>The Introductor (You):</b></h5>
                        <p>
                            As someone with genuine relationships to these Meeting Targets, 
                            you're verified by Introductable and introduced to the Requestor to facilitate a personalized warm introduction and a meeting. 
                            You are paid once the meeting happens.
                        </p>
                    </Col>
                    <Col xs={12} md="auto" className='introductor-role-rectangle' style={{backgroundColor: '#FFF8E0'}}>
                        <h5>The Meeting Target:</h5>
                        <p>The Meeting Target: Your trusted contacts, who become more receptive and accessible through your warm introductions.</p>
                    </Col>
               </Row>
               <Row className='justify-items-start' style={{ marginTop: '35px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
               <Row style={{marginTop: '30px', marginBottom: '25px'}} className='introductor-role-mobile-direction'>
                    <Col xs={12} md={6} style={{textAlign: 'justify'}}>
                        <h4 style={{fontFamily: 'Raleway'}}><b>Here's How It Works:</b></h4>
                        <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                            1. You Provide: Names of prospects, target companies, or roles.
                            <br/><br/>
                            2. AI Identifies: Introductors with existing relationships to those Meeting Targets. After payment, you'll be connected with them.
                            <br/><br/>
                            3. Introductor Facilitates: The warm introduction, arranges the meeting, and provides initial context (given by you).
                            <br/><br/>
                            4. You Secure: A meaningful meeting through an authentic path, not cold outreach. You only pay for the meetings you have. 
                        </p>
                        <h4 style={{fontFamily: 'Raleway'}}><b>Benefits:</b></h4>
                        <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                            <GoDotFill className='main-color'/> Bypass resistance to engage key prospects.
                            <br/><br/>
                            <GoDotFill className='main-color'/> Begin relationship on a positive and trusting basis.
                            <br/><br/>
                            <GoDotFill className='main-color'/> Significantly shorten sales cycles.
                            <br/><br/>
                            <GoDotFill className='main-color'/> Cost effectively gain access to key prospects.
                        </p>
                    </Col>
                    <Col xs={12} md={6} style={{textAlign: 'center'}}>
                        <img src={leadGenerationImage} className="introductor-role-image" alt="leadGenerationImage" />
                    </Col>
               </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>

    );
}

export default IntroductorRolePage;
