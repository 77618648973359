import React from 'react';
import { Container, Row } from 'react-bootstrap';
import '../../../Main.css';
import './StyleVideoComponent.css';
import { StyleVideoComponentProps } from '../types';

const StyleVideoComponent: React.FC<StyleVideoComponentProps> = ({ isLooped, videoSrc }) => {
    return (
        <Container className='styled-video-wrapper'>
            <Row>
                <video className='styled-video-player' src={videoSrc} autoPlay muted playsInline loop={isLooped} />
            </Row>
        </Container>
    );
}

export default StyleVideoComponent;
