import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { addMeetingReview } from '../../../../api/meetingApi';
import { ActionFormProps } from './ActionFormTypes';
import { MeetingReviewRequest } from '../../../../types/meeting';
import '../../../../Main.css';

const SuccessNoReviewForm: React.FC<ActionFormProps> = ({ meeting, onSubmit }) => {
    const [meetingReviewRequest, setMeetingReviewRequest] = useState<MeetingReviewRequest>({
        rating: 5,
        opinion: ''
    });

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (meeting.meetingId) {
            await addMeetingReview(meeting.meetingId, meetingReviewRequest);
            onSubmit();
        }
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setMeetingReviewRequest({...meetingReviewRequest, [event.target.name]: event.target.value});
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId="rating">
                <Form.Label>Rating</Form.Label>
                <Form.Select name="rating" value={meetingReviewRequest.rating} onChange={handleChange}>
                    {[...Array(5)].map((_, i) => 
                        <option key={i} value={i+1}>{i+1}</option>
                    )}
                </Form.Select>
            </Form.Group>
            <Form.Group controlId="opinion">
                <Form.Label>Comment</Form.Label>
                <Form.Text as="textarea" rows={3} name="opinion" value={meetingReviewRequest.opinion} onChange={handleChange} />
            </Form.Group>
            <br/>
            <Button variant="primary" type="submit" className='styled-button'>
                Submit Review
            </Button>
        </Form>
    );
}

export default SuccessNoReviewForm;
