import React from 'react';
import './StyledRadio.css';
import { FormCheck } from 'react-bootstrap';
import { StyledRadioProps } from '../types';

const StyledRadio: React.FC<StyledRadioProps> = ({ checked, onChange, required = false, name, label }) => {
    return (
      <>
        <FormCheck
          type="radio"
          id={name}
          name={name}
          checked={checked}
          onChange={onChange}
          required={required}
          className='styled-radio-component'
        />
        <label htmlFor={name}>{label}</label> 
      </>
    );
};
  
  export default StyledRadio;