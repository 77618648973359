import React from 'react';
import './GreatDayToLeaveMeAlonePage.css';
import '../../../Main.css';
import { Container, Row } from 'react-bootstrap';
import blogImage from '../../../content/blog/greatdaytoleavemealone/great_day_to_leave_me_alone.png';
import FooterAddition from '../../footer/FooterAddition';
import { GoDotFill } from 'react-icons/go';


const GreatDayToLeaveMeAlonePage: React.FC = () => {
    return (
        <>
        <Container fluid>
            <Row className='great-day-to-leave-me-alone-wrapper'>
                <Row style={{marginTop: '30px'}}>
                    <h2 className='great-day-to-leave-me-alone-mobile-text' >Top Ten <span className="main-color">Leave Me Alone</span> Email Responders</h2>
                </Row>
                <Row style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={blogImage} className="great-day-to-leave-me-alone-image" alt="leaveMeAloneImage" />
                </Row>
                <Row style={{marginTop: '40px'}}>
                    <p>
                        As I bask in the glow of my vacation, far from the ping of incoming emails and the buzz of meeting reminders, I thought it might be amusing to share something a bit lighter. 
                        We've all been there - desperately seeking a moment of peace from the constant barrage of work communications. 
                        In the spirit of summer breaks and the quest for uninterrupted relaxation, I've compiled a list of fun 'leave me alone' email replies. 
                        These auto-responses might just inspire you to craft your own clever out-of-office message, or at the very least, give you a chuckle as you 
                        imagine the reactions they'd provoke. So let's dive into these attempts to keep the working world at bay while we enjoy our well-deserved time off.
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        <GoDotFill className='main-color'/> 1. "I'm currently out of the office and pretending I don't have email access. Your message will be deleted with extreme prejudice."
                        <br/><br/>
                        <GoDotFill className='main-color'/> 2. "Thank you for your email. I am currently away from my desk, running away from my responsibilities. If this is urgent, please panic accordingly."
                        <br/><br/>
                        <GoDotFill className='main-color'/> 3. "I'm on vacation. In case of emergency, please contact anyone else but me."
                        <br/><br/>
                        <GoDotFill className='main-color'/> 4. "Auto-reply: I'm currently attending a seminar on how to avoid people. Your email is helping me practice."
                        <br/><br/>
                        <GoDotFill className='main-color'/> 5. "I'm out of the office and out of patience. Please try again never."
                        <br/><br/>
                        <GoDotFill className='main-color'/> 6. "Your email is important to us. Unfortunately, I am not. Please try my more enthusiastic colleague."
                        <br/><br/>
                        <GoDotFill className='main-color'/> 7. "I'm currently on a digital detox, which is a fancy way of saying I'm ignoring everyone. See you when I run out of Netflix shows."
                        <br/><br/>
                        <GoDotFill className='main-color'/> 8. "Due to high email volume, I'm currently only responding to messages containing pizza emojis or cat videos. Please resend accordingly."
                        <br/><br/>
                        <GoDotFill className='main-color'/> 9. "Congratulations! Your email has reached the void. For faster service, please write your message on a piece of paper and throw it into the wind."
                        <br/><br/>
                        <GoDotFill className='main-color'/> 10."I'm currently experiencing an existential crisis and cannot be bothered with your mundane inquiries. Please check back when I've found the meaning of life or my will to work, whichever comes first."
                        <br/><br/>
                    </p>
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
}

export default GreatDayToLeaveMeAlonePage;
