import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { ActionFormProps } from './ActionFormTypes';
import '../../../../Main.css';
import { checkCoupon, createPaymentSession } from '../../../../api/paymentApi';
import { useAuthContext } from '../../../../App';
import StyledActionButton from '../../../atoms/button/StyledActionButton';
import StyledInput from '../../../atoms/input/StyledInput';

const PendingIntroductorAddedForm: React.FC<ActionFormProps> = ({ meeting, onSubmit }) => {
    const DEFAULT_MEETING_PRICE = 800;
    const { user } = useAuthContext();
    const [meetingPrice, setMeetingPrice] = useState<number>(DEFAULT_MEETING_PRICE);
    const [discountCode, setDiscountCode] = useState<string>('');
    const [appliedPromoCode, setAppliedPromoCode] = useState<string>('');
    const [errorCouponMessage, setErrorCouponMessage] = useState<string>('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDiscountCode(event.target.value);
    };

    const handleApplyPromo = async () => {
        setMeetingPrice(DEFAULT_MEETING_PRICE);
        if (discountCode) {
            await checkCoupon(discountCode).then(response => {
                if (response.data.isValid) {
                    setAppliedPromoCode(discountCode);
                    setMeetingPrice(DEFAULT_MEETING_PRICE - (DEFAULT_MEETING_PRICE * response.data.discountPercatage / 100 ));
                    setErrorCouponMessage('');
                } else {
                    setMeetingPrice(DEFAULT_MEETING_PRICE);
                    setAppliedPromoCode('');
                    setErrorCouponMessage('Promo code is invalid');
                }
            }).catch(() => {
                setMeetingPrice(DEFAULT_MEETING_PRICE);
                setAppliedPromoCode('');
                setErrorCouponMessage('Promo code is invalid');
            });
        } else {
            setMeetingPrice(DEFAULT_MEETING_PRICE);
            setAppliedPromoCode('');
        }
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (meeting.meetingId) {
            await createPaymentSession({
                meetingId: meeting.meetingId,
                stripeProduct: 'MEETING_800',
                userEmail: user.email,
                discountCode: appliedPromoCode
            }).then(response => {
                window.location.href = response.data.paymentUrl;
            });
        }
    };

    const firstIntroductorLetter = meeting.introductor?.lastName ? meeting.introductor.lastName.charAt(0) : '';

    return (
        <>
        <Container>
            <Row style={{marginTop: '20px'}}>
                <h4>Pay For Your Meeting</h4>
            </Row>
            <Row style={{marginTop: '20px'}}>
                {
                    appliedPromoCode.length > 0 ? (
                        <h5>Your meeting price: <span style={{textDecoration: 'line-through', fontWeight: '300'}}>${DEFAULT_MEETING_PRICE}</span> ${meetingPrice}</h5>
                    ) : (
                        <h5>Your meeting price: ${meetingPrice}</h5>
                    )
                }
            </Row>
            <Row style={{marginTop: '20px'}}>
                <p>
                    Paying for meeting between <b>{`${meeting.interessant?.name} ${meeting.interessant?.lastName} `}</b>
                    <br/>
                    and <b>{`${meeting.expert?.name} ${meeting.expert?.lastName}`}</b>
                    <br/>
                    thanks to introductor: <b>{`${meeting.introductor?.name} ${firstIntroductorLetter}.`}</b>
                </p>
            </Row>
            <Row style={{marginTop: '20px'}}>
                <Form onSubmit={handleSubmit}>
                    <StyledActionButton variant="primary" type="submit" className='styled-button' label={'Pay now'} />
                </Form>
            </Row>
            <Row style={{marginTop: '20px'}}>
                <p>Enter your promo code here:</p>
            </Row>
            <Row style={{maxWidth: '400px'}}>
                <Col>
                        <StyledInput 
                            name="discountCode"
                            value={discountCode}
                            onChange={handleChange} 
                            type={'text'}                  
                            placeholder='Promo Code'      
                        />    
                        <p>{errorCouponMessage.length > 0 ? <span style={{color: 'red'}}>{errorCouponMessage}</span> : ''}</p>
                </Col>
                <Col>
                    <StyledActionButton label={'Apply Promo Code'} onClick={handleApplyPromo}/>
                </Col>
            </Row>
            <Row style={{marginTop: '20px'}}>
                <p>Remember! You pay only for the meetings you have!</p>
            </Row>
        </Container>
        </>
    );
}

export default PendingIntroductorAddedForm;
