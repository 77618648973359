import React from 'react';
import './RelationshipInsurancePage.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';

import peopleTalk from '../../../content/blog/people-talking_scaled.jpeg'
import FooterAddition from '../../footer/FooterAddition';
import StyledLink from '../../atoms/link/StyledLink';
import { GoDotFill } from 'react-icons/go';

const RelationshipInsurancePage: React.FC = () => {
    return (
        <>
        <Container fluid className='justify-content-center'>
            <Row>
                    <img src={peopleTalk} className="relationship-insurance-title-image" alt="peopleTalk" />
            </Row>
            <Row style={{paddingLeft: "20px", paddingTop: "50px", paddingRight: "20px", paddingBottom: "40px"}} className='justify-content-center'>
            <Row>
                <Col className='relationship-insurance-title'><h2>Use <span className="main-color">“Relationship Insurance”</span> to Shorten the Sales Cycle</h2></Col>
            </Row>
            <Row className='relationship-insurance-styled-text' style={{ marginTop: '20px' }}>
                <p>
                    Sales professionals know that accelerating sales cycles is critical for driving revenue growth. 
                    However, it's also one of the hardest aspects of the sales process to effectively improve. 
                    <br/>
                    Shortening time-to-close directly impacts key metrics like pipeline velocity and sales efficiency. 
                    It allows organizations to maximize selling capacity and bookings, 
                    while increasing the number of opportunities teams can work - greatly enhancing efficiency and reducing the need to add headcount to meet revenue targets.
                </p>
            </Row>
            <Row style={{ marginTop: '20px' }}>
                <h4>Inject Trusted Referrals Early</h4>
                <p>
                    One of the best ways to accelerate sales cycles is by injecting a trusted source or referral as early into the process as possible. 
                    Introductions through established professional relationships can tremendously accelerate sales cycles and prevent deals from stalling out. 
                    When initial meetings occur thanks to a warm referral from a trusted connection, 
                    prospects are 7x more likely to remain actively engaged throughout the entire process.
                </p>
            </Row>
            <Row>
                <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                    <GoDotFill className='main-color'/> <b>Prevent Disengagement:</b> With traditional cold outbound tactics, 
                    it's extremely common for promising leads to suddenly go dark or disengage partway through discussions. 
                    Initial enthusiasm wanes, priorities shift, and the opportunity gets deprioritized in favor of maintaining the status quo. 
                    Sales reps and marketing teams then have to restart the difficult cycle of trying to regain mindshare.
                    <br/><br/>
                    <GoDotFill className='main-color'/> <b>Foster Accountability:</b> When an introduction comes from someone the prospect already knows 
                    and respects within their network, there's an innate accountability that keeps them constructively involved. 
                    No one wants to risk damaging a valued personal connection by disregarding or cutting off communication after 
                    that helpful referral facilitated the initial meeting. This dynamic fosters consistent, attentive engagement from prospects.
                    <br/><br/>
                    <GoDotFill className='main-color'/> <b>Maintain Momentum:</b> Fewer deals get derailed or drawn out unnecessarily when relationships 
                    are leveraged for introductions. Opportunities keep progressing efficiently, with active participation from key decision-makers. 
                    Momentum is maintained from start to close, preventing delays and restarts in the sales cycle.
                    <br/><br/>
                    <GoDotFill className='main-color'/> <b>Capitalize on "Relationship Insurance":</b> Securing introductions through networks provides powerful 
                    "relationship insurance" that prevents inconsistent engagement - one of the biggest bottlenecks for sales teams. 
                    Initial interest transforms into sustained commitment, radically accelerating time-to-close versus having to regain mindshare after a prospect disengages.
                </p>
            </Row>
            <Row>
                <p>
                    By strategically facilitating introductions from trusted sources early on, sales organizations can shorten their cycles, 
                    prevent derailed opportunities, and maximize efficient revenue production.
                </p>
            </Row>
            <Row>
                <p>
                    <StyledLink href={'/signup'}><b>Create account here:</b></StyledLink> to find people able and willing to make introductions to your prospects.
                </p>
            </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
    }

export default RelationshipInsurancePage;
