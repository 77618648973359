import React, { useEffect, useState } from 'react';
import { getMeetingChat } from '../../api/meetingApi';
import { ChatMessage } from '../../types/chat';
import { addChatMessage } from '../../api/chatApi';
import { useAuthContext } from '../../App';
import { Container, Row } from 'react-bootstrap';
import StyledInput from '../atoms/input/StyledInput';
import { Meeting } from '../../types/meeting';
import '../../Main.css';
import './ChatComponent.css';
import { DateUtils } from '../../utils/DateUtils';

export type ChatComponentProps = {
    meeting: Meeting;
}

const ChatComponent: React.FC<ChatComponentProps> = ({ meeting }) => {
    const { user } = useAuthContext();
    const senderName = user.personId === meeting.introductor?.personId ? `${meeting.introductor?.name} ${meeting.introductor?.lastName}` : `${meeting.interessant?.name} ${meeting.interessant?.lastName}`;
    const [chatMessages, setChatMessages] = useState<ChatMessage[]>([]);
    const [messageText, setMessageText] = useState('');
    const [isIntroductor, setIsIntroductor] = useState<boolean>(user.personId === meeting.introductor?.personId);
    const [receiverId] = useState<string>(user.personId === meeting.introductor?.personId ? meeting.interessant?.personId as string : meeting.introductor?.personId as string);

    useEffect(() => {
        if (meeting.meetingId) {
            getMeetingChat(meeting.meetingId).then(
                response => {
                    setChatMessages(response.data);
                }
            );

        }
    }, [meeting]);

    const handleSendMessage = async (e: React.FormEvent) => {
        e.preventDefault();
        if (messageText.trim() && meeting.meetingId) {
            await addChatMessage({ meetingId: meeting.meetingId, receiverId, senderId: user.personId as string, messageText });
            var message = { meetingId: meeting.meetingId, receiverId, senderId: user.personId as string, messageText, senderName: senderName, sentDateTime: DateUtils.nowIso() } as ChatMessage;
            chatMessages.push(message);
            setMessageText('');
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' && !e.shiftKey) { 
            e.preventDefault(); 
            handleSendMessage(e as unknown as React.FormEvent); 
        }
    };

    const getChatMessages = () => {
        return (
            <>
                <Row className='chat-messages-wrapper'>
                {chatMessages.map(msg => (
                    msg.senderName === 'Introductable Team' ? (
                        <>
                            <Row className='chat-message-wrapper-introductable-team'>
                                <h5>{msg.senderName}</h5>
                                <p>{msg.messageText}</p>
                            </Row>
                            <Row className='chat-message-wrapper-date-sender'>
                                <p >{DateUtils.formatReadableDateTime(msg.sentDateTime as string)}</p>
                            </Row>
                        </>
                    ) : (
                        <>{
                            msg.senderId === user.personId ? (
                                <>
                                <Row className='chat-message-wrapper-sender'>
                                    <h5>{msg.senderName}</h5>
                                    <p>{msg.messageText}</p>
                                </Row>
                                <Row className='chat-message-wrapper-date-sender'>
                                    <p >{DateUtils.formatReadableDateTime(msg.sentDateTime as string)}</p>
                                </Row>
                                </>
                            ) : (
                                <>
                                <Row className='chat-message-wrapper-receiver'>
                                    <h5>{msg.senderName}</h5>
                                    <p>{msg.messageText}</p>
                                </Row>
                                <Row className='chat-message-wrapper-date-receiver' >
                                    <p>{DateUtils.formatReadableDateTime(msg.sentDateTime as string)}</p>
                                </Row>
                                </>
                            )
                        }</>
                    )))}
                </Row>
            </>
        );
    };

    return (
        <Container style={{marginBottom: '20px'}}>
            <Row className='chat-title'>
                <h4 style={{fontFamily: 'Raleway'}}>{'Chat with ' + (isIntroductor ? 'Requestor' : 'Introductor')}</h4>
            </Row>
            <Row className='chat-wrapper'>
                {getChatMessages()}
                <Row>
                    <StyledInput 
                        value={messageText} 
                        onChange={(e) => setMessageText(e.target.value)} 
                        type={'text'} 
                        placeholder='Type your message'
                        onKeyDown={handleKeyDown}
                    />
                </Row>
            </Row>
        </Container>
    );
};

export default ChatComponent;