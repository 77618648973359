import React from "react";
import './PrivacyPolicyComponent.css'; 
import '../../Main.css';
import FooterAddition from "../footer/FooterAddition";
import { Container } from "react-bootstrap";
import HorizontalLineSimple from "../atoms/lines/HorizontalLineSimple";

const PrivacyPolicyComponent: React.FC = () => {
    return (
        <>
        <Container fluid className="privacy-policy">
            <h2>Privacy Policy</h2>
            
            <h4>Introduction</h4>
            <p>Our website, Introductable.com (hereinafter: "the website"), values the privacy of our members, users, and any individual who accesses or uses our website. We are committed to protecting the personal information that you share with us. This Privacy Policy outlines our practices concerning the collection, use, and disclosure of your information when you use our website.</p>
            <HorizontalLineSimple/>

            <h4 style={{marginTop: '20px'}}>Information Collection and Use</h4>
            <p>We collect several different types of information for various purposes to provide and improve our service to you. These may include, but are not limited to, personal identification information (name, email address, phone number), usage data, and cookies.</p>
            <HorizontalLineSimple />
            
            <h4 style={{marginTop: '20px'}}>Log Data</h4>
            <p>When you access our website, we may collect information that your browser sends to us, known as Log Data. This Log Data may include information such as your computer's Internet Protocol (IP) address, browser version, the pages of our website that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</p>
            <HorizontalLineSimple />
            
            <h4 style={{marginTop: '20px'}}>Cookies</h4>
            <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of our service.</p>
            <HorizontalLineSimple />
            
            <h4 style={{marginTop: '20px'}}>Service Providers</h4>
            <p>We may employ third-party companies and individuals due to the following reasons: to facilitate our service; to provide the service on our behalf; to perform service-related services; or to assist us in analyzing how our service is used. We want to inform our service users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
            <HorizontalLineSimple />
            
            <h4 style={{marginTop: '20px'}}>Security</h4>
            <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
            <HorizontalLineSimple />
            
            <h4 style={{marginTop: '20px'}}>Changes to This Privacy Policy</h4>
            <p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page.</p>
            <HorizontalLineSimple />
            
            <h4 style={{marginTop: '20px'}}>Contact Us</h4>
            <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at info@introductable.com.</p>
        </Container>
        <FooterAddition />
        </>
    );
};

export default PrivacyPolicyComponent;
