import axios, { AxiosResponse } from 'axios';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/v1/upload`;

export async function uploadResume(formData: FormData, personId: string): Promise<AxiosResponse<string>> {
    formData.append('userId', personId);

    return await axios.post(`${API_BASE_URL}/resume`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}