import React from 'react';
import './HorizontalLine.css';

const HorizontalLineSimple: React.FC = () => {
  return (
    <div className='horizontal-line-wrapper'>
      <div className='horizontal-line'/>
    </div>
  );
}

export default HorizontalLineSimple;
