import React, { useState } from 'react';
import './WantMeetingComponent.css';
import '../../../Main.css';
import aboutUsFirstImg from '../../../content/aboutus/aboutus_2.jpeg'
import aboutUsSecondImg from '../../../content/aboutus/aboutus_3.jpeg'
import { Col, Container, Row } from 'react-bootstrap';
import ImageCollage from '../../molecules/collages/ImageCollage';
import StyledInput from '../../atoms/input/StyledInput';
import CircledButtonSmall from '../../atoms/button/CircledButtonSmall';
import { useNavigate } from 'react-router-dom';


const WantMeetingComponent: React.FC = () => {
    const navigate = useNavigate();
    const [searchKeyword, setSearchKeyword] = useState<string>('');

    const onSearchClick = () => {
        navigate(`/search/result?keywords=${searchKeyword}`);
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if ( e.key === 'Enter') {
            onSearchClick();
        }
    };

    return (
        <Container className='make-a-meeting-wrapper'>
            <Row style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px"}}>
                <Col xs={12} md={6}>
                    <ImageCollage firstImage={aboutUsFirstImg} secondImage={aboutUsSecondImg} />
                </Col>
                <Col xs={12} md={6} className="make-meeting-center">
                    <Row>
                        <p>Make a Meeting</p>
                    </Row>
                    <Row>
                        <h2 className='main-color'>I Want to Have a Meeting With.....</h2>
                    </Row>
                    <Row style={{marginLeft: '20px', marginRight: '20px', marginTop: '20px'}}>
                        <StyledInput 
                            value={searchKeyword} 
                            onChange={(e) => setSearchKeyword(e.target.value)}
                            type={"text"}      
                            placeholder="Enter name"                    
                            onKeyDown={handleKeyDown}  
                        />
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                        <CircledButtonSmall onClick={onSearchClick} />
                    </Row>
                </Col>
            </Row>
        </Container>
    );
    }

export default WantMeetingComponent;
