import React from 'react';
import './TragicComedyColdOutreachPage.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';

import peopleTalk from '../../../content/blog/people-talking_scaled.jpeg'
import FooterAddition from '../../footer/FooterAddition';
import { GoDotFill } from 'react-icons/go';

const TragicComedyColdOutreachPage: React.FC = () => {
    return (
        <>
        <Container fluid className='justify-content-center'>
            <Row>
                    <img src={peopleTalk} className="tragic-comedy-cold-reach-page-title-image" alt="peopleTalk" />
            </Row>
            <Row style={{paddingLeft: "20px", paddingTop: "50px", paddingRight: "20px", paddingBottom: "40px"}} className='justify-content-center'>
            <Row>
                <Col className='tragic-comedy-cold-reach-page-title'><h2><span className="main-color">The Tragic Comedy of Cold Outreach;</span> Let’s Commiserate Together</h2></Col>
            </Row>
            <Row>
                <p>
                    Picture this: It's another glorious Monday morning. You've armed yourself with a triple-shot espresso and the unwavering belief that today, yes today, your cold outreach efforts will finally pay off. 
                    <br/>
                    <br/>
                    Buckle up, because we're about to take a hilarious (and slightly painful) journey through the land of cold outreach fails.
                </p>
                <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                    <GoDotFill className='main-color'/> 1. <b>The Email Void:</b> You've crafted the perfect email. It's witty, it's concise, it's borderline Pulitzer-worthy. You hit send and... nothing. Crickets. Tumbleweeds. You begin to wonder if the internet is broken or if you've somehow emailed an alternate dimension where your messages go to die.
                    <br/><br/>
                    <GoDotFill className='main-color'/> 2. <b>The LinkedIn Ghost Town:</b> Ah, LinkedIn: The promised land of professional connections. You've sent out 50 connection requests with personalized messages. Three days later, you have one new connection - your mom, supporting your "networking efforts." Thanks, Mom.
                    <br/><br/>
                    <GoDotFill className='main-color'/> 3. <b>The Cringe-Worthy Follow-Up:</b> Undeterred, you send a follow-up email. "Just circling back to see if you had a chance to read my previous message!" Yeah, they saw it. They just didn't care. But you persist, thinking, "Third time's the charm," right? Right? Please tell me I’m right…
                    <br/><br/>
                    <GoDotFill className='main-color'/> 4. <b>The Voicemail Black Hole:</b> You think, "Maybe I'll try calling." You leave a cheerful voicemail, only to realize no one checks voicemail anymore. It’s probably lost in a digital abyss with your other voicemails and all those deleted emails.
                    <br/><br/>
                    <GoDotFill className='main-color'/> 5. <b>The Unsubscribe Avalanche:</b> You thought your email list was solid gold. Turns out, it's more like fool's gold. Within minutes of sending your campaign, your inbox is flooded with unsubscribe notifications. It's like hosting a party where everyone simultaneously decides to leave.
                    <br/><br/>
                    <GoDotFill className='main-color'/> 6. <b>The Spam Folder Abyss:</b> After days of radio silence, you discover all your meticulously crafted emails have been languishing in spam folders. Your brilliant sales pitch is now keeping company with offers for discount pharmaceuticals and Nigerian princes.
                    <br/><br/>
                    <GoDotFill className='main-color'/> 7. <b>The Pity Response:</b> Hallelujah! A response! Your heart races as you open it, only to read: "Please stop emailing me. My dog died, I lost my job, and your constant messages are the final straw." Well, at least they replied?
                    <br/><br/>
                    <GoDotFill className='main-color'/> 8. <b>The Wrong Person Altogether:</b> You've spent weeks researching and pursuing the perfect contact. You finally get them on the phone, only to discover they left the company two years ago. Or worse, they don’t do what you thought they did and no…they won’t tell you who does. They will definitely pass your info along, though. Ouch…
                    <br/><br/>
                    <GoDotFill className='main-color'/> 9. <b>The Automation Fail:</b> You decided to get smart and use an automation tool. Genius! Until you realize it's been sending the same message to the same person every day for a month. You're now officially known as the company stalker.
                    <br/><br/>
                    <GoDotFill className='main-color'/> 10. <b>The Mistaken Identity:</b> In a stroke of what you thought was brilliance, you referenced a recent post your prospect made. Only to find out it wasn't their post, it was their competitor's. Cue the world's most awkward silence.
                    <br/><br/>
                </p>
            </Row>
            <Row>
                <p>
                    If any (or all) of these scenarios sound painfully familiar, don't worry - you're not alone. We've all been there, hitting our heads against the cold, unforgiving wall of outreach.
                    <br/>
                    <br/>
                    But fear not! There's hope on the horizon. Rumor has it there's a magical place called Introductable, where warm introductions flow like honey and prospects actually want to talk to you. It sounds too good to be true, but after the tenth spam complaint of the day, aren't you willing to try anything?
                </p>
            </Row>
            <Row>
                <p>
                    Remember, in the world of cold outreach, if you're not laughing, you're crying. So might as well embrace the absurdity and hope that one day, just maybe, someone will read your email. Until then, just remember to double-check those names first.
                </p>
            </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
    }

export default TragicComedyColdOutreachPage;
