import React from 'react';
import './RequestorRolePage.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';
import leadGenerationImage from '../../../content/blog/leadgeneration/meeting.png';
import FooterAddition from '../../footer/FooterAddition';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';
import { GoDotFill } from "react-icons/go";


const RequestorRolePage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
        <Container fluid>
            <Row className='requestor-role-title-wrapper'>
                <h5 style={{fontFamily: 'Raleway'}} className="main-color">Requestor</h5>
                <h2 className='requestor-role-mobile-text' >Looking to have sales or other types of meetings set for you? <span className="main-color">Become a Requestor by creating a free account:</span> then search for your prospects and have meetings set for you!</h2>
            </Row>
            <Row className='requestor-role-wrapper'>
               <Row style={{marginTop: '20px'}}>
                    <h5 style={{fontFamily: 'Raleway'}}>At Introductable, we revolutionize B2B networking through the power of warm introductions facilitated by real human connections.</h5>
               </Row>
               <Row style={{marginTop: '20px'}} className='requestor-role-reasons'>
                    <Col xs={12} md="auto" className='requestor-role-rectangle' style={{backgroundColor: '#E7F8F2'}}>
                        <h5><b>The Requestor (You):</b></h5>
                        <p>Seeking to connect with specific companies and decision-makers (Meeting Targets) to explore potential opportunities.</p>
                    </Col>
                    <Col xs={12} md="auto" className='requestor-role-rectangle' style={{backgroundColor: '#DBDBDB'}}>
                        <h5>The Introductor:</h5>
                        <p>
                            Introductable identifies trusted individuals with genuine relationships to your Meeting Targets. 
                            We connect you to the Introductor, and you provide them the information and context for the meeting. 
                            They then arrange the meeting with guidance from you.
                        </p>
                    </Col>
                    <Col xs={12} md="auto" className='requestor-role-rectangle' style={{backgroundColor: '#FFF8E0'}}>
                        <h5>The Meeting Target:</h5>
                        <p>Your self-identified prospect, made more receptive and accessible through a warm introduction from the trusted Introductor.</p>
                    </Col>
               </Row>
               <Row className='justify-items-start' style={{ marginTop: '35px'}}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
               <Row style={{marginTop: '30px', marginBottom: '25px'}} className='requestor-role-mobile-direction'>
                    <Col xs={12} md={6} style={{textAlign: 'justify'}}>
                        <h4 style={{fontFamily: 'Raleway'}}><b>Here's How It Works:</b></h4>
                        <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                            1. You Provide: Names of prospects, target companies, or roles.
                            <br/><br/>
                            2. AI Identifies: Introductors with existing relationships to those Meeting Targets. After payment, you'll be connected with them.
                            <br/><br/>
                            3. Introductor Facilitates: The warm introduction, arranges the meeting, and provides initial context (given by you).
                            <br/><br/>
                            4. You Secure: A meaningful meeting through an authentic path, not cold outreach. You only pay for the meetings you have. 
                        </p>
                        <h4 style={{fontFamily: 'Raleway'}}><b>Benefits:</b></h4>
                        <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                            <GoDotFill className='main-color'/> Bypass resistance to engage key prospects.
                            <br/><br/>
                            <GoDotFill className='main-color'/> Begin relationship on a positive and trusting basis.
                            <br/><br/>
                            <GoDotFill className='main-color'/> Significantly shorten sales cycles.
                            <br/><br/>
                            <GoDotFill className='main-color'/> Cost effectively gain access to key prospects.
                        </p>
                    </Col>
                    <Col xs={12} md={6} style={{textAlign: 'center'}}>
                        <img src={leadGenerationImage} className="requestor-role-image" alt="leadGenerationImage" />
                    </Col>
               </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>

    );
}

export default RequestorRolePage;
