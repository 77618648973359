import React from 'react';
import './HorizontalLine.css';
import { HorizontalLineProps } from '../types';

const HorizontalLine: React.FC<HorizontalLineProps> = ({ label }) => {
  return (
    <div className='horizontal-line-wrapper'>
      <div className='horizontal-line'/>
      <div className='horizontal-line-label'>{label}</div>
      <div className='horizontal-line'/>
    </div>
  );
}

export default HorizontalLine;
