import React from 'react';
import './SharedGoalsProspectPage.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';
import FooterAddition from '../../footer/FooterAddition';
import { GoDotFill } from 'react-icons/go';

import blogImage from '../../../content/blog/sharedgoalsprospect/SharedGoalsProspects.png';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';

const SharedGoalsProspectPage: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
        <Container fluid className='justify-content-center'>

            <Row style={{paddingLeft: "20px", paddingTop: "50px", paddingRight: "20px", paddingBottom: "40px"}} className='justify-content-center'>
                <Row>
                    <Col className='shared-goals-prospect-title'><h2>How to <span className="main-color">Develop Shared Goals</span> With Your Prospects</h2></Col>
                </Row>
                <Row style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={blogImage} className="shared-goals-prospect-title-image" alt="agencyShowdownImage" />
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                        In sales, establishing a genuine connection with prospects is key component and one of the most powerful ways to build this connection is by developing shared goals. Shared goals serves as an excellent starting point for your sales process and a guiding light throughout your interactions, leading to mutual success.
                        <br/>
                        <br/>
                            When the key players align their objectives, a foundation of trust and collaboration is established transforming the traditional sales dynamic into a partnership, where both parties agree to work together towards stated common goals.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>Starting Point: Identifying Shared Goals</h5>
                    <p>
                        The sales process should begin with a discovery phase focused on understanding the prospect's needs, challenges, and aspirations. By actively listening and asking insightful questions, salespeople can identify areas where their product or service can genuinely add value.
                        <br/>
                        <br/>
                        Tools like Introductable can be valuable here, providing salespeople with relevant background information on prospects and enabling more informed and meaningful initial conversations.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>Throughout the Process: Keeping Goals in Focus</h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        As the sales process progresses, shared goals should remain at the forefront of all interactions. This means:
                        <br/>
                        <GoDotFill className='main-color'/> 1. Tailoring presentations to address specific goals
                        <br/>
                        <GoDotFill className='main-color'/> 2. Demonstrating how your solution aligns with the prospect's objectives
                        <br/>
                        <GoDotFill className='main-color'/> 3. Addressing concerns in the context of achieving shared outcomes
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>End Point: Achieving Mutual Success</h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        The culmination of the sales process should be the realization of these shared goals. This might involve:
                        <br/>
                        <GoDotFill className='main-color'/> Implementing the solution
                        <br/>
                        <GoDotFill className='main-color'/> Measuring success based on agreed-upon metrics
                        <br/>
                        <GoDotFill className='main-color'/> Establishing a long-term partnership focused on ongoing goal achievement
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h4>Benefits of the Shared Goals Approach</h4>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        <GoDotFill className='main-color'/> <b>1. Trust Building:</b> When prospects see that you're genuinely invested in their success, it fosters trust and credibility.
                        <br/>
                        <GoDotFill className='main-color'/> <b>2. Reduced Resistance:</b> Aligning goals minimizes objections and resistance throughout the sales process.
                        <br/>
                        <GoDotFill className='main-color'/> <b>3. Higher Close Rates:</b> Prospects are more likely to commit when they see a clear path to achieving their objectives.
                        <br/>
                        <GoDotFill className='main-color'/> <b>4. Longer-Term Relationships:</b> The focus on shared success lays the groundwork for ongoing partnerships and repeat business.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h4>Implementing the Shared Goals Strategy</h4>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        To effectively use shared goals in your sales process:
                        <br/>
                        <GoDotFill className='main-color'/> <b>1. Develop Strong Listening Skills:</b> Train your sales team to actively listen and identify underlying needs and goals.
                        <br/>
                        <GoDotFill className='main-color'/> <b>2. Use Technology Wisely:</b> Leverage tools like Introductable to gather insights that can help identify potential shared goals before initial meetings.
                        <br/>
                        <GoDotFill className='main-color'/> <b>3. Create Goal-Oriented Sales Materials:</b> Develop presentations and proposals that clearly link your offerings to the prospect's objectives.
                        <br/>
                        <GoDotFill className='main-color'/> <b>4. Establish Clear Metrics:</b> Work with prospects to define measurable outcomes that represent shared success.
                        <br/>
                        <GoDotFill className='main-color'/> <b>5. Follow Through:</b> After the sale, continue to track progress towards shared goals and offer support as needed.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                    By centering your sales process around shared goals, you transform the buyer-seller relationship into a collaborative journey. This approach not only leads to more successful sales outcomes but also builds the foundation for long-lasting, mutually beneficial business relationships.
                        <br/>
                        <br/>
                        Remember, the key is to genuinely care about your prospect's success. When you approach sales with this mindset, you're not just closing deals – you're opening doors to partnerships that can flourish for years to come.
                    </p>
                </Row>
                <Row className='justify-items-start' style={{ marginTop: '35px', marginBottom: '25px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
    }

export default SharedGoalsProspectPage;
