import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import successIcons from '../../content/account/success.svg';
import BoxedButton from '../molecules/boxedbutton/BoxedButton';
import StyledLabel from '../atoms/label/StyledLabel';

const SuccessNewUser: React.FC = () => {
    const navigate = useNavigate();

    const goToSearchPage = () => {
        navigate(`/search`);
    }

    const goToConnectionsPage = () => {
        navigate(`/connections`);
    }

    return (
        <>
            <Container fluid style={{ height: "calc(100vh - 100px)" }}>
                <Row className="justify-content-center mb-2" style={{ paddingTop: "80px"}}>
                    <Col className="text-center">
                        <h4>Success!</h4>
                    </Col>
                </Row>
                    <Row className="justify-content-center mb-2"> 
                    <Col className="text-center">
                        <StyledLabel align='center' label={'You now have an account'} />
                    </Col>
                </Row>
                <Row className="justify-content-around mb-3" style={{ paddingTop: "30px"}}>
                    <Col md={3} />
                    <Col md={3} className="d-flex justify-content-center">
                        <BoxedButton title='If you are looking to set a meeting, we suggest you start here!' 
                                    buttonLabel={'Search meeting targets'} onClick={goToSearchPage} />
                    </Col>
                    <Col md={3} className="d-flex justify-content-center">
                        <BoxedButton title='If you are looking to make introductions, we suggest you start here!' 
                                    buttonLabel={'My connections panel'} onClick={goToConnectionsPage} />
                    </Col>
                    <Col md={3} />
                </Row>
                <Row className="justify-content-center" style={{ paddingTop: "20px"}}>
                    <Col style={{ paddingLeft: "30%", paddingRight: "30%" }}> 
                        <img src={successIcons} style={{ width: "100%", height: "auto" }} alt="Success Icons" />
                    </Col>
                </Row>
            </Container>
        </>
        
    );
};

export default SuccessNewUser;
