import React from 'react';
import './StyledLabel.css';
import { StyledLabelProps } from '../types';


const StyledLabel: React.FC<StyledLabelProps> = ({ label, align = 'left', bold = false, color  }) => {
  const getAlignmentClass = () => {
    if (align === 'right') {
      return 'custom-link-align-right';
    } else if (align === 'left') {
      return 'custom-link-align-left';
    } else {
      return 'custom-label-align-center'
    }
  }

  const getColorClass = () => {
    if (color === 'green') {
        return 'custom-label-color-green';
    }

    return '';
  }

  const alignmentClass = getAlignmentClass();
  const colorClass = getColorClass();

  const boldClass = bold ? 'custom-link-bold' : '';
  return (
    <div className={`color-styled-label ${alignmentClass} ${boldClass} ${colorClass}`}>
        {label}
    </div>
  );
}

export default StyledLabel;
