import axios, { AxiosResponse } from 'axios';
import { SignInResponse, User } from '../types/user';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;

export async function signUpUser(user: User): Promise<AxiosResponse> {
  return await axios.post(`${API_BASE_URL}/auth/signup`, user);
}

export async function signInUser(user: User): Promise<AxiosResponse<SignInResponse>> {
  return await axios.post(`${API_BASE_URL}/auth/signin`, user);
}

export async function forgetPassword(email: string): Promise<AxiosResponse<String>> {
  return await axios.post(`${API_BASE_URL}/auth/forget-password`, {email: email});
}

export async function changePasswordForUser(newPassword: string, token: string): Promise<AxiosResponse<String>> {
  return await axios.post(`${API_BASE_URL}/auth/change-password`, {newPassword: newPassword, token: token});
}