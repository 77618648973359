import React from 'react';
import './StyledButton.css'; 
import { StyledButtonProps } from '../types';
import { Button } from 'react-bootstrap';


const StyledButton: React.FC<StyledButtonProps> = ({ label, variant = 'primary', ...props }) => {
  const className = `custom-styled-button ${variant} ${props.className || ''}`;
  return (
    <Button {...props} className={className}>
        {label}
    </Button>
  );
};

export default StyledButton;
