import React from 'react';
import { FormControl } from 'react-bootstrap';
import './StyledInput.css';
import { StyledInputProps } from '../types';

const StyledInput: React.FC<StyledInputProps> = ({ value, onChange, type, required = false, name, placeholder, ...props }) => {
  
  return (
    <FormControl
        className='custom-input'
        value={value} 
        onChange={onChange} 
        type={type} 
        required={required} 
        name={name} 
        placeholder={placeholder}
        {...props}
    />
  );
}

export default StyledInput;
