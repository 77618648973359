import React, { ChangeEvent, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import basicInfoPicture from '../../content/account/profile_basic.jpeg';
import stepperTwo from '../../content/account/stepper_2.svg';
import logo from '../../content/logo/logo_opacity.png';

import './NewUser.css';
import '../../Main.css';
import { updatePerson } from '../../api/personApi';
import { useAuthContext } from '../../App';
import StyledInput from '../atoms/input/StyledInput';
import StyledActionButton from '../atoms/button/StyledActionButton';
import StyledSelect from '../atoms/select/StyledSelect';
import { DateUtils, FORMATTED_MONTHS_LIST, FORMATTED_YEARS_LIST, MonthYear, TIMEZONES_SELECT_LIST } from '../../utils/DateUtils';
import { COUNTRIES_SELECT_LIST } from '../../utils/LocationUtils';

const BasicNewUser: React.FC = () => {
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const [currentDateSelect, setCurrentDateSelect] = useState<MonthYear>({
        month: '',
        year: ''
    });

    const [userInfo, setUserInfo] = useState({
      firstName: '',
      lastName: '',
      currentRole: '',
      currentCompany: '',
      companyStartDate: '',
      country: '',
      city: '',
      timezone: '',
    });
  
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setUserInfo(prevState => ({
        ...prevState,
        [name]: value,
      }));
    };
  
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (user.personId) {
        updatePerson(user.personId, {    
          name: userInfo.firstName,
          lastName: userInfo.lastName,
          timezone: userInfo.timezone,
          country: userInfo.country,
          city: userInfo.city,
          occupationList: [{title: userInfo.currentRole, companyName: userInfo.currentCompany, startDate: DateUtils.formatDate(currentDateSelect.month, currentDateSelect.year)}]
        }).then( response => {
            navigate('/profile/optional');
          })
      }
    };
  
    const handleSelectDateChange = (e: ChangeEvent<HTMLSelectElement>) => {
      setCurrentDateSelect({...currentDateSelect, [e.target.name]: e.target.value});
    };

    const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
      setUserInfo({...userInfo, [e.target.name]: e.target.value});
    };

    return (
      <>
            <Container fluid>
                <Row>
                    <Col md={4} style={{ padding: 0}}>
                        <img src={basicInfoPicture} className='basic-profile-image' alt="basicInfoPicture" />
                    </Col>
                    <Col md={8} style={{ paddingLeft: "10%", paddingRight: "10%", paddingTop: "10%" }}>
                        <Row>
                            <img src={logo} style={{ width: "50%", height: "100%", objectFit: "cover" }} alt="Logo" />
                        </Row>
                        <Row>
                            <img src={stepperTwo} style={{ width: "100%", height: "100%", objectFit: "cover", paddingTop: "20px" }} alt="stepper" />
                        </Row>
                        <Row style={{paddingTop: "34px"}}>
                            <Row>
                                <h4>We need just a bit of info before we <span className="main-color">Get Started</span></h4>
                            </Row>
                            <Row style={{paddingTop: "10px"}}>
                                <p>You’re just a few steps away from finding your meeting.</p>
                            </Row>
                        </Row>
                        <Row>
                      <Form onSubmit={handleSubmit}>
                        <Row>
                          <Col md={6} xs={12}>
                            <Form.Group style={{paddingTop: "16px"}}>
                                <StyledInput 
                                    type="text"
                                    name="firstName"
                                    value={userInfo.firstName || ''}
                                    onChange={handleChange}
                                    placeholder='First Name'
                                    required              
                                />
                            </Form.Group>
                          </Col>
                          <Col md={6} xs={12}>
                            <Form.Group style={{paddingTop: "16px"}}>
                                <StyledInput 
                                    type="text"
                                    name="lastName"
                                    value={userInfo.lastName || ''}
                                    onChange={handleChange}
                                    placeholder='Last Name'
                                    required              
                                />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Form.Group style={{paddingTop: "16px"}}>
                              <StyledInput 
                                  type="text"
                                  name="currentRole"
                                  value={userInfo.currentRole || ''}
                                  onChange={handleChange}
                                  placeholder='Current Role'
                                  required              
                              />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Form.Group style={{paddingTop: "16px"}}>
                              <StyledInput 
                                  type="text"
                                  name="currentCompany"
                                  value={userInfo.currentCompany || ''}
                                  onChange={handleChange}
                                  placeholder='Current Company'
                                  required              
                              />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Col md={6} xs={12}>
                              <Form.Group style={{paddingTop: "16px"}}>
                                  <StyledSelect
                                      onChangeSelect={handleSelectDateChange}
                                      options={FORMATTED_MONTHS_LIST} 
                                      name={'month'} 
                                      value={currentDateSelect.month}  
                                      placeholder='Company Start Month'                        
                                    />
                              </Form.Group>
                          </Col>
                          <Col md={6} xs={12}>
                            <Form.Group style={{paddingTop: "16px"}}>
                                <StyledSelect
                                  onChangeSelect={handleSelectDateChange}
                                  options={FORMATTED_YEARS_LIST} 
                                  name={'year'} 
                                  value={currentDateSelect.year}  
                                  placeholder='Company Start Year'                            
                                />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row style={{paddingTop: "16px"}}>
                          <Col xs={12}>
                              <Form.Group>
                                  <StyledSelect
                                      onChangeSelect={handleSelectChange}
                                      options={COUNTRIES_SELECT_LIST} 
                                      name={'country'} 
                                      value={userInfo.country ?? ''}  
                                      placeholder='Country'          
                                      required             
                                    />
                              </Form.Group>
                          </Col>
                          <Col xs={12}>
                            <Form.Group style={{paddingTop: "16px"}}>
                              <StyledInput 
                                  type="text"
                                  name="city"
                                  value={userInfo.city || ''}
                                  onChange={handleChange}
                                  placeholder='City'
                                  required
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row style={{paddingTop: "16px"}}>
                          <Form.Group>
                            <StyledSelect 
                              value={userInfo.timezone ?? ''}
                              name='timezone'
                              placeholder='Select your timezone'
                              required={true} 
                              onChangeSelect={handleSelectChange} 
                              options={TIMEZONES_SELECT_LIST}                              
                            />
                          </Form.Group>
                        </Row>
                        <Row style={{paddingTop: "16px", paddingLeft: "12px", paddingRight: "12px", paddingBottom: "20px"}}>
                          <StyledActionButton label={'Submit'} type="submit" />
                        </Row>
                        </Form>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
      
    );
}

export default BasicNewUser;