import React from 'react';
import './StyledActionButton.css'; 
import { StyledButtonProps } from '../types';
import { Button } from 'react-bootstrap';


const StyledActionButton: React.FC<StyledButtonProps> = ({ label, variant = 'primary', ...props }) => {
  const className = `custom-styled-action-button ${variant} ${props.className || ''}`;
  return (
    <div className={'custom-styled-action-button-wrapper'}>
        <Button {...props} className={className}>
        {label}
        </Button>
    </div>
  );
};

export default StyledActionButton;
