import React from 'react';
import './PricingComponent.css';
import { PricingComponentProps } from '../types';
import { Col, Container, Row } from 'react-bootstrap';
import StyledLabel from '../../atoms/label/StyledLabel';
import { IoCheckmarkOutline } from "react-icons/io5";
import StyledLink from '../../atoms/link/StyledLink';
import CircledButton from '../../atoms/button/CircledButton';


const PricingComponent: React.FC<PricingComponentProps> = ({ title, icon, pricePerMonth, benefitList, linkHref, buttonLabel, onClickButton }) => {
  return (
    <Container fluid style={{ minHeight: "480px", maxWidth: '90%' }} className='pricing-component-wrapper'>
        <Row>
            <Row>
                <Col style={{ marginTop: "20px", marginLeft: "30px", paddingBottom: "12px", paddingRight: "44px", maxWidth:"50px"}} className='pricing-component-icon-wrapper'>
                    {icon}
                </Col>
            </Row>
            <Row style={{ paddingTop: "20px", paddingLeft: "20px"}}>
                <StyledLabel label={title} />
            </Row>
            <Row className='price-styling' style={{ paddingLeft: "20px"}}>
                <Col>
                    {`${pricePerMonth}`}<span className='small-price-styling'>/mo</span>
                </Col>
            </Row>
            <Row>
                {benefitList.map((benefit) => (
                    <Row key={benefit} style={{ paddingTop: "20px", paddingLeft: "24px"}}> 
                        <Col xs="auto"><IoCheckmarkOutline /></Col> 
                        <Col style={{ paddingLeft: "1px"}} className='benefits-text'>{benefit}</Col>
                    </Row>
                ))}
            </Row>
            <Row style={{ paddingTop: "20px", paddingLeft: "20px"}}>
                <StyledLink href={linkHref} align='left' bold={true}>View all Features</StyledLink>
            </Row>
        </Row>
        <Row style={{ paddingLeft: "20px", paddingTop: "20px", paddingBottom: "20px", paddingRight: "30%"}}>
            <CircledButton label={buttonLabel} onClick={onClickButton} transitionX={'28px'} />
        </Row>
    </Container>
  );
}

export default PricingComponent;