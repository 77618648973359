import React from 'react';

import './NewsPage.css';
import '../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';
import FooterAddition from '../footer/FooterAddition';
import BlogItemComponent from '../molecules/blogitem/BlogItemComponent';
import { NewsBlogItems } from './NewsBlogItems';


const NewsPage: React.FC = () => {
    return (
        <>
            <Container fluid>
                <Row className='news-page-title-wrapper'>
                    <h5 style={{ fontFamily: 'Raleway' }} className="main-color">Our Blogs</h5>
                    <h2>
                        The Power of
                        <br />
                        <span className="main-color">Warm Introductions</span>
                    </h2>
                    <p style={{ marginTop: '16px' }}>
                        Introductable connects those seeking to generate business meetings or demos with those who have a close relationship with the meeting target.
                        <br />
                        These warm introductions immediately establish a higher level of trust and credibility than traditional cold outreach methods.
                    </p>
                </Row>
                <Row className='news-page-items-wrapper'>
                    <Row style={{ marginTop: '16px' }} className='news-page-items-wrapper-title'>
                        <h3>Recent <span className="main-color">Blogs</span></h3>
                    </Row>
                    {NewsBlogItems.map((blog, index) => (
                        <Col key={index} md={4} xs={12} style={{ marginTop: '16px' }}>
                            <BlogItemComponent 
                                title={blog.title} 
                                publishDate={blog.publishDate} 
                                description={blog.description} 
                                image={blog.image} 
                                blogLink={blog.blogLink} 
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
            <FooterAddition />
        </>
    );
}

export default NewsPage;
