import React from 'react';
import './ContactComponent.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';


const ContactComponent: React.FC = () => {
    return (
        <Container className='contact-styled-text'>
            <Row style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px"}}>
            <Row>
                <h5 className="main-color">Contact</h5>
            </Row>
            <Row>
                <Col><h2>Get in <span className="main-color">Touch</span></h2></Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
                <p>
                Have questions or want to learn more about Introductable? Get in touch with our team today.
                <br />
                Email: <a className='contact-link' href='mailto:info@introductable.com'>info@introductable.com</a>
                </p>
            </Row>
            </Row>
        </Container>
    );
    }

export default ContactComponent;
