import React, {useState, useEffect} from 'react';
import {getPersonCancelledMeetings, getPersonScheduledMeetings, getPersonSuccessMeetings} from '../../api/personApi';
import { useNavigate } from 'react-router-dom';
import './MeetingPage.css';
import { getUIText, MeetingStatusEnum } from '../../types/meetingStatus';
import '../../Main.css';
import { useAuthContext } from '../../App';
import { Col, Container, Row } from 'react-bootstrap';
import StyledTable from '../molecules/table/styledtable/StyledTable';
import StyledButton from '../atoms/button/StyledButton';
import { DateUtils } from '../../utils/DateUtils';
import { Meeting } from '../../types/meeting';

const MeetingList: React.FC = () => {
    const { user } = useAuthContext();
    const navigate = useNavigate();
    const [meetingStatusFilter, setMeetingStatusFilter] = useState<MeetingStatusEnum>(MeetingStatusEnum.PENDING);
    const [dashboardTableData, setDashboardTableData] = useState<string[][]>([]);
    const [tableRowIds, setTableRowIds] = useState<string[]>([]);
    const MEETING_LIST_TABLE_HEADERS = ['Meeting Target', 'Company', 'Title', 'Date Time', 'Requestor', 'Introductor', 'Meeting Status'];
  
    useEffect(() => {
        loadMeetings();
    }, [user.personId, meetingStatusFilter]);

    const loadMeetings = () => {
        if (user.personId !== undefined) {
            let getMeetings;
    
            switch (meetingStatusFilter) {
                case MeetingStatusEnum.PENDING:
                    getMeetings = getPersonScheduledMeetings;
                    break;
                case MeetingStatusEnum.SUCCESS:
                    getMeetings = getPersonSuccessMeetings;
                    break;
                case MeetingStatusEnum.CANCELED:
                    getMeetings = getPersonCancelledMeetings;
                    break;
                default:
                    return; 
            }

            getMeetings(user.personId)
                .then(response => {
                    processMeetingResponse(response.data);
                })
                .catch(error => {
                    console.error("Error loading meetings:", error);
                });
        }
    }

    const processMeetingResponse = (meetings: Meeting[]) => {
        const tableData: React.SetStateAction<string[][]> = [];
        const ids: React.SetStateAction<string[]> = [];
    
        meetings.forEach((meeting) => {
            const meetingRow = [
                meeting.expert ? `${meeting.expert.name} ${meeting.expert.lastName}` : '-',
                meeting.expert?.occupationList?.[0]?.companyName || '-',
                meeting.expert?.occupationList?.[0]?.title || '-',
                meeting.meetingDateTime ? DateUtils.formatReadableDateTime(meeting.meetingDateTime) : '-',
                meeting.interessant ? `${meeting.interessant.name} ${meeting.interessant.lastName}` : '-',
                meeting.introductor ? `${meeting.introductor.name} ${meeting.introductor.lastName}` : '-',
                meeting.meetingSubstatus ? getUIText(meeting.meetingSubstatus) : '-'
            ];
            tableData.push(meetingRow);
            ids.push(meeting.meetingId || '');
        });
    
        setDashboardTableData(tableData);
        setTableRowIds(ids);
    }

    function clickMeetingRow(meetingId: string): void {
        navigate(`/meetings/${meetingId}`);
    }

    return (
        <>
            <Container fluid style={{ minHeight: "calc(100vh - 100px)", maxWidth: '90%' }}>
                <Row style={{ paddingTop: "40px"}}>
                    <Col style={{maxWidth: "210px"}}><StyledButton label={'Pending Meetings'} variant={meetingStatusFilter === MeetingStatusEnum.PENDING ? 'third' : 'secondary'} onClick={() => setMeetingStatusFilter(MeetingStatusEnum.PENDING)}/></Col>
                    <Col style={{maxWidth: "210px"}}><StyledButton label={'Successful Meetings'} variant={meetingStatusFilter === MeetingStatusEnum.SUCCESS ? 'third' : 'secondary'} onClick={() => setMeetingStatusFilter(MeetingStatusEnum.SUCCESS)}/></Col>
                    <Col style={{maxWidth: "210px"}}><StyledButton label={'Cancelled Meetings'} variant={meetingStatusFilter === MeetingStatusEnum.CANCELED ? 'third' : 'secondary'} onClick={() => setMeetingStatusFilter(MeetingStatusEnum.CANCELED)}/></Col>
                </Row>
                <Row style={{ paddingTop: "30px"}}>
                    <h5>{'Requested Meetings'}</h5>
                </Row>
                <Row style={{ paddingTop: "20px"}}>
                    <StyledTable 
                            tableHeaders={MEETING_LIST_TABLE_HEADERS} 
                            tableData={dashboardTableData} 
                            tableRowsIds={tableRowIds}
                            onClickRow={clickMeetingRow}
                            lastColumnColored={true}
                        /> 
                </Row>
            </Container>          
        </>
    );
}

export default MeetingList;
