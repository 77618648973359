import React from 'react';
import './FbiStrategiesSalesPage.css';
import '../../../Main.css';
import { Container, Row } from 'react-bootstrap';
import FooterAddition from '../../footer/FooterAddition';
import blogImage from '../../../content/blog/fbistrategiessales/fbiStrategiesSales.png';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';


const FbiStrategiesSalesPage: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
        <Container fluid>
            <Row className='fbi-strategies-sales-wrapper'>
                <Row style={{marginTop: '30px'}}>
                    <h2 className='fbi-strategies-sales-mobile-text' >From Standoff to <span className="main-color">Sales Call: FBI Strategies</span> for Building Trust</h2>
                </Row>
                <Row style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={blogImage} className="fbi-strategies-sales-image" alt="leaveMeAloneImage" />
                </Row>
                <Row style={{marginTop: '30px'}}>
                    <h4>How to Make a Connection and Leave a Favorable Impression with a Sales Prospect on an Initial Call (Inspired by FBI Negotiation Tactics)</h4>
                </Row>
                <Row style={{marginTop: '20px'}}>
                    <p>
                        Sales might not seem like it has much in common with FBI negotiations, many of the same principles that help federal agents resolve tense situations can be applied to the sales world. So how can you can blend effective sales strategies with proven negotiation techniques to build rapport and close deals? 
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>1. Build Rapport with Empathy (Mirroring and Labeling)</h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        One of the FBI’s core tactics in negotiation is creating a connection through empathy. They achieve this by actively listening, mirroring, and labeling emotions to help the other party feel understood. In sales, doing the same can make your prospect feel like you genuinely understand their needs and challenges.
                        <br/><br/>
                        How to do it:
                        <br/>
                        - Mirroring: Repeat the last few words or key phrases your prospect says to show that you're engaged in the conversation and following their train of thought. This also encourages them to elaborate further, giving you more insight into their needs.
                        <br/><br/>
                        Example:<br/>
                        Prospect: “We’re struggling with our current software integration.”<br/><br/>
                        You: “Software integration?”<br/><br/>
                        Prospect: “Yes, it’s causing delays and miscommunication across teams.”<br/><br/>
                        - Labeling: Identify the emotions or concerns underlying your prospect’s words and state them out loud. This simple technique helps validate their feelings and concerns.
                        <br/><br/>    
                        Example:<br/>
                        “It sounds like you’re feeling frustrated with the inefficiency of your current solution.”<br/><br/>
                        This kind of emotional acknowledgment fosters trust and shows your prospect that you're not just trying to sell them something—you genuinely want to help solve their problems.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>2. Stay Calm and Show Patience</h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        FBI negotiators know that staying calm under pressure is key. In sales, when a prospect seems disinterested or even defensive, it’s easy to feel like you’re losing control of the conversation. However, it’s essential to remain patient and composed. People appreciate calm, level-headed communication, especially in a business setting.
                        <br/><br/>
                        How to do it:
                        <br/>
                        - Slow down the conversation when needed. If a prospect throws up an objection or expresses doubt, don’t rush to respond. Take a breath, acknowledge their concern, and then thoughtfully address it. This gives them space to think and helps you avoid coming across as overly pushy.
                        <br/>
                        - Ask open-ended questions that keep the conversation going without pressure. For example: “What challenges are you hoping to solve in the next few months?”
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>3. Use Tactical Empathy to Understand their Pain Points</h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        The FBI calls it tactical empathy—a deep understanding of the other party’s emotions and motivations. In sales, this means honing in on the specific pain points your prospect is experiencing and demonstrating that you truly understand their needs.
                        <br/><br/>
                        How to do it:<br/>
                        - Ask thoughtful, open-ended questions like: "Can you walk me through your current process?" or "What’s been the biggest challenge for you recently?"
                        <br/>                
                        - Listen carefully to their responses without interrupting or jumping into your sales pitch. Only after they’ve had the chance to fully explain their situation should you start framing your solution.
                        <br/><br/>
                        When you demonstrate that you understand their pain points, it’s easier to present your product or service as the natural solution.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>4. Control the Pace of the Conversation with ‘No-Oriented’ Questions</h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        Sales people are often taught to ask questions that elicit a ‘yes’ so that prospects are more likely to answer positively when asked to buy. This is actually counter productive. A common FBI technique is to ask questions designed to get the other person to say “no,” which can actually make them feel in control and more comfortable. It’s counterintuitive, but people are often more willing to engage if they don’t feel like they’re being pushed to say “yes” all the time.
                        <br/><br/>
                        How to do it:<br/>
                        - Instead of asking “Does this solution sound good to you?”, ask “Would this solution be totally out of line with what you’re looking for?” A “no” answer gives them the power to correct or clarify, opening up the conversation for further exploration.
                        <br/><br/>
                        - Another example: “Is it crazy to think this could solve your problem?” Again, if they answer “no,” it sets the stage for more honest dialogue.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>5. Use the Power of Silence</h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        FBI negotiators are experts in using silence to their advantage. After asking a probing question or making an important point, they stay quiet and wait for the other person to fill the gap. This technique works just as well in sales.
                        <br/><br/>
                        How to do it:<br/>
                        - After asking a key question—such as “What’s the biggest obstacle you’re facing right now?”—resist the urge to jump in if your prospect takes a moment to respond. Give them the space to think and articulate their thoughts. This not only shows respect for their ideas but also gives you better insight into their real concerns.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>6. Create a Collaborative Tone, Not an Adversarial One</h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        FBI negotiations are about finding a mutually beneficial solution to avoid conflict, and successful sales follow the same principle. Instead of focusing on closing a deal, aim to collaborate with your prospect on finding the best solution for their needs.
                        <br/><br/>
                        How to do it:<br/>
                        - Use phrases like “Let’s explore how we can work together to address that issue” instead of “Here’s what we can do for you.”
                        <br/>
                        - Position yourself as a problem-solver, not just someone trying to close the sale.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>7. Closing with the Prospect’s Needs in Mind (Accusation Audit)</h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        An accusation audit is a negotiation technique where you address potential objections before the other person raises them. In sales, acknowledging any hesitations a prospect might have upfront can ease concerns and lead to a smoother close.
                        <br/><br/>
                        How to do it:<br/>
                        - Before wrapping up your call, acknowledge potential objections like: “You might be wondering if this will integrate smoothly with your current systems,” or “I’m sure you’re concerned about the cost.”
                        <br/>
                        - This transparency builds trust and shows that you’re thinking about the sale from their perspective, making it easier for them to feel comfortable moving forward.
                    </p>
                </Row>
                <Row style={{marginTop: '20px'}}>
                    <p>
                        While you are unlikely to have to speak with a kidnapper, using FBI negotiation tactics in your sales calls can improve your ability to make connections and leave a lasting, favorable impression. By emphasizing empathy, asking thoughtful questions, and allowing for moments of silence, you create a more relaxed, trusting environment where prospects feel understood and valued.
                        <br/><br/>
                        Remember, the goal is to build a relationship, not just close a deal. By adopting these strategies, you’ll find that your prospects are more engaged and open to continuing the conversation, setting the foundation for a successful sales process.
                        <br/><br/>
                        Building relationships can be hard, which is why Introductable uses existing relationships to shorten the sales process and build immediate credibility. If a training session at Quantico isn’t in your future, visit Introductable.com and build a relationship today.
                    </p>
                </Row>
                <Row className='justify-items-start' style={{ marginTop: '35px', marginBottom: '25px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
}

export default FbiStrategiesSalesPage;
