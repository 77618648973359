import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { ActionFormProps } from './ActionFormTypes';
import '../../../../Main.css';

const PendingMeetingConfirmedForm: React.FC<ActionFormProps> = ({ meeting, onSubmit }) => {
    return (
        <Container>
            <Row style={{marginTop: '20px'}}>
                <h4>Meeting Confirmed</h4>  
            </Row>
            <Row style={{marginTop: '20px'}}>
                <h5>All Good</h5>  
            </Row>
            <Row style={{marginTop: '20px'}}>
                <p>The meeting has been set. Great job, everyone! The meeting is now on your Google calendar.</p> 
                <p>If your meeting needs to be rescheduled, it can be changed via Introductable. You can use our chat feature to make finding a time easier.</p>  
                <p>Please email us with questions or problems info@introductable.com</p>  
            </Row>
        </Container>
    );
}

export default PendingMeetingConfirmedForm;
