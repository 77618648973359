import { Col, Container, Row } from "react-bootstrap";
import PricingComponent from "../organisms/pricing/PricingComponent";
import { useNavigate } from "react-router-dom";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

const PricingEssentials: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
            <Container fluid style={{ height: "calc(100vh - 100px)", maxWidth: '90%' }}>
                <Row className="centred-element" style={{ marginTop: "50px"}}>
                    <h2><span className="main-color">Essentials</span></h2>
                </Row>
                <Row className="justify-content-space-between" >
                    <Col style={{ marginLeft: "25%", marginRight: "25%"}}> 
                        <PricingComponent 
                            icon={<IoMdCheckmarkCircleOutline className="color-element"/>} 
                            title={"Essentials"} 
                            pricePerMonth={"$0.00"} 
                            benefitList={[
                                'Pay As You Go-Perfect for trying Introductable out',
                                'Pay only for those meetings you have',
                                'The option if you plan on primarily making introductions',
                            ]} 
                            linkHref='/pricing/essentials'
                            onClickButton={() => navigate(`/pricing/essentials`)}
                            buttonLabel='Free account'
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default PricingEssentials;