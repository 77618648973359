import React from 'react';
import './UnlockThePowerComponent.css';
import { Col, Container, Row } from 'react-bootstrap';
import unlockThePowerImage from '../../../content/landing-page/unlockthepower.jpeg';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';

const UnlockThePowerComponent: React.FC = () => {
    const navigate = useNavigate();

    const onGetStartedClick = () => {
        navigate(`/signup`);
    }

    return (
        <Container className='unlock-the-power-wrapper'>
            <Row style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px"}}>
                <Col xs={12} md={6}>
                    <img src={unlockThePowerImage} className='unlock-the-power-image' style={{ height: "auto" }} alt="unlockThePowerImage" />
                </Col>
                <Col xs={12} md={6}>
                    <Row style={{marginTop: '20px'}} className='unlock-the-power-text'>
                        <h2>Unlock the <span className="main-color">Power of Trusted Introductions</span> and Elevate your <span className="main-color">Business Today!</span></h2>
                    </Row>
                    <Row style={{marginTop: '20px'}}>
                        <Col>
                            <CircledButton 
                                label={"Join Introductable Now"} 
                                onClick={onGetStartedClick} 
                                transitionX={"10px"} 
                                maxWidth={'260px'}
                            />
                        </Col>
                        <Col className="unlock-the-power-mobile-no-display"></Col>
                    </Row>

                </Col>
            </Row>
        </Container>
    );
}

export default UnlockThePowerComponent;
