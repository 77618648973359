import React from 'react';
import './TenReasonsColdReachPage.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';

import peopleTalk from '../../../content/blog/people-talking_scaled.jpeg'
import FooterAddition from '../../footer/FooterAddition';
import { GoDotFill } from 'react-icons/go';
import StyledLink from '../../atoms/link/StyledLink';

const TenReasonsColdReachPage: React.FC = () => {
    return (
        <>
        <Container fluid className='justify-content-center'>
            <Row>
                    <img src={peopleTalk} className="ten-reasons-cold-reach-page-title-image" alt="peopleTalk" />
            </Row>
            <Row style={{paddingLeft: "20px", paddingTop: "50px", paddingRight: "20px", paddingBottom: "40px"}} className='justify-content-center'>
            <Row>
                <Col className='ten-reasons-cold-reach-page-title'><h2><span className="main-color">Top 10 Reasons</span> Why Cold Outreach Makes Us Want to Crawl Under a Rock</h2></Col>
            </Row>
            <Row>
                <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                    <GoDotFill className='main-color'/> 1. Nothing says "I love you" like a copy-pasted message to 10,000 strangers.
                    <br/><br/>
                    <GoDotFill className='main-color'/> 2. It's the perfect way to ensure your email address ends up on every spam list known to mankind.
                    <br/><br/>
                    <GoDotFill className='main-color'/> 3. You get to experience the thrill of being ignored by more people than you ever thought possible!
                    <br/><br/>
                    <GoDotFill className='main-color'/> 4. Your heartfelt pitch gets a lower response rate than a Nigerian prince's inheritance offer.
                    <br/><br/>
                    <GoDotFill className='main-color'/> 5. You'll develop a newfound appreciation for crickets and their soothing chirps of silence.
                    <br/><br/>
                    <GoDotFill className='main-color'/> 6. It's an excellent way to make your self-esteem do a vanishing act faster than a magician's rabbit.
                    <br/><br/>
                    <GoDotFill className='main-color'/> 7. You'll become intimately familiar with every possible way to say "No, thanks" or "Please remove me from your list."
                    <br/><br/>
                    <GoDotFill className='main-color'/> 8. Your response rate on Tinder seems high in comparison.
                    <br/><br/>
                    <GoDotFill className='main-color'/> 9. Just checking if you saw my last 15 emails. Sure, they'll respond this time. Totally.
                    <br/><br/>
                    <GoDotFill className='main-color'/> 10. It's a fantastic way to ensure that your carefully crafted message gets less attention than a cat video on the internet.
                    <br/><br/>
                    </p>
            </Row>
            <Row>
                <p>
                    <b>Bonus:</b> You'll develop psychic powers trying to predict which prospects will block you before even reading your message!
                </p>
            </Row>
            <Row>
                <p>
                    Remember, if at first you don't succeed... maybe it's time to rethink your marketing strategy. 
                    <StyledLink href={'/signup'}><b> Introductable</b></StyledLink>; for when you absolutely have to have a great meeting with someone.
                </p>
            </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
    }

export default TenReasonsColdReachPage;
