import React, {useEffect, useState, ChangeEvent, FormEvent} from 'react';
import {Form, Container, Row, Col} from 'react-bootstrap';
import { User, UserReason, userReasonOptions } from '../../types/user';
import '../../Main.css';
import StyledInput from '../atoms/input/StyledInput';
import StyledCheckbox from '../atoms/checkbox/StyledCheckbox';
import StyledLink from '../atoms/link/StyledLink';
import StyledSelect from '../atoms/select/StyledSelect';
import { SelectOption } from '../atoms/types';
import StyledActionButton from '../atoms/button/StyledActionButton';
import { useAuthContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export interface UserFormProps {
    onSubmit: (user: User) => void;
    isNewUser: boolean;
}

const UserForm: React.FC<UserFormProps> = ({onSubmit, isNewUser}) => {
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const [errors, setErrors] = useState({password: '', termsConditions: ''});
    const [editUser, setEditUser] = useState<User>({ email: '', personId: undefined, userReason: undefined, termsConditions: false, rememberMe: false});
    const [confirmPassword, setConfirmPassword] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const userReasonOptionsList: SelectOption[] = Object.entries(userReasonOptions).map(([value, label]) => ({
        value,
        label
    }));

    useEffect(() => {
        if (user.personId !== undefined) {
            navigate('/dashboard');
        }
    }, [isNewUser]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = e.target;
        let newErrors = { ...errors };
        
        if (name === "password") {
            if (!isValidPassword(value)) {
                newErrors.password = "Password must be at least 8 characters long, include a number, an uppercase and a lowercase letter.";
            } else {
                newErrors.password = ''; 
            }
            setEditUser({...editUser, [e.target.name]: e.target.value});
        } else if (name === 'termsConditions' || name === 'rememberMe') {
            setEditUser({...editUser, [name]: checked});
            if (checked) {
                newErrors.termsConditions = '';
            }
        } else {
            setEditUser({...editUser, [e.target.name]: e.target.value});
        }

        setErrors(newErrors);
    };

    const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setEditUser({...editUser, [e.target.name]: e.target.value});
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isNewUser && !editUser.termsConditions) {
            setErrors(prevErrors => ({ ...prevErrors, termsConditions: 'You must agree to the terms and conditions.' }));
            return;
        }
        onSubmit(editUser);
    };

    const isValidPassword = (password: string) => {
        if (!isNewUser) {
            return true;
        }
        const minLength = 8;
        const hasNumber = /[0-9]/.test(password);
        const hasUpper = /[A-Z]/.test(password);
        const hasLower = /[a-z]/.test(password);
    
        return password.length >= minLength && hasNumber && hasUpper && hasLower;
    };

    const passwordMatch = editUser.password === confirmPassword;

    return (
        <Container>
        <Form onSubmit={handleSubmit}>
            <Row>
            <Form.Group>
                <StyledInput 
                    type="email"
                    name="email"
                    value={editUser.email || ''}
                    onChange={handleChange}
                    placeholder='Email address'
                    required              
                />
            </Form.Group>
            </Row>
            <Row>
            <Form.Group style={{ paddingTop: "18px" }}>
                <StyledInput 
                    type={showPassword ? 'text' : 'password' }
                    name="password"
                    value={editUser.password || ''}
                    onChange={handleChange}
                    required
                    placeholder='Password'
                />
                {errors.password.length > 0 && <div style={{ color: 'red' }}>{errors.password}</div>}
            </Form.Group>
            </Row>

            {isNewUser ? (
                <>
                <Row>
                    <Form.Group style={{ paddingTop: "18px" }}>
                        <StyledInput 
                            type={showPassword ? 'text' : 'password' }
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            required
                            placeholder='Confirm Password'
                        />
                        {confirmPassword !== '' && !passwordMatch && (
                            <div style={{ color: 'red' }}>Passwords do not match</div>
                        )}
                    </Form.Group>
                </Row>
                <Row style={{ paddingTop: "10px" }}>
                    <StyledCheckbox 
                        checked={showPassword} 
                        onChange={() => setShowPassword(!showPassword)} 
                        label={'Show Password'} />
                </Row>
                <Row>
                <Form.Group style={{ paddingTop: "18px" }}>
                    <StyledSelect 
                            onChangeSelect={handleSelectChange}
                            options={userReasonOptionsList}
                            name={'userReason'}
                            value={editUser.userReason ? UserReason[editUser.userReason] : ''} 
                            placeholder={'Reason for joining'}                        >
                        
                    </StyledSelect>
                </Form.Group>
                </Row>
                <Row>
                <Form.Group style={{ paddingTop: "18px" }}>
                    <StyledCheckbox 
                        type="checkbox"
                        label={
                            <span>
                                I agree to the <a href="/terms-conditions" target="_blank" rel="noopener noreferrer"
                                    style={{color: '#33C38F', textDecoration: 'none', fontFamily: 'Raleway' }}
                                >
                                    TERMS & CONDITIONS
                                </a>
                            </span>
                        }
                        name="termsConditions"
                        checked={!!editUser.termsConditions}
                        onChange={handleChange}
                        isInvalid={!!errors.termsConditions}
                    />
                    {errors.termsConditions && <div style={{ color: 'red' }}>{errors.termsConditions}</div>}
                </Form.Group>
                </Row>
                </>
            ) : (
                <Container>
                    <Row style={{ paddingTop: "10px" }}>
                    <Col className="d-flex justify-content-start">
                        <StyledCheckbox 
                            checked={showPassword} 
                            onChange={() => setShowPassword(!showPassword)} 
                            label={'Show Password'} />
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <StyledLink href={'/forget-password'}>Forget password</StyledLink>
                    </Col>
                    </Row>

                </Container>
            )}

            <br/>
            <StyledActionButton label={isNewUser ? 'Create your account' : 'Login to account'} type="submit" />

        </Form>
        </Container>
    );
}

export default UserForm;
