import React from 'react';
import './NeedRuthlessHonestyPage.css';
import '../../../Main.css';
import { Container, Row } from 'react-bootstrap';
import FooterAddition from '../../footer/FooterAddition';
import blogImage from '../../../content/blog/ruthlesshonesty/ruthlessHonetyImage.png';
import blogImageMatrix from '../../../content/blog/ruthlesshonesty/ruthlessHonestyTable.png';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';
import { GoDotFill } from 'react-icons/go';


const NeedRuthlessHonestyPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
        <Container fluid>
            <Row className='need-ruthless-honesty-wrapper'>
                <Row style={{marginTop: '30px'}}>
                    <h2 className='need-ruthless-honesty-mobile-text' >The Need for <span className="main-color">Ruthless Honesty</span> in Lead Evaluation</h2>
                </Row>
                <Row style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={blogImage} className="need-ruthless-honesty-image" alt="ruthlessHonestyTreeImage" />
                </Row>
                <Row style={{marginTop: '30px'}}>
                    <h5 className='need-ruthless-honesty-mobile-text' >TLDR: Critiquing your lead process and the leads generated is fundamental and is the basis for your entire sales process. i.e. Garbage in, garbage out</h5>
                </Row>
                <Row style={{marginTop: '40px'}}>
                    <p>
                        While it may be tempting to view every lead as a potential goldmine, the reality is that not all leads are created equal. Being ruthlessly honest in your lead evaluation process is crucial for long-term success. This means having the courage to disqualify leads that don't meet your criteria, even if it means a temporary hit to your pipeline. Remember, a smaller pool of high-quality leads is far more valuable than a large pool of unqualified prospects. This honesty extends to self-evaluation as well – be willing to admit when your product or service isn't the right fit for a lead. This integrity not only saves time and resources but also builds trust and credibility in your industry. By adopting a ruthlessly honest approach, you're not just optimizing your sales process; you're laying the foundation for sustainable growth and long-lasting client relationships.
                    </p>
                    <p style={{ paddingTop: '10px'}}>
                        <h5><b>The Importance of Lead Evaluation</b></h5>
                        <br/>
                            Evaluating leads is crucial for several reasons:
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> 1. <b>Time efficiency:</b> By focusing on qualified leads, your sales team can spend more time building meaningful connections with prospective clients who are more likely to convert.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> 2. <b>Resource allocation:</b> Proper evaluation helps allocate resources to the most promising opportunities.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> 3. <b>Improved conversion rates:</b> Targeting the right leads increases the likelihood of successful conversions.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> 4. <b>Enhanced customer satisfactiony:</b> By understanding lead needs, you can provide more tailored solutions.
                        <br/>
                    </p>
                    <p style={{ paddingTop: '10px'}}>
                        <h5><b>Key Questions for Lead Evaluation</b></h5>
                        <br/>
                            To effectively evaluate leads, consider asking the following questions:
                        <br/>
                        <br/>
                        1. What is the lead's budget for this solution?
                        <br/>
                        2. What is their timeline for implementation?
                        <br/>
                        3. Who are the key decision-makers in their organization?
                        <br/>
                        4. What specific pain points or challenges are they facing?
                        <br/>
                        5. How does our solution align with their business goals?
                        <br/>
                        6. What is their current process or solution for addressing this need?
                        <br/>
                        7. Have they worked with similar solutions or vendors in the past?
                        <br/>
                        8. What are their expectations for ROI?
                        <br/>
                        9. Are there any potential obstacles to implementing our solution?
                        <br/>
                        10. How does this purchase fit into their overall business strategy?
                        <br/>
                    </p>
                    <p style={{ paddingTop: '10px'}}>
                        <h5><b>Developing a Lead Evaluation Matrix</b></h5>
                        <br/>
                        Creating a lead evaluation matrix can help systematize your approach to assessing potential clients. Here's a simple framework to get started:
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> 1. <b>Identify key criteria:</b> Based on your ideal customer profile, determine the most important factors for lead quality (e.g., budget, timeline, decision-making authority).
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> 2. <b>Assign weights:</b> Give each criterion a weight based on its importance to your sales process.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> 3. <b>Create a scoring system:</b> Develop a consistent scoring method for each criterion (e.g., 1-5 scale).
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> 4. <b>Calculate total scores:</b> Multiply each criterion's score by its weight and sum the results.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> 5. <b>Set thresholds:</b> Establish score ranges to categorize leads (e.g., hot, warm, cold).
                        <br/>
                    </p>
                </Row>
                <Row style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p>Example Matrix:</p>
                    <img src={blogImageMatrix} className="need-ruthless-honesty-image-table" alt="blogImageMatrix" />
                </Row>
                <Row style={{marginTop: '20px'}}>
                    <p style={{ paddingTop: '10px'}}>
                        <h5><b>Benefits for Your Sales Team</b></h5>
                        By implementing a robust lead evaluation process, your sales team can:
                        <br/>
                        <br/>
                        1. Prioritize high-potential leads
                        <br/>
                        2. Tailor their approach to each prospect's specific needs
                        <br/>
                        3. Improve time management and productivity
                        <br/>
                        4. Increase overall conversion rates
                        <br/>
                        5. Build stronger, more meaningful relationships with clients
                        <br/>
                    </p>
                    <p style={{ paddingTop: '10px'}}>
                        <h5><b> Leveraging Introductable for Quality Leads</b></h5>
                        <br/>
                        While a thorough lead evaluation process is crucial, starting with high-quality leads can significantly streamline your sales efforts by:
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> 1. <b>Leveraging existing networks:</b> Tapping into trusted connections provides a strong foundation for potential leads.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> 2. <b>Pre-qualifying prospects:</b> The platform's focus on meaningful introductions ensures that leads are more likely to be relevant and interested.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> 3. <b>Establishing trust:</b> Introductions through mutual connections create an immediate basis for trust, accelerating the relationship-building process.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> 4. <b>Saving time:</b> By providing leads with a built-in level of trust and relevance, Introductable can significantly reduce the time spent on initial lead qualification.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> 5. <b>Improving conversion rates:</b> Leads generated through trusted introductions are more likely to convert, as they come with an implicit endorsement.
                        <br/>
                    </p>
                    <p>
                    Remember, the key to sales success lies not just in the quantity of leads, but in the quality of your evaluation process and the strength of the connections you build. With the right tools and strategies in place, your sales team can maximize their potential and achieve remarkable results.
                    </p>
                </Row>
                <Row className='justify-items-start' style={{ marginTop: '35px', marginBottom: '25px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
}

export default NeedRuthlessHonestyPage;
