import React from 'react';
import './SalesLifeCycleTreePage.css';
import '../../../Main.css';
import { Container, Row } from 'react-bootstrap';
import FooterAddition from '../../footer/FooterAddition';
import blogImage from '../../../content/blog/saleslifecycle/tree.jpg';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';


const SalesLifeCycleTreePage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
        <Container fluid>
            <Row className='sales-life-cycle-tree-wrapper'>
                <Row style={{marginTop: '30px'}}>
                    <h2 className='sales-life-cycle-tree-mobile-text' >Sales Process vs. <span className="main-color">The Life Cycle of a Tree:</span> A Wholly Contrived Comparison</h2>
                </Row>
                <Row style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={blogImage} className="sales-life-cycle-tree-image" alt="salesLifeCycleTreeImage" />
                </Row>
                <Row style={{marginTop: '40px'}}>
                    <p>
                        Joyce Kilmer wrote, 
                    </p>
                    <p>
                        “I think that I shall never see
                    </p>
                    <p>
                        A poem lovely as a tree.”
                    </p>
                    <p>
                        Without diminishing his lovely poem and it’s subject, for those in sales it would probably be fair to amend it to say,
                    </p>
                    <p>
                        “I think I shall rarely see
                    </p>
                    <p>
                        A sale as easy as from a warm lead”
                    </p>
                    <p>
                        I’ll grant you that very few articles on sales start with a poem, but as I look out my window, and I see the leaves of the trees begin to turn, I am inspired by cycles: cycles of life and the (less inspirational to be sure) sales cycle. 
                    </p>
                    <p>
                        Let’s torture this comparison for a bit, shall we?
                    </p>
                    <p>
                        The process of nurturing a prospect from initial contact to a signed contract can often feel like an arduous journey. Surprisingly, this journey shares many parallels with the life cycle of a tree—from the planting of a seed to the growth of a towering oak.
                    </p>
                    <p>
                        The life of a tree is a beautiful journey from a tiny seed to a towering, fully-grown tree, and believe it or not, this journey has a lot in common with the enterprise sales process. Just like a tree needs nurturing and time to grow, so does a sales opportunity. Let’s explore this comparison in a more down-to-earth way.
                    </p>
                    <p style={{ paddingTop: '10px'}}>
                        <h5><b>Planting the Seed: Identifying a Sales Opportunity</b></h5>
                        <br/>
                            Every great sales journey starts with a seed—a new lead or a potential opportunity. Just as a tree begins its life as a small seed, the sales process begins with identifying a prospect. This might be someone who has shown interest in your product or a company that could benefit from your solutions. The key here is to plant that seed in fertile ground by doing your research and targeting the right audience.
                        <br/>
                    </p>
                    <p style={{ paddingTop: '10px'}}>
                        <h5><b>Watering and Nurturing: Building Relationships</b></h5>
                        <br/>
                            Once the seed is planted, it needs care and attention to grow. In sales, this is where you start building relationships. Just like watering a seed, you need to consistently engage with your prospects, providing them with valuable information and understanding their needs. This stage is all about nurturing—keeping the conversation going, offering solutions, and showing that you’re there to help them grow.
                        <br/>
                    </p>
                    <p style={{ paddingTop: '10px'}}>
                        <h5><b>Growing Roots: Establishing Trust</b></h5>
                        <br/>
                            As a tree’s roots take hold and grow deeper into the soil, your relationship with the prospect should also become more solid. This stage is crucial—it's when trust is built. By demonstrating your expertise, reliability, and understanding of the prospect's challenges, you help them see the value of working with you. Just like roots provide stability to a tree, trust provides a strong foundation for a successful deal.
                        <br/>
                    </p>
                    <p style={{ paddingTop: '10px'}}>
                        <h5><b>Branching Out: Exploring Opportunities</b></h5>
                        <br/>
                            As the tree grows, it begins to sprout branches, reaching out in different directions. Similarly, in the sales process, this is the stage where you start to explore different opportunities within the prospect's organization. You might uncover additional needs or find other departments that could benefit from your solution. This branching out is vital for expanding the scope of your potential deal.
                        <br/>
                    </p>
                    <p style={{ paddingTop: '10px'}}>
                        <h5><b>Bearing Fruit: Closing the Deal</b></h5>
                        <br/>
                            Finally, after all the nurturing, the tree begins to bear fruit—a tangible result of all your hard work. In the sales world, this is the moment when the deal is closed. All the time and effort you’ve put into cultivating the relationship pays off. Just as the tree’s fruit is a reward for its growth, closing a deal is a reward for your perseverance and dedication.
                        <br/>
                    </p>
                    <p style={{ paddingTop: '10px'}}>
                        <h5><b>Continued Care: Maintaining the Relationship</b></h5>
                        <br/>
                            Even after the tree has borne fruit, it still needs care to continue thriving. In sales, closing a deal doesn’t mean your work is done. It’s important to maintain the relationship with your new customer, ensuring they’re satisfied and finding ways to continue adding value. This ongoing care can lead to repeat business, referrals, and a long-lasting partnership.
                        <br/>
                    </p>
                    <p style={{ paddingTop: '10px'}}>
                        <h4><b>The Cycle Continues</b></h4>
                        <br/>
                         Just as a tree goes through cycles of growth, dormancy, and renewal, the sales process is cyclical. Each deal closed is an opportunity to plant new seeds, start new relationships, and continue growing. By understanding and embracing this cycle, you can nurture your sales opportunities from tiny seeds into fruitful partnerships that stand the test of time.
                        <br/>
                    </p>
                </Row>
                <Row className='justify-items-start' style={{ marginTop: '35px', marginBottom: '25px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
}

export default SalesLifeCycleTreePage;
