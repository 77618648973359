import React from 'react';
import './PowerOfWarmIntroductionsPage.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';
import firstImage from '../../../content/blog/warmintroductions/image-first.jpeg'
import secondImage from '../../../content/blog/warmintroductions/image-second.jpeg'
import thirdImage from '../../../content/blog/warmintroductions/image-third.png'
import fourthImage from '../../../content/blog/warmintroductions/image-fourth.jpeg'
import fifthImage from '../../../content/blog/warmintroductions/image-fifth.jpeg'
import FooterAddition from '../../footer/FooterAddition';


const PowerOfWarmIntroductionsPage: React.FC = () => {
    return (
        <>
        <Container fluid>
            <Row className='warm-introductions-title-wrapper'>
                    <h5 className="main-color">Warm Introductions</h5>
                    <h1>
                        The <span className="main-color">Power</span> of
                        Warm <span className="main-color">Introductions</span>
                    </h1>
            </Row>
            <Row className='warm-introductions-wrapper'>
                <Row>
                    <p>
                        In today's hyper-connected world, building valuable relationships is pivotal to personal and professional success. 
                        A personal introduction is more than 500% more likely to lead to a successful outcome. 
                        Among the many tools in our networking arsenal, warm introductions stand out as an invaluable asset. 
                        Introductable is characterized by the personal touch and building a trust-based foundation.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }} className='align-items-center'>
                    <Col xs={10} md={5} className='warm-introductions-image-wrapper'>
                        <img src={firstImage} className="warm-introductions-image" alt="firstImage" />
                    </Col>
                    <Col xs={2} md={1} className='warm-introductions-rectangle' style={{backgroundColor: '#ECECEC'}}>
                        <h4>01</h4>
                    </Col>
                    <Col xs={12} md={6}>
                        <p>
                        Trust is the Currency of Success: Warm introductions are rooted in trust. 
                        When a trusted contact vouches for you, it instantly boosts your credibility and paves the way for meaningful conversations. 
                        Trust is the foundation of all enduring relationships, and warm introductions provide the essential bridge to building it.
                        </p>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }} className='align-items-center'>
                    <Col xs={12} md={6}>
                        <p>
                            Cutting Through the Noise: In an age of information overload, standing out is a challenge. 
                            Warm introductions cut through the noise by leveraging the reputation and credibility of your mutual connection. 
                            This direct connection provides a shortcut to the heart of the matter, saving time and effort in the networking process.
                        </p>
                    </Col>
                    <Col xs={2} md={1} className='warm-introductions-rectangle' style={{backgroundColor: '#FFF8E0'}}>
                        <h4>02</h4>
                    </Col>
                    <Col xs={10} md={5}>
                        <img src={secondImage} className="warm-introductions-image" alt="secondImage" />
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }} className='align-items-center'>
                    <Col xs={10} md={5} className='warm-introductions-image-wrapper'>
                        <img src={thirdImage} className="warm-introductions-image" alt="thirdImage" />
                    </Col>
                    <Col xs={2} md={1} className='warm-introductions-rectangle' style={{backgroundColor: '#E7F8F2'}}>
                        <h4>03</h4>
                    </Col>
                    <Col xs={12} md={6}>
                        <p>
                            A Human Connection: In a world driven by digital interactions, genuine human connections are increasingly rare and valuable. 
                            Warm introductions inject a personal touch into professional relationships, reminding us that behind every business endeavor 
                            are individuals with unique stories and aspirations.
                        </p>
                    </Col>
                </Row>
                <Row style={{ marginTop: '20px' }} className='align-items-center'>
                    <Col xs={12} md={6}>
                        <p>
                            A Competitive Advantage: In a competitive world, anything that sets you apart is an advantage. 
                            Utilizing warm introductions strategically can give you the edge by opening doors that might remain closed to others.
                        </p>
                    </Col>
                    <Col xs={2} md={1} className='warm-introductions-rectangle' style={{backgroundColor: '#F0E0E0'}}>
                        <h4>04</h4>
                    </Col>
                    <Col xs={10} md={5}>
                        <img src={fourthImage} className="warm-introductions-image" alt="fourthImage" />
                    </Col>
                </Row>
                <Row style={{ marginTop: '35px' }} className='align-items-center'>
                    <Col xs={10} md={5} className='warm-introductions-image-wrapper'>
                        <img src={fifthImage} className="warm-introductions-image" alt="fifthImage" />
                    </Col>
                    <Col xs={2} md={1} className='warm-introductions-rectangle' style={{backgroundColor: '#D5DEFF'}}>
                        <h4>05</h4>
                    </Col>
                    <Col xs={12} md={6}>
                        <p>
                            Sustained Growth: Warm introductions form the bedrock of sustainable growth. 
                            They foster lasting relationships that can evolve and adapt to changing circumstances, 
                            ensuring your network remains a source of support and opportunity throughout your career.
                        </p>
                    </Col>
                </Row>
                <Row style={{ marginTop: '35px' }}>
                    <p>
                        Introductable is not just about making connections; we are about building enduring, trust-based relationships that 
                        can transform your personal and professional life. Introductable provide a shortcut to success, helping you cut through 
                        the noise and access opportunities that might otherwise be out of reach.
                    </p>
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
}

export default PowerOfWarmIntroductionsPage;
