import React from 'react';
import './TopTenReasonsPage.css';
import '../../../Main.css';
import { Container, Row } from 'react-bootstrap';
import FooterAddition from '../../footer/FooterAddition';
import blogImage from '../../../content/blog/toptenreasons/top_ten_reasons.png';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';


const TopTenReasonsPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
        <Container fluid>
            <Row className='top-ten-reasons-wrapper'>
                <Row style={{marginTop: '30px'}}>
                    <h2 className='top-ten-reasons-mobile-text' >Top Ten Reasons <span className="main-color">Introductable Speeds Up</span> The Sales Process</h2>
                </Row>
                <Row style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={blogImage} className="top-ten-reasons-image" alt="topTenReasonsImage" />
                </Row>
                <Row style={{marginTop: '40px'}}>
                    <p>
                        DYK?
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        Introductable decreases time to close by 40% over traditional marketing channels.
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <b>10.</b> "Instant warm intros mean no more cold calling. Start with trust instead of breaking the ice."
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <b>9.</b> "Pre-vetted connections save time on research. You’re talking to the right people from the start."
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <b>8.</b> "It streamlines follow-ups by providing direct access to decision-makers."
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <b>7.</b> "No more wasting time with dead-end leads—Introductable brings your high-value contacts straight to you."
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <b>6.</b> "Automated introductions cut out the back-and-forth scheduling headaches."
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <b>5.</b> "Referrals from trusted networks speed up the rapport-building process."
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <b>4.</b> "Eliminates gatekeepers."
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <b>3.</b> "Faster first meetings = faster deals. You’re already halfway through the door!"
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <b>2.</b> "Sales cycle shrinks when you’re introduced by someone your prospects already trust."
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <b>1.</b> "Less time prospecting means more time closing deals and hitting your target.”
                    </p>
                </Row>
                <Row className='justify-items-start' style={{ marginTop: '35px', marginBottom: '25px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
}

export default TopTenReasonsPage;
