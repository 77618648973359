import React from 'react';
import { Container, Row } from 'react-bootstrap';
import '../../Main.css';
import './NotFoundPage.css';



const NotFoundPage: React.FC = () => {
  return (
    <Container className='not-found-wrapper'>
      <Row className='not-found-title'>
        <h1 style={{fontWeight: '600'}}>Sometimes even we can't make a connection happen.</h1>
      </Row>
      <Row className='not-found-text' style={{marginTop: '20px'}}>
        <h3>Take me back to Homepage City, where the grass is green and connections are pretty... awesome.</h3>
      </Row>
    </Container>
  );
};

export default NotFoundPage;
