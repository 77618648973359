import { Col, Container, Row } from "react-bootstrap";
import { useAuthContext } from "../../App";
import { signInUser } from "../../api/authApi";
import { User } from "../../types/user";
import UserForm from "./LoginForm";
import { useNavigate } from "react-router-dom";
import GoogleComponent from "./google/GoogleComponent";
import './LoginComponent.css';
import '../../Main.css';

import loginPicture from '../../content/login/login_scaled.jpeg';
import logo from '../../content/logo/logo_opacity.png';
import HorizontalLine from "../atoms/lines/HorizontalLine";
import StyledLabel from "../atoms/label/StyledLabel";
import StyledLink from "../atoms/link/StyledLink";
import { useState } from "react";

const LoginComponent: React.FC = () => {
    const navigate = useNavigate();
    const { setUser, setToken } = useAuthContext();
    const [isErrorLogin, setIsErrorLogin] = useState<Boolean>(false);

    const handleLoginUser = async (user: User) => {
        await signInUser(user)
            .then(response => {
                if (response.status === 200) {
                    if (response.data.token && response.data.email && response.data.personId) {
                        setToken(response.data.token);
                        setUser({ email: response.data.email, personId: response.data.personId, timezone: response.data.timezone, 
                                    personName: response.data.personName, personLastName: response.data.personLastName });
                    } else {
                        navigate('/signin');
                    }

                    if (response.data.personName && response.data.personName !== '' && response.data.personName !== null) {
                        navigate('/dashboard');    
                    } else {
                        navigate('/profile/basic');    
                    }    
                }
            }).catch(() => {
                setIsErrorLogin(true);
            });
    };

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={4} style={{ padding: 0 }}>
                        <img src={loginPicture} className='login-image' alt="Login" />
                    </Col>
                    <Col md={8} style={{ paddingLeft: "10%", paddingRight: "10%", paddingTop: "10%" }}>
                        <Row>
                            <img src={logo} style={{ width: "50%", height: "100%", objectFit: "cover" }} alt="Login" />
                        </Row>
                        <Row style={{paddingTop: "34px"}}>
                            <Row>
                                <h4>Login to <span className="main-color">Account</span></h4>
                            </Row>
                            <Row style={{paddingTop: "10px"}}>
                                <p>You are one click away from finding your meeting.</p>
                            </Row>
                        </Row>
                        <Row  style={{paddingTop: "20px"}}>
                            <UserForm
                                onSubmit={handleLoginUser}
                                isNewUser={false}
                            />
                            {isErrorLogin ? <p style={{ color: 'red', paddingTop: '10px' }}>Email and password don't match</p> : <></>}
                        </Row>
                        <Row className="mt-2 align-items-center justify-content-center" style={{ paddingTop: "10px" }}>
                            <Col xs="auto" className="pe-0"><StyledLabel label="Don’t have an account?" /></Col>
                            <Col xs="auto" className="ps-2"><StyledLink href="/signup" bold={true}>Register</StyledLink></Col>
                        </Row>
                        <Row style={{ paddingTop: "18px" }}>
                            <HorizontalLine label={'or'}/>      
                        </Row>
                        <Row style={{ paddingTop: "18px", paddingLeft: "15%", paddingRight: "15%", paddingBottom: "20px" }}>
                            <GoogleComponent />
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
};


export default LoginComponent;
