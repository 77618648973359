import React from 'react';
import './AIIntroductablePage.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';
import peopleTalk from '../../../content/blog/people-talking_scaled.jpeg'
import FooterAddition from '../../footer/FooterAddition';


const AIIntroductablePage: React.FC = () => {
    return (
        <>
        <Container fluid>
            <Row>
                <img src={peopleTalk} className="ai-introductable-title-image" alt="peopleTalk" />
            </Row>
            <Row style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px", paddingBottom: "40px"}}>
                <Row className='ai-introductable-text' style={{ marginTop: '20px' }}>
                    <h5 className="main-color">Artificial Intelligence</h5>
                </Row>
                <Row className='ai-introductable-text'>
                    <Col><h1>AI at <span className="main-color">Introductable</span></h1></Col>
                </Row>

                <Row className='ai-introductable-reasons' style={{ marginTop: '20px' }}>
                    <Col xs={12} md={6} className='ai-introductable-green-wrapper' style={{ marginTop: '20px' }}>
                        <Row><h1>01</h1></Row>
                        <Row><h4>To identify potential connections by reviewing multiple data sources</h4></Row>
                    </Col>
                    <Col xs={12} md={6} className='ai-introductable-yellow-wrapper' style={{ marginTop: '20px' }}>
                        <Row><h1>02</h1></Row>
                        <Row><h4>Analyze the depth and strength of those connections</h4></Row>
                    </Col>
                </Row>
                <Row className='ai-introductable-text' style={{ marginTop: '35px' }}>
                    <p>
                        Introductable leverages artificial intelligence (AI) technology to revolutionize the way professionals connect and network. 
                        At its core, AI powers the platform's sophisticated matching algorithms, which analyze vast amounts of data to identify and 
                        suggest meaningful connections between users. By employing AI, Introductable can quickly and accurately assess factors such as 
                        professional backgrounds, skills, interests, and preferences, enabling more precise and tailored matchmaking. By scrutinizing these elements, 
                        we can discern the strength and depth of relationships, providing valuable insights into the dynamics between individuals.
                        <br/><br/>
                        AI also plays a crucial role in optimizing the user experience on Introductable. 
                        Through machine learning algorithms, the platform continuously learns from user interactions and feedback, 
                        refining its recommendations over time. This iterative process ensures that the matches generated by Introductable 
                        become increasingly relevant and valuable to its users, fostering deeper and more fruitful connections within the professional community.
                    </p>
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
}

export default AIIntroductablePage;
