import React from 'react';
import './LeadGenerationPage.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';
import leadGenerationImage from '../../../content/blog/leadgeneration/meeting.png';
import FooterAddition from '../../footer/FooterAddition';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';


const LeadGenerationPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
        <Container fluid>
            <Row className='lead-generation-title-wrapper'>
                <h5 className="main-color">Lead Generation</h5>
                <h1 className='lead-generation-mobile-text' >Introductable is great for companies with <span className="main-color">ongoing demand generation needs!</span></h1>
            </Row>
            <Row className='lead-generation-wrapper'>
               <Row style={{marginTop: '20px'}}>
                    <h2>Redefining <span className="main-color">Lead Generation</span></h2>
               </Row>
               <Row style={{marginTop: '20px'}}>
                    <p>Introductable offers tremendous advantages for companies with ongoing lead and demand generation needs, including:</p>
               </Row>
               <Row style={{marginTop: '20px'}} className='lead-generation-reasons'>
                    <Col xs={12} md="auto" className='lead-generation-rectangle' style={{backgroundColor: '#E7F8F2'}}>
                        <h5>Vast Network</h5>
                        <p>Access to a vast network of qualified leads and potential clients.</p>
                    </Col>
                    <Col xs={12} md="auto" className='lead-generation-rectangle' style={{backgroundColor: '#DBDBDB'}}>
                        <h5>Enhanced Credibility</h5>
                        <p>Enhanced credibility and trust in the lead generation process.</p>
                    </Col>
                    <Col xs={12} md="auto" className='lead-generation-rectangle' style={{backgroundColor: '#FFF8E0'}}>
                        <h5>Streamlined lead generation</h5>
                        <p>Streamlined lead generation and nurturing, leading to more efficient resource allocation and revenue growth.</p>
                    </Col>
               </Row>
               <Row style={{marginTop: '30px'}} className='lead-generation-mobile-direction'>
                    <Col xs={12} md={6} style={{textAlign: 'justify'}}>
                        <p>
                            Leveraging a trusted third party through Introducable provides access to a vast network of qualified leads and potential clients.
                            <br/><br/>
                            Using trusted third parties, Introducable acts as a bridge, connecting you to your self-identified high-value targeted companies exponentially increasing the chances of converting your targets into loyal customers.
                            There is nothing that provides more instant credibility and trust than through the positive introduction of a trusted third party. In an era where skepticism towards unsolicited marketing is prevalent, having a reputable intermediary vouch for you significantly enhances your company's reputation. Potential clients are more than 5x as likely to engage with businesses recommended by a trusted source, leading to higher conversion rates, shorter sales cycles, higher AOV and stronger customer relationships.
                            <br/><br/>
                            Introducable's services streamline the lead generation and nurturing process, saving companies valuable time and resources. Instead of investing significant efforts in cold outreach and prospecting, businesses can focus on companies that meet your Ideal Customer profile. This is a much more efficient allocation of resources and builds meaningful connections and closing deals, ultimately driving revenue growth.
                            <br/><br/>
                            By tapping into Introductable, companies can efficiently and effectively expand their reach and target new audiences. And remember, you only pay for the meetings you hold!
                        </p>
                    </Col>
                    <Col xs={12} md={6} style={{textAlign: 'center'}}>
                        <img src={leadGenerationImage} className="lead-generation-image" alt="leadGenerationImage" />
                    </Col>
               </Row>
               <Row className='justify-items-start' style={{ marginTop: '35px', marginBottom: '25px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>

    );
}

export default LeadGenerationPage;
