import React from 'react';
import './MeetingCounter.css';
import { MeetingCounterProps } from '../../molecules/types';
import { Col, Container, Row } from 'react-bootstrap';
import calendarIcon from '../../../content/dashboard/calendar.svg';
import StyledLabel from '../../atoms/label/StyledLabel';


const MeetingCounter: React.FC<MeetingCounterProps> = ({ meetingsNumber, title }) => {

    const formattedMeetingNumber = meetingsNumber.toLocaleString();

    return (
        <Container className='meeting-counter'>
            <Row style={{paddingLeft: "20px", paddingTop: "20px"}}>

                <Col md={1}>
                    <img src={calendarIcon} style={{ width: "24px", height: "auto", marginRight: '20px' }} alt="Success Icons" />
                </Col>
                <Col md={3} className='meeting-counter-number'>
                    {formattedMeetingNumber}
                </Col>
                <Col md={4}/>
            </Row>
            <Row style={{ paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px"}}>
                <StyledLabel label={title} />
            </Row>
        </Container>
    );
    }

export default MeetingCounter;
