import { Col, Container, Row } from "react-bootstrap";
import PricingComponent from "../organisms/pricing/PricingComponent";
import { BsBuildings } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import './PricingPage.css';

const PricingEnterprise: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <Container fluid>
                <Row className="centred-element" style={{ marginTop: "50px"}}>
                    <h2><span className="main-color">Enterprise</span></h2>
                </Row>
                <Row className="justify-content-space-between" style={{ marginBottom: "20px"}} >
                    <Col className="pricing-single-component"> 
                    <PricingComponent 
                            icon={<BsBuildings className="color-element"/>} 
                            title={"Enterprise"} 
                            pricePerMonth={"$1499.00"} 
                            benefitList={[
                                'Premium features for enterprise and high-volume users.',
                                'Unlimited access to Introductable\'s matchmaking platform with priority matching.',
                                'Top priority for AI and human assistance for unavailable meeting targets.',
                                'Priority access to highest-rated Introductors.',
                                'Personalized assistance and onboarding.',
                                'Meeting priority over Professional and Essential plans for high-demand targets.',
                                'Full access to all meeting targets.',
                                'Lowest meeting costs: $900/meeting',
                            ]} 
                            linkHref='/pricing/enterprise'
                            onClickButton={() => navigate(`/pricing/enterprise`)}
                            buttonLabel='Purchase Now'
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default PricingEnterprise;