import { Container, Row } from "react-bootstrap";
import { useDropzone } from 'react-dropzone';
import './UploadFileComponent.css';
import { useCallback, useState } from "react";


interface UploadFileComponentProps {
    onFileSelected: (file: File) => void;
  }

const UploadFileComponent: React.FC<UploadFileComponentProps> = ({ onFileSelected }) => {
    const [fileName, setFileName] = useState<string | null>(null);

    const onDrop = useCallback((acceptedFiles: any) => {
        const file = acceptedFiles[0];
        onFileSelected(file);
        setFileName(file.name);
      }, [onFileSelected]);
    
      const { getRootProps, getInputProps } = useDropzone({ onDrop });

    return (
      <Container fluid>
        <Row className='upload-file-wrapper'>
            <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
            </div>
        </Row>
        {fileName && (
          <Row style={{paddingTop: '20px'}} className='file-name'>
            <p>Uploaded file: {fileName}</p>
          </Row>
        )}
    </Container>
    );
  };
  
  export default UploadFileComponent;
  