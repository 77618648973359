import axios, { AxiosResponse } from 'axios';
import { LightSearchResponse, SearchResponse } from '../types/search';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/v1/search`;

export async function getPersonSearch(searchWords: String[]): Promise<AxiosResponse<SearchResponse>> {
    return await axios.post(`${API_BASE_URL}`, searchWords);
}

export async function getPersonLightSearch(searchWords: String[]): Promise<AxiosResponse<LightSearchResponse>> {
    return await axios.post(`${API_BASE_URL}/light`, searchWords);
}