import React, { useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import resumePicture from '../../content/account/profile_resume.jpeg';
import stepperFour from '../../content/account/stepper_4.svg';
import logo from '../../content/logo/logo_opacity.png';

import './NewUser.css';
import '../../Main.css';
import { useAuthContext } from '../../App';
import StyledActionButton from '../atoms/button/StyledActionButton';
import UploadFileComponent from '../molecules/uploadfile/UploadFileComponent';
import { uploadResume } from '../../api/uploadFileApi';

const UploadUserResume: React.FC = () => {
    const navigate = useNavigate();
    const { user } = useAuthContext();
  
  
    const [file, setFile] = useState<File | null>(null);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (file && user.personId) {
            const formData = new FormData();
            formData.append('file', file);
            uploadResume(formData, user.personId)
            .then( () => {
                navigate('/profile/start');
            }).catch( error => {
                console.error('Error uploading file', error);
            })
        } else {
            navigate('/profile/start');
        }
    };
  
    return (
      <>
            <Container fluid>
                <Row>
                    <Col md={4} style={{ padding: 0 }}>
                        <img src={resumePicture} className='profile-image' alt="resumePicture" />
                    </Col>
                    <Col md={8} style={{ paddingLeft: "10%", paddingRight: "10%", paddingTop: "10%" }}>
                        <Row>
                            <img src={logo} style={{ width: "50%", height: "100%", objectFit: "cover" }} alt="Logo" />
                        </Row>
                        <Row>
                            <img src={stepperFour} style={{ width: "100%", height: "100%", objectFit: "cover", paddingTop: "20px" }} alt="stepper" />
                        </Row>
                        <Row style={{paddingTop: "34px"}}>
                            <Row>
                                <h4>We need just a bit of info before we <span className="main-color">Get Started</span></h4>
                            </Row>
                            <Row style={{paddingTop: "10px"}}>
                                <p>You’re just a few steps away from finding your meeting.</p>
                            </Row>
                        </Row>
                        <Row>
                            <Form onSubmit={handleSubmit}>
                                <Row>
                                    <UploadFileComponent onFileSelected={setFile} />
                                </Row>
                                <Row style={{paddingTop: "16px", paddingLeft: "12px", paddingRight: "12px", paddingBottom: "180px"}}>
                                    <StyledActionButton label={'Submit'} type="submit" />
                                </Row>
                        </Form>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default UploadUserResume;