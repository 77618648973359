import React from 'react';
import './OurValues.css';
import { Col, Container, Row } from 'react-bootstrap';
import { HiOutlineLightBulb } from "react-icons/hi";
import { FaHandshake } from "react-icons/fa6";
import { GoPeople } from "react-icons/go";

const OurValues: React.FC = () => {
    return (
        <Container className='our-values-wrapper'>
            <Row>
                <Col xs={12} md={4} className="value-col">
                    <div className="value-circle value-circle-yellow">
                        <GoPeople />
                    </div>
                    <div className="values-rectangle values-green-rectangle">
                        <h4>Humanization</h4>
                    </div>
                </Col>
                <Col xs={12} md={4} className="value-col">
                    <div className="value-circle value-circle-salmon">
                        <FaHandshake />
                    </div>
                    <div className="values-rectangle values-grey-rectangle">
                        <h4>Trust</h4>
                    </div>
                </Col>
                <Col xs={12} md={4} className="value-col">
                    <div className="value-circle value-circle-green">
                        <HiOutlineLightBulb />
                    </div>
                    <div className="values-rectangle values-yellow-rectangle">
                        <h4>Innovation</h4>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default OurValues;
