import React from 'react';
import './ImageCollage.css';
import { Col, Container, Row } from 'react-bootstrap';
import { ImageCollageProps } from '../types';


const ImageCollage: React.FC<ImageCollageProps> = ({firstImage, secondImage}) => {

    return (
        <Container className='image-collage-wrapper'>
            <Row>
                <Col xs={12} md={6} className='image-collage-column-wrapper'>
                    <Row>
                        <img src={firstImage} className="first-collage-image" alt="aboutUsFirstImg" />
                    </Row>
                    <Row>
                        <div className="image-collage-yellow-rectangle"></div>
                    </Row>
                </Col>
                <Col xs={12} md={6} className='image-collage-column-wrapper'>
                    <Row>
                        <div className="image-collage-green-rectangle"></div>
                    </Row>
                    <Row>
                        <img src={secondImage} className="second-collage-image" alt="aboutUsSecondImg" />
                    </Row>
                </Col>
            </Row>
        </Container>
    );
    }

export default ImageCollage;
