import React from 'react';
import './NotALeadPage.css';
import '../../../Main.css';
import { Container, Row } from 'react-bootstrap';
import FooterAddition from '../../footer/FooterAddition';
import blogImage from '../../../content/blog/notalead/notaleadimage.png';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';
import { GoDot, GoDotFill } from 'react-icons/go';


const NotALeadPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
        <Container fluid>
            <Row className='not-a-lead-wrapper'>
                <Row style={{marginTop: '30px'}}>
                    <h2 className='not-a-lead-mobile-text' >THAT'S <span className="main-color">NOT A LEAD!!!</span></h2>
                </Row>
                <Row style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={blogImage} className="not-a-lead-image" alt="notALeadImage" />
                </Row>
                <Row style={{marginTop: '40px'}}>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        Or, Buying A Scraped List is just Buying An Expensive Phone Book.
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        You don’t understand what a lead is.
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        Yep, it’s absolutely true. OK, it’s <b>probably</b> true. You might be an exception. You are obviously smart and discerning by virtue of reading this after all. 
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        I am active in a number of marketing groups, sub reddits, substacks, etc and without qualification I can trust that on a daily basis, someone will try and pitch ‘leads’. 
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        Grr. Grr.
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        What they are inevitably pitching are scraped Linkedin or Apollo or some other db spreadsheet of name and contact info. That is just an expensive phone book.
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        Fine. You do need those, although I would ask why you aren’t getting them yourself at a lower cost by subscribing yourself, but…whatever. 
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        What they absolutely are <b>not</b> is a <b>LEAD</b>. Nope. That’s just a spreadsheet of random names, companies and job titles. If you think that is a lead, you need some help, so do me a favor and let me vent while you gain some knowledge.
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        Let’s talk terms…or what words actually mean.
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        {`Pool > Lead > Qualified Lead > Opportunity`}
                    </p>

                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        TL:DNR
                        <br/>
                        <br/>
                        <b>Pool:</b> Everyone in the universe who might be a fit.
                        <br/>
                        <b>Lead:</b> Expressed some interest, but may not even have what you do right.
                        <br/>
                        <b>Qualified Lead:</b> They have it right and want to chat.
                        <br/>
                        <b>Opportunity:</b> You have connected and they need what you have and have the $ and authority to make it happen. 
                        <br/>
                    </p>

                    <p style={{paddingLeft: '20px', paddingTop: '30px'}}>
                        For those masochists among you who like the long form, here is my attempt at providing you with a lexicon you can use internally so everyone in your organization understands what is and isn’t a lead. 
                    </p>
                    <h4 style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        <b>Pool</b>
                    </h4>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        The <b>Pool</b> represents the broadest group of potential customers within your Total Addressable Market (TAM). This group includes anyone who might have an interest in your product or service, but they haven't yet been engaged through any specific sales or marketing efforts. These individuals typically fit a general profile relevant to your offerings, such as industry, job title, or company size, but have not shown explicit interest in your product.
                    </p>
                    <p style={{paddingLeft: '30px'}}>
                        <GoDotFill className='main-color'/> <b>Key Characteristics:</b>
                    </p>
                    <p style={{paddingLeft: '50px'}}>
                        <GoDot className='main-color'/> They are within your TAM but not yet identified as leads.
                    </p>
                    <p style={{paddingLeft: '50px'}}>
                        <GoDot className='main-color'/> They fit a broad customer profile, such as being in a relevant industry or having a job title that aligns with your product’s use case.
                    </p>
                    
                    <h4 style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        <b>Lead</b>
                    </h4>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        A <b>Lead</b> is an individual or entity within the Pool who has taken some form of action that indicates interest in your product or company. This interest might be expressed through visiting your website, downloading content, signing up for a newsletter, or responding to a promotional campaign. At this stage, leads are at the top of the sales funnel, and while they’ve shown initial interest, they still need to be qualified to determine if they’re a good fit for your product or service.
                    </p>
                    <p style={{paddingLeft: '30px'}}>
                        <GoDotFill className='main-color'/> <b>Key Characteristics:</b>
                    </p>
                    <p style={{paddingLeft: '50px'}}>
                        <GoDot className='main-color'/> They have interacted with your brand or content in a meaningful way, signalling potential interest.
                    </p>
                    <p style={{paddingLeft: '50px'}}>
                        <GoDot className='main-color'/> Leads can be generated through inbound marketing (e.g., content marketing, SEO) or outbound efforts (e.g., email campaigns, advertising).
                    </p>
                    <p style={{paddingLeft: '50px'}}>
                        <GoDot className='main-color'/> They are early in the buyer's journey and require further nurturing to move them down the funnel.
                    </p>
                    <p style={{paddingLeft: '50px'}}>
                        <GoDot className='main-color'/> Examples include someone who has downloaded a whitepaper from your website or someone who filled out a form to request more information.
                    </p>
                    
                    <h4 style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        <b>Qualified Lead</b>
                    </h4>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        A <b>Qualified Lead</b> is a lead that has been vetted and meets specific criteria that indicate a higher likelihood of becoming a customer. This qualification process typically involves determining if the lead has a need for your product, a budget to purchase it, and the authority to make or influence a buying decision. Qualification can be based on factors such as their level of engagement, the problems they’re looking to solve, and their alignment with your ideal customer profile (ICP).
                    </p>
                    <p style={{paddingLeft: '30px'}}>
                        <GoDotFill className='main-color'/> <b>Key Characteristics:</b>
                    </p>
                    <p style={{paddingLeft: '50px'}}>
                        <GoDot className='main-color'/> They have expressed a clear interest in your product or service and understand its relevance to their needs.
                    </p>
                    <p style={{paddingLeft: '50px'}}>
                        <GoDot className='main-color'/> They have a budget allocated or the financial capacity to purchase your offering.
                    </p>
                    <p style={{paddingLeft: '50px'}}>
                        <GoDot className='main-color'/> They have a timeframe in mind for making a purchase, often within a defined near-term period that aligns with your sales cycle.
                    </p>
                    <p style={{paddingLeft: '50px'}}>
                        <GoDot className='main-color'/> Qualification criteria can include factors such as their role in the purchasing process, the urgency of their need, and their fit within your target market.
                    </p>
                    <p style={{paddingLeft: '50px'}}>
                        <GoDot className='main-color'/> Examples include a decision-maker who has requested a demo or a company that has expressed an immediate need for your solution.
                    </p>
                    
                    <h4 style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        <b>Opportunity</b>
                    </h4>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        An <b>Opportunity</b> is a qualified lead that has been further vetted and has agreed that there is potential for a business relationship. At this stage, the lead has moved deeper into the sales funnel, and the sales team begins more focused discussions to address the prospect's needs, present solutions, and negotiate terms. The opportunity stage is critical, as it represents a prospect with a high likelihood of converting into a paying customer.
                    </p>
                    <p style={{paddingLeft: '30px'}}>
                        <GoDotFill className='main-color'/> <b>Key Characteristics:</b>
                    </p>
                    <p style={{paddingLeft: '50px'}}>
                        <GoDot className='main-color'/> The lead has shown a strong interest in moving forward with discussions and exploring a potential purchase.
                    </p>
                    <p style={{paddingLeft: '50px'}}>
                        <GoDot className='main-color'/> The sales team has assessed that the lead’s needs align with the product or service offered, and there is a clear fit.
                    </p>
                    <p style={{paddingLeft: '50px'}}>
                        <GoDot className='main-color'/> Opportunities are tracked in a CRM system with detailed information about the prospect, the potential deal size, expected close date, and other key metrics.
                    </p>
                    <p style={{paddingLeft: '50px'}}>
                        <GoDot className='main-color'/> The sales process at this stage often involves proposals, negotiations, and addressing any remaining concerns the prospect may have.
                    </p>
                    <p style={{paddingLeft: '50px'}}>
                        <GoDot className='main-color'/> Examples include a lead who has participated in a product demo and is now discussing pricing or contract terms with your sales team.
                    </p>

                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        <h4>
                            <b>Summary</b>
                        </h4>
                        <br/>
                            <GoDotFill className='main-color'/> <b>Pool:</b> Broad potential customer base within your TAM, not yet engaged.
                        <br/>
                            <GoDotFill className='main-color'/> <b>Lead:</b> An individual who has shown initial interest through interaction with your brand or content.
                        <br/>
                            <GoDotFill className='main-color'/> <b>Qualified Lead:</b> A lead that has been vetted and meets criteria indicating a strong potential to become a customer.
                        <br/>
                            <GoDotFill className='main-color'/> <b>Opportunity:</b> A qualified lead that has agreed to further discussions, moving deeper into the sales process with the intent to purchase.
                        <br/>
                        <br/>
                        There are plenty of ways to further segment these down but this is pretty damn long already.
                        <br/>
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        Thanks for letting me vent.
                    </p>
                </Row>
                <Row className='justify-items-start' style={{ marginTop: '35px', marginBottom: '25px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
}

export default NotALeadPage;
