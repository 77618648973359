import React from 'react';
import './WhyUsComponent.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';


const WhyUsComponent: React.FC = () => {
    return (
        <Container className='why-us-styled-text'>
            <Row style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px"}}>
            <Row>
                <h5 className="main-color why-us-text-margin" style={{fontFamily: 'Raleway'}}>Why Choose Us</h5>
            </Row>
            <Row>
                <Col><h2 className='why-us-text-margin'>Great for companies ongoing <span className="main-color">need for lead generation</span></h2></Col>
            </Row>
            <Row className='why-us-text-margin' style={{ marginTop: '20px' }}>
                <p>
                    Introductable offers a singular service that facilitates genuine and meaningful business introductions via a 
                    trusted third person to make a warm introduction to your selected meeting target. 
                    The novelty of our approach is in its return to how business and networking used to be done but with the added benefit of scalability through technology.
                </p>
            </Row>
            </Row>
        </Container>
    );
    }

export default WhyUsComponent;
