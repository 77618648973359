import React from 'react';
import './RatingHaloweenCandyPage.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';
import FooterAddition from '../../footer/FooterAddition';
import { GoDotFill } from 'react-icons/go';

import blogImage from '../../../content/blog/ratinghaloweencandy/RatingHaloweenCandyImage.png';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';

const RatingHaloweenCandyPage: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
        <Container fluid className='justify-content-center'>

            <Row style={{paddingLeft: "20px", paddingTop: "50px", paddingRight: "20px", paddingBottom: "40px"}} className='justify-content-center'>
                <Row>
                    <Col className='rating-haloween-candy-title'><h2>Rating Halloween Candy: <span className="main-color">Do you want to be the cool house</span> or the house that gets egged?</h2></Col>
                </Row>
                <Row style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={blogImage} className="rating-haloween-candy-title-image" alt="ratingHaloweenCandyImage" />
                </Row>
                <Row>
                    <h5>Did you know that vinegar candy was a thing? Yeah. It was actually pretty popular 100 years ago or so.</h5>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                        Fortunately, we now have many more tasty ways to get kids (and adults) a massive sugar rush.  Thanks the gods for corn syrup.
                    </p>
                    <p>
                        And yeah, I know Halloween was yesterday. This article isn’t a day late, it’s a year early. 
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>1. A Tier: Top-Tier Treats</b></h5>
                    <p>These are the candies you eat first and kids hide from their parents.</p>
                    <p style={{paddingLeft: '20px'}}>
                        <GoDotFill className='main-color'/> <b>Reese’s Peanut Butter Cups:</b> It’s hard to beat this chocolate and peanut butter combo. Smooth, creamy, and perfectly balanced, Reese’s Cups are the holy grail of Halloween candy. 
                        <br/>
                        <GoDotFill className='main-color'/> <b>Snickers:</b> Snickers bring the perfect mix of nougat, caramel, and peanuts, with just the right amount of sweetness and crunch. 
                        <br/>
                        <GoDotFill className='main-color'/> <b>Twix:</b> With its cookie crunch, caramel layer, and milk chocolate coating, Twix appeals to everyone who loves a mix of textures. The dual-bar format also makes it feel like you’re getting double the fun.
                        <br/>
                        <GoDotFill className='main-color'/> <b>Kit Kat:</b> Kit Kat’s iconic wafer crunch and creamy chocolate coating make it a favorite for those who love a simple, satisfying candy. Plus, the option to “break off a piece” is part of the fun.
                        <br/>
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>2. B Tier : Solid Choices</b></h5>
                    <p>These candies may not generate the same level of excitement as the S tiers, but they’re solid choices that never go to wast.</p>
                    <p style={{paddingLeft: '20px'}}>
                        <GoDotFill className='main-color'/> <b>M&M’s</b> Whether plain, peanut, or crispy, M&M’s are classic and easy to enjoy in small portions. Their versatility and variety make them a safe bet, but they might not stand out as much as some of the bigger candy bars.
                        <br/>
                        <GoDotFill className='main-color'/> <b>Milky Way:</b> With a soft caramel and nougat center wrapped in milk chocolate, Milky Way is a close cousin to Snickers, but without the added crunch of peanuts. It’s creamy and delicious but doesn’t have quite the same level of popularity.
                        <br/>
                        <GoDotFill className='main-color'/> <b>Butterfinger:</b> Falling out of A Tier after a recipe redo, they are in danger of falling even lower after the nostalgia wears off.
                        <br/>
                        <GoDotFill className='main-color'/> <b>Starburst:</b> For those who love fruity candies, Starburst is a hit. Each piece is chewy, tangy, and packed with flavor.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>3. C Tier: The Crap Tier</b></h5>
                    <p>If you want your house egged just give these out!</p>
                    <p style={{paddingLeft: '20px'}}>
                        <GoDotFill className='main-color'/> <b>Candy Corn:</b> No flavor and sticks to your teeth? Did you run out cough drops to hand out?
                        <br/>
                        <GoDotFill className='main-color'/> <b>Carmellos:</b> Don’t try and tell me the cream is…I truly do not want to know.
                        <br/>
                        <GoDotFill className='main-color'/> <b>Tootsie Rolls:</b> When nostalgia finally fails this is what happens. 
                        <br/>
                        <GoDotFill className='main-color'/> <b>Necco Wafers:</b> Are they asbestos powdered with dandruff? I’m not sure but is shaved, compressed dry wall the best you can offer? I’d honestly take a nickel over these.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>4. Stealth Winners: Underdog Heroes</b></h5>
                    <p>These treats are the rare jewels in your treat bucket. You won’t get many of them but that’s ok. These are the palate cleansers, the cucumber sorbet in your 7 course candy snacking. The most common reaction when seeing one is, “ Oh! CANDY NAME…nice!”.</p>
                    <p style={{paddingLeft: '20px'}}>
                        <GoDotFill className='main-color'/> <b>Almond Joy/Mounds:</b> Coconut-filled candies are divisive, but those who love them really love them. Almond Joy’s combination of coconut, almonds, and chocolate provides a unique flavor profile, while Mounds delivers a smooth, dark chocolate and coconut experience.
                        <br/>
                        <GoDotFill className='main-color'/> <b>100 Grand:</b> This candy bar doesn’t get as much hype as others, but its mix of crispy rice and caramel wrapped in milk chocolate makes it a hidden gem that’s always a pleasant surprise in the trick-or-treat bag.
                        <br/>
                        <GoDotFill className='main-color'/> <b>Junior Mints:</b> Mint and chocolate lovers are thrilled when they come across Junior Mints. Their refreshing flavor and creamy texture make them a unique addition to the Halloween candy mix.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>This certainly wasn’t meant to be the definitive guide, so which of your fave candies did I leave out?</p>
                </Row>
                
                <Row className='justify-items-start' style={{ marginTop: '35px', marginBottom: '25px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
    }

export default RatingHaloweenCandyPage;
