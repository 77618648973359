import React from 'react';
import FAQComponent from './FAQComponent';
import FooterAddition from '../footer/FooterAddition';

const FAQPage: React.FC = () => (
    <>
        <FAQComponent />
        <FooterAddition />
    </>
);

export default FAQPage;