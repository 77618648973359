import axios, { AxiosResponse } from "axios";
import { RequestorToExpertLookForIntroductorListAddRequest } from "../types/notification";

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/v1/notification`;



export async function requestorToExpertLookForIntroductorListAdd(meetingRequest: RequestorToExpertLookForIntroductorListAddRequest): Promise<AxiosResponse<string>> {
    return await axios.post(`${API_BASE_URL}/requestor-to-expert-look-for-introductor-list/add`, meetingRequest);
  }
  