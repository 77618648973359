import React from 'react';
import './MissionComponent.css';
import { Col, Container, Row } from 'react-bootstrap';
import missionPicture from '../../../content/aboutus/aboutus_1.jpeg';


const MissionComponent: React.FC = () => {


    return (
        <Container className='mission-wrapper mission-styled-text'>
            <Row style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px"}}>
                <Col xs={12} md={6}>
                    <Row>
                        <h5 className="main-color">Mission</h5>
                    </Row>
                    <Row>
                        <Col><h2>Our <span className="main-color">Mission</span> as <span className="main-color">Introductable</span></h2></Col>
                    </Row>
                    <Row>
                        <p>
                        Our mission at Introductable is simple: to connect people who would like to have a meeting with those who can make that meeting happen 
                        through their close relationships.
                        </p>
                    </Row>
                    <Row>
                        <p>
                        We believe so strongly in our mission and our product that we live it every day. 
                        What does that mean? We take no meetings unless they come via a request on Introductable. 
                        Of course, that would be incredibly self-serving and unfair so every meeting request comes at a cost of just $1. 
                        That $1 dollar (again you pay only if you have a meeting!) is donated to a rotating cast of charities.
                        Our current charity recipient is the National Pediatric Cancer Foundation. The NPCF invests in research 
                        and funds family support services for families for children with cancer.
                        </p>
                    </Row>

                </Col>
                <Col xs={12} md={6}>
                    <img src={missionPicture} className='mission-picture' style={{ height: "auto" }} alt="missionPicture" />
                </Col>
            </Row>
        </Container>
    );
    }

export default MissionComponent;
