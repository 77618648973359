import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import testimonialsImage from '../../../content/landing-page/testimonials.svg';
import start4Image from '../../../content/rating/stars_4.svg';
import './Testimonials.css';
import '../../../Main.css';

const Testimonials: React.FC = () => {

  return (
    <Container className='testimonials-wrapper'>
      <Row>
        <Col xs={12} md={6}> 
          <Row>
            <p className="main-color">Testimonials</p>
          </Row>
          <Row>
            <h4>Hear What Our Users <span className='main-color'>Have to Say</span></h4>
          </Row>
          <Row>
            <h5>Cost-Effective Lead Gen</h5>
          </Row>
          <Row>
            <p>“Introductable has been far and away our most cost-effective lead generation channel.”</p>
          </Row>
          <Row>
            <img src={start4Image} className='testimonials-rating-image' alt="start4Image" />
          </Row>
          <Row style={{marginTop: '20px'}}>
            <h5>Mary T.</h5>
          </Row>
          <Row>
            <p>Director of Lead Generation</p>
          </Row>
        </Col>
        <Col xs={12} md={6}>
          <img src={testimonialsImage} className='testimonials-image' alt="testimonialsImage" />
        </Col>
      </Row>
    </Container>
  );
}

export default Testimonials;
