import { Col, Container, Form, Row } from "react-bootstrap";
import { FormEvent, useState } from "react";
import StyledInput from "../atoms/input/StyledInput";
import loginPicture from '../../content/login/login_scaled.jpeg';
import StyledActionButton from "../atoms/button/StyledActionButton";
import { forgetPassword } from "../../api/authApi";

const ForgetPasswordComponent: React.FC = () => {
    const [resetPasswordSent, setResetPasswordSent] = useState<boolean>(false);
    const [resetPasswordEmail, setResetPasswordEmail] = useState<string>('');

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setResetPasswordSent(true);
        forgetPassword(resetPasswordEmail)
            .then(() => {

            });
    };

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={4} style={{ padding: 0 }}>
                        <img src={loginPicture} className='login-image' alt="Login" />
                    </Col>
                    <Col md={8} style={{ paddingLeft: "10%", paddingRight: "10%", paddingTop: "10%" }}>
                        <Row style={{paddingTop: "34px"}}>
                            <Row>
                                <h4>Need a New <span className="main-color">Password?</span></h4>
                            </Row>
                        </Row>
                        <Row style={{ paddingTop: "18px" }}>
                            {
                                resetPasswordSent ? (
                                    <p>
                                        An email to reset your password has been sent! Please check your inbox to continue.
                                    </p>
                                ) : (
                                    <Form onSubmit={handleSubmit}>
                                        <Form.Group>
                                            <StyledInput 
                                                type="text"
                                                name="resetPasswordEmail"
                                                value={resetPasswordEmail}
                                                onChange={(e) => setResetPasswordEmail(e.target.value)}
                                                required
                                                placeholder='Your email...'
                                            />
                                        </Form.Group>
                                        <Form.Group style={{ paddingTop: "18px" }}>
                                            <StyledActionButton label={'Reset Your Password'} type="submit" />
                                        </Form.Group>
                                    </Form>
                                )
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ForgetPasswordComponent;
