import React, {useState, useEffect} from 'react';
import {getPersonById} from '../../api/personApi';
import { Person } from '../../types/person';
import { UUID } from 'crypto';
import { useNavigate, useParams } from 'react-router-dom';
import { createMeeting } from '../../api/meetingApi';
import './PersonPanelComponent.css';
import '../../Main.css';
import { useAuthContext } from '../../App';
import { Col, Container, Row } from 'react-bootstrap';
import StyledTable from '../molecules/table/styledtable/StyledTable';
import StyledCheckbox from '../atoms/checkbox/StyledCheckbox';
import StyledActionButton from '../atoms/button/StyledActionButton';
import SearchBar from '../search/SearchBar';
import PersonDetailsComponent from '../molecules/persondetails/PersonDetailsComponent';
import { getAllExpertIntroductors } from '../../api/connectionApi';
import { RelationTypeEnum, getRelationTypeString } from '../../types/relation';
import { requestorToExpertLookForIntroductorListAdd } from '../../api/notificationApi';

const PersonPanelComponent: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const { expertId } = useParams();
  const [expert, setExpert] = useState<Person>({    
      personId: '00000000-0000-0000-0000-000000000000',
      name: '',
      lastName: '',
      email: '',
      occupationList: []
  });

  const [selectedIntroductorId, setSelectedIntroductorId] = useState<string>('');
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [introductorsTableData, setIntroductorsTableData] = useState<string[][]>([]);
  const [tableRowIds, setTableRowIds] = useState<string[]>([]);
  const [addedToList, setAddedToList] = useState<boolean>(false);
  const INTRODUCORS_TABLE_HEADERS = ['Name', 'Relationship', 'Location']; 
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(-1);

  const onSearch = (searchText: string) => {
    navigate(`/search/result?keywords=${searchText}`);
  }

  const handleClickIntroductor = (selectedPersonId: string, selectedRowIndex: number) => {
    setSelectedIntroductorId(selectedPersonId);
    setSelectedRowIndex(selectedRowIndex);
  }

  const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
  };

  const handleLetUserKnow = () => {
    if (user.personId && expertId){ 
      requestorToExpertLookForIntroductorListAdd({expertId: expertId as UUID, requestorId: user.personId}).then(
        () => {
          setAddedToList(true);
        }
      )  
    }
  };

  useEffect(() => {
    getPersonById(expertId as UUID)
      .then(personResponse => {
        setExpert(personResponse.data);
      });
  }, [expertId])

  useEffect(() => {
    getAllExpertIntroductors(expertId as UUID)
        .then(response => {
          const tableData: React.SetStateAction<string[][]> = [];
          const ids: React.SetStateAction<string[]>  = [];
          response.data.map((introductor, index) => {
              const introductorRow = [
                  introductor.name + ' ' + introductor.lastName.charAt(0) + '.',
                  introductor.relationship ? getRelationTypeString(introductor.relationship as RelationTypeEnum) : '-',
                  ((introductor.country ? introductor.country : '') + ' ' + (introductor.city ? introductor.city : '')) ?? '-'
                            
          ];
              tableData.push(introductorRow);
              ids.push(introductor.personId);
          })

          setIntroductorsTableData(tableData);
          setTableRowIds(ids);
      })
      .catch((error) => {
          console.error('Error:', error);
      });
  }, [expertId]);

  const handleSubmit = () => {
    if (user.personId !== undefined) {
      createMeeting({ 
        interessantId: user.personId, 
        expertId: expertId as string,
        introductorId: selectedIntroductorId,
        timeProposals: []
      }).then(
        response => {
          navigate(`/meetings/${response.data.meetingId}`);
      }).catch( error => {
        console.error(error);
      });
    }
  }

  return (
    <>
      <Container>
        <Row style={{paddingTop: "20px"}}>
            <SearchBar onSearch={onSearch} />
        </Row>
        <Row style={{ marginTop: '20px' }}>
          <PersonDetailsComponent 
            title={'Meeting Target'} 
            personName={`${expert.name} ${expert.lastName}`} 
            occupation={`${expert.occupationList && expert.occupationList.length > 0 ? expert.occupationList[0].title : ''}`} 
            company={`${expert.occupationList && expert.occupationList.length > 0 ? expert.occupationList[0].companyName : ''}`} 
          />
        </Row>
        <Row style={{ marginTop: '20px' }}>
        {
          introductorsTableData.length > 0 ? (
            <>
              <h4 style={{fontFamily: 'Raleway'}}>Success! <span className='introductors-title-greyed'>These people can set up a meeting.</span></h4>
              <p>Click on any names to set the meeing</p>
              <StyledTable 
                    tableTitle=''
                    tableHeaders={INTRODUCORS_TABLE_HEADERS} 
                    tableData={introductorsTableData} 
                    tableRowsIds={tableRowIds}
                    onClickRow={handleClickIntroductor}
                    selectedRow={selectedRowIndex}
                />
            </>
          ) : (
            <Container className='no-one-available-text'>
              <Row>
                <h5><b>Sorry!</b> No one is currently available to arrange a meeting.</h5>
              </Row>
              <Row style={{ marginTop: '20px' }}>
                <p>
                  It’s not you... It’s us. Either we don’t have anyone we trust enough to make an introduction and set a meeting, 
                  or the person you are trying to meet has recently had Introductable set a meeting with them. If you would like to us to inform you when we can set a meeting, 
                  let us know!  
                </p>
              </Row>
              {
                addedToList ? (
                  <>
                    <Row>
                      <p>
                        We have been notified about your request. We will send you an email as soon we will find Introductor.
                      </p>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className='main-color' style={{ marginTop: '20px' }}>
                      <Col style={{ display: 'flex', justifyContent: 'center', width: '100%' }}><
                        StyledCheckbox checked={isChecked} onChange={handleCheckboxChange} label={"Yes! Let me know when a meeting can be set."} />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '20px' }}>
                      <Col style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <StyledActionButton label={'Submit'} onClick={handleLetUserKnow} disabled={!isChecked}/>
                      </Col>
                    </Row>
                  </>
                )
              }

              <Row>
              </Row>
            </Container>
            )
          }
        </Row>
        {
            introductorsTableData.length > 0 && !addedToList && (
              <Row style={{ marginTop: '20px', maxWidth: '200px' }}>
                <StyledActionButton onClick={handleSubmit} label={'Schedule your meeting'} disabled={selectedIntroductorId === '' || selectedIntroductorId === '0'} />
              </Row>
            )
        }
      </Container>
      
    </>
  );
}

export default PersonPanelComponent;