import React from 'react';
import './WhenTurningDownPaymentPage.css';
import '../../../Main.css';
import { Container, Row } from 'react-bootstrap';
import blogImage from '../../../content/blog/whenturningdown/WhenTurningDownImage.png';
import FooterAddition from '../../footer/FooterAddition';
import { GoDotFill } from 'react-icons/go';


const WhenTurningDownPaymentPage: React.FC = () => {
    return (
        <>
        <Container fluid>
            <Row className='when-turning-down-wrapper'>
                <Row style={{marginTop: '30px'}}>
                    <h2 className='when-turning-down-mobile-text' >When <span className="main-color">Turning Down Payment</span> is the Right Move</h2>
                </Row>
                <Row style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={blogImage} className="when-turning-down-image" alt="olympicGoldImage" />
                </Row>
                <Row style={{marginTop: '40px'}}>
                    <p>
                        In the world of business, it's rare to hear of a company turning down payment for services rendered. However, that's exactly what happened recently at our firm. As the CEO, I made the decision to forgo billing a client for work that, while completed, would not have served their best interests.
                    </p>
                    <p>
                        The situation arose when a client submitted a custom list for introductions, expecting to pay a low five-figure sum for our services. Upon review, we realized that the names and titles on the list were misaligned with the client's actual needs. The roles simply weren't suitable for what they were looking to achieve.
                    </p>
                    <p>
                        Instead of proceeding with the introductions and accepting payment, we took a different approach. We suggested new job titles that would be more beneficial to our client's objectives, along with examples for them to review and discuss internally. This redirection required additional time and effort on our part, but we believed it was the right thing to do.
                    </p>
                    <p>
                        The decision to not bill for this work was rooted in our commitment to client success. We understand that our clients' victories are ultimately our victories. By prioritizing their long-term success over short-term gains, we build trust, loyalty, and lasting partnerships.
                    </p>
                    <p>
                        This incident exemplifies our philosophy of investing in our clients as much as, if not more than, they invest in us. Here's how we put this principle into practice:
                        <br/><br/>
                        <GoDotFill className='main-color'/> <b>1. Expertise and Guidance:</b> We don't just provide a service; we offer our expertise to guide clients toward the best solutions, even if it means recommending a different approach than initially requested.
                        <br/><br/>
                        <GoDotFill className='main-color'/> <b>2. Long-term Vision:</b> We focus on building enduring relationships rather than maximizing immediate profits. Sometimes, this means making short-term sacrifices for long-term benefits.
                        <br/><br/>
                        <GoDotFill className='main-color'/> <b>3. Customized Solutions:</b> We tailor our services to each client's unique needs, even if it requires additional unpaid work on our part.
                        <br/><br/>
                        <GoDotFill className='main-color'/> <b>4. Honesty and Transparency:</b> We're not afraid to have difficult conversations with clients when we believe a different strategy would better serve their interests.
                        <br/><br/>
                        <GoDotFill className='main-color'/> <b>5. Continuous Improvement:</b> We constantly seek ways to add value to our clients' businesses, often going beyond the scope of our contracted services.
                        <br/><br/>
                    </p>
                    <p>
                        By investing heavily in our clients' success, we create a virtuous cycle. Satisfied clients become long-term partners and ambassadors for our brand, leading to organic growth and a reputation for excellence.
                    </p>
                    <p>
                        In the end, turning down payment in this instance was more than just a goodwill gesture. It was an investment in our client's future and, by extension, our own. This approach may not maximize short-term revenue, but it builds the foundation for sustained success and mutual growth.
                    </p>
                    <p>
                        True partnerships – where both parties are equally invested in each other's success – are invaluable. By prioritizing our clients' best interests, even at the expense of immediate gains, we're not just providing a service; we're cultivating success stories.
                    </p>
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
}

export default WhenTurningDownPaymentPage;
