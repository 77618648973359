import React from 'react';
import './GoogleComponent.css';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}`;

const GoogleComponent: React.FC = () => {
  return (
      <a className='google-button-wrapper' href={`${API_BASE_URL}/oauth2/authorization/google`} >
          <img src="https://developers.google.com/identity/images/g-logo.png" alt="Google sign-in" style={{ marginRight: '10px', height: '28px', width: '28px' }} />
          Continue with Google
      </a>
  );
}

export default GoogleComponent;

