import { Container, Form, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { addConnectionWithExpert, getConnectionById, updateConnection } from "../../api/connectionApi";
import { useAuthContext } from "../../App";
import './ConnectionPage.css';
import StyledInput from "../atoms/input/StyledInput";
import { ConnectionWithExpertRequest } from "../../types/connection";
import StyledSelect from "../atoms/select/StyledSelect";
import { RELATION_OPTION_SELECT_LIST } from "../../types/relation";
import StyledActionButton from "../atoms/button/StyledActionButton";
import StyledRadioGroup from "../molecules/radiogroup/StyledRadioGroup";

const ConnectionPage: React.FC = () => {
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const [selectedOption, setSelectedOption] = useState<string>('5');
    const { connectionId } = useParams<{ connectionId: string }>();
    
    const CONNECTION_STRENGHT_OPTIONS = [
        { value: '1', label: '01' },
        { value: '2', label: '02' },
        { value: '3', label: '03' },
        { value: '4', label: '04' },
        { value: '5', label: '05' },
        { value: '6', label: '06' },
        { value: '7', label: '07' },
        { value: '8', label: '08' },
        { value: '9', label: '09' },
        { value: '10', label: '10' },
      ];
    const [createConnectionRequest, setCreateConnectionRequest] = useState<ConnectionWithExpertRequest>({
        introductorId: user.personId ?? '',
        connectionExpertEmail: '',
        connectionDescription: '',
    
        expertName: '',
        expertLastName: '',
        expertLinkedinUrl: '',
        
        expertCompany: '',
        expertTitle: '',
        expertCompanyStartDate: '',
    
        relation: '', 
        relationStrength: 5
    });


    useEffect(() => {
        if(connectionId) {
            getConnectionById(connectionId).then(
                response => {
                    setCreateConnectionRequest(response.data);
                    setSelectedOption(String(response.data.relationStrength));
                }
            )
        }
    }, [connectionId]);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCreateConnectionRequest({
          ...createConnectionRequest,
          [event.target.name]: event.target.value,
        });
    };

    const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCreateConnectionRequest({...createConnectionRequest, [e.target.name]: e.target.value});
      };

    const handleRadioChange = (value: string) => {
        setSelectedOption(value);
        setCreateConnectionRequest({...createConnectionRequest, relationStrength: Number(value)});
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (user.personId !== undefined) {
            if (connectionId) {
                updateConnection(connectionId, createConnectionRequest)
                .then(() => {
                    navigate('/connections');
                });
            } else {
                addConnectionWithExpert(createConnectionRequest)
                .then(() => {
                    navigate('/connections');
                });
            }
        }
    }

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Container className='d-flex flex-column justify-content-center'>        
                    <Row className='connection-page-mobile-text text-center'>
                        <h4>{connectionId ? 'Update Connection' : 'Create New Connection'}</h4>
                    </Row>
                    <Row style={{marginTop: '20px'}}>
                        <p>
                            <b>Why We Need This</b>
                            <br/>
                                We know the strength of a professional connection lies in the context and quality of the relationship. 
                                That's why we ask you to share a bit about how you know each person in your network. 
                                Understanding the nature of your association, whether it's a former colleague, friend, or maybe just a shared interest, 
                                allows our AI to make more intelligent and relevant introductions and ensures you will be compensated for your great relationships!
                            <br/>
                            <br/>
                                We will only use this info internally to set meetings! 
                                We will <b>NEVER market, email, call, telegraph or telepathically connect </b> 
                                to anyone you add as a connection. We will never share, sell, trade or barter for a herd of goats, 
                                any information with any data brokers or any other external organization.
                                Pinkie swear. 
                        </p>
                    </Row>
                    <Row className='connection-page-input-row'>
                        <Form.Group>
                            <StyledInput 
                                value={createConnectionRequest.expertName} 
                                onChange={handleChange} 
                                type={'text'} 
                                name='expertName'
                                placeholder='First Name'
                                required={true}
                            />
                        </Form.Group>
                    </Row>
                    <Row className='connection-page-input-row'>
                        <Form.Group>
                            <StyledInput 
                                value={createConnectionRequest.expertLastName} 
                                onChange={handleChange} 
                                type={'text'} 
                                name='expertLastName'
                                placeholder='Last Name'
                                required={true}
                            />
                        </Form.Group>
                    </Row>
                    <Row className='connection-page-input-row'>
                        <Form.Group>
                            <StyledInput 
                                value={createConnectionRequest.connectionExpertEmail} 
                                onChange={handleChange} 
                                type={'text'} 
                                name='connectionExpertEmail'
                                placeholder='Email address'
                                required={true}
                            />
                        </Form.Group>
                    </Row>
                    <Row className='connection-page-input-row'>
                        <Form.Group>
                            <StyledInput 
                                value={createConnectionRequest.expertTitle} 
                                onChange={handleChange} 
                                type={'text'} 
                                name='expertTitle'
                                placeholder='Job Title'
                                required={true}
                            />
                        </Form.Group>
                    </Row>
                    <Row className='connection-page-input-row'>
                        <Form.Group>
                            <StyledInput 
                                value={createConnectionRequest.expertCompany} 
                                onChange={handleChange} 
                                type={'text'} 
                                name='expertCompany'
                                placeholder='Company'
                                required={true}
                            />
                        </Form.Group>
                    </Row>
                    <Row className='connection-page-input-row'>
                        <Form.Group>
                            <StyledInput 
                                value={createConnectionRequest.expertLinkedinUrl} 
                                onChange={handleChange} 
                                type={'text'} 
                                name='expertLinkedinUrl'
                                placeholder='Linkedin profile URL'
                            />
                        </Form.Group>
                    </Row>
                    <Row className='connection-page-input-row'>
                        <Form.Group>
                        <StyledSelect
                            onChangeSelect={handleSelectChange}
                            options={RELATION_OPTION_SELECT_LIST} 
                            name={'relation'} 
                            value={createConnectionRequest.relation} 
                            placeholder='Relationship'                        
                        />
                        </Form.Group>
                    </Row>
                    <Row className='connection-page-input-row'>
                        <Form.Group>
                        <h5>How well do you know this person?</h5>
                        <p>01 - barely, 10 - very well</p>
                        <StyledRadioGroup 
                            options={CONNECTION_STRENGHT_OPTIONS} 
                            groupName={'connectionStrengthRadioGroup'} 
                            selectedValue={selectedOption} 
                            onChange={handleRadioChange}                                                
                        />
                        </Form.Group>
                    </Row>
                    <Row className='connection-page-input-row'>
                        <Form.Group>
                            <StyledInput 
                                value={createConnectionRequest.connectionDescription} 
                                onChange={handleChange} 
                                type={'text'} 
                                name='connectionDescription'
                                placeholder='Anything else we should know about your relationship?'
                            />
                        </Form.Group>
                    </Row>
                    <Row className='connection-page-input-row' style={{marginBottom: '20px'}}>
                        <Form.Group>
                            <StyledActionButton type="submit" label={connectionId ? 'Update connection' : 'Create connection'} />
                        </Form.Group>
                    </Row>

                </Container>
            </Form>
        </>
    )

}

export default ConnectionPage;