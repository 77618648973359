import React from 'react';
import './RehumanizingNetworkPage.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';

import jeff from '../../../content/Jeff.jpeg';
import FooterAddition from '../../footer/FooterAddition';

const RehumanizingNetworkPage: React.FC = () => {
    return (
        <>
        <Container fluid className='justify-content-center'>
        <Row style={{paddingLeft: "20px", paddingTop: "50px", paddingRight: "20px", paddingBottom: "40px"}} className='justify-content-center'>
            <Row className='justify-content-center'>
                <h5 className="main-color rehumanizing-network-styled-text">Jeff, CEO Introductable</h5>
            </Row>
            <Row>
                <Col className='rehumanizing-network-title'><h2>“Sometimes to have a revolution, <span className="main-color">you need to go back to the past.”</span></h2></Col>
            </Row>
            <Row className='rehumanizing-network-styled-text' style={{ marginTop: '20px' }}>
                <p>Networking used to be done in person or by a friend or colleague making an introduction.
                    <br/>
                        It worked. Why? Trusted relationships.
                    <br/><br/>
                    That’s where Introductable comes in. 
                    <br/><br/>
                    Now, in a world that is seemingly dominated by impersonal interactions, mass outreach and tone deaf marketing, Introductable redefines networking from a transactional exchange of business cards and LinkedIn connections to genuine connection from a trusted third party that promotes genuine conversation.
                    <br/><br/>
                    In a world often dominated by impersonal interactions and digital barriers, Introductable seeks to bridge the gap between individuals looking for opportunities by making a human connection. Through its innovative platform, Introductable facilitates meaningful introductions transforming the way people expand their connections and begin conversations.
                    <br/>
                    At the core of Introductable's mission is the belief that technology should serve to amplify human connections rather than replace them. Introductable humanizes the often daunting modern world of networking. </p>
            </Row>
            <Row style={{ marginTop: '20px' }} className='justify-content-center'>
                <img src={jeff} alt="Jeff" className='rehumanizing-network-image' />
            </Row>
        </Row>
        </Container>
        <FooterAddition />
        </>
    );
    }

export default RehumanizingNetworkPage;
