import React from 'react';
import './CircledButton.css'; 
import { CircledButtonProps } from '../types';
import { Col, Container, Row } from 'react-bootstrap';
import { IoIosArrowRoundForward } from "react-icons/io";



const CircledButton: React.FC<CircledButtonProps> = ({ label, onClick, transitionX, maxWidth = '220px' }) => {
  return (
    <Container onClick={onClick} className='circled-button-wrapper' style={{maxWidth: `${maxWidth}`}}>
            <Row className="justify-content-between align-items-center">
                <Col>
                  <Row style={{ paddingLeft: "30px", minWidth: '131px'}}>
                    {label}
                  </Row>
                </Col>
                <Col xs="auto">
                  <div className='circle-arrow-wrapper' style={{ transform: `translateX(${transitionX})` }}>
                      <IoIosArrowRoundForward />
                  </div>
                </Col>
            </Row>
        </Container>
  );
};

export default CircledButton;

