import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../App';
import LoadingIndicator from '../loadingIndicator/LoadingIndicator';
import { UUID } from 'crypto';
import { TimeZone } from '../../utils/DateUtils';

const AuthHandler: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { setUser, setToken } = useAuthContext();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');
        const email = queryParams.get('email');
        const personId = queryParams.get('personId');
        const personName = queryParams.get('personName');
        const personLastName = queryParams.get('personLastName');
        const timezone = queryParams.get('timezone');

        if (token && email && personId) {            
            setToken(token);
            setUser({ email: email, personId: personId as UUID, timezone: timezone as TimeZone, personName: personName ?? '', personLastName: personLastName ?? ''});

            if (personName !== '' && personName != null && personName != undefined)  {
                navigate('/dashboard', { replace: true });
            } else {
                navigate('/profile/basic', { replace: true });
            }

        } else {
            navigate('/');
        }
    }, [location, navigate]);

    return <LoadingIndicator />;
};

export default AuthHandler;