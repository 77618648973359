import React from 'react';
import './PersonDetailsComponent.css';
import { Container, Row } from 'react-bootstrap';
import { PersonDetailsComponentProps } from '../types';
import '../../../Main.css';


const PersonDetailsComponent: React.FC<PersonDetailsComponentProps> = ({title, personName, occupation, company }) => {

    return (
        <Container className='person-details-component-wrapper'>
            <Row className='person-details-component-title'>
                <h4 style={{fontFamily: 'Raleway'}}>{title}</h4>
            </Row>
            <Row style={{paddingTop: '10px', paddingLeft: '10px'}}>
                <div className='person-details-component-name-text'>{personName}</div>
            </Row>
            <Row style={{paddingTop: '10px', paddingLeft: '10px'}}>
                <p>Occupation: {occupation}</p>
            </Row>
            <Row style={{paddingTop: '10px', paddingLeft: '10px'}}>
                <p>Company: {company}</p>
            </Row>
        </Container>
    );
    }

export default PersonDetailsComponent;
