import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { addIntroductorToTheMeeting } from '../../../../api/meetingApi';
import { AddMeetingIntroductorRequest } from '../../../../types/meeting';
import { getPersonIntroductors } from '../../../../api/personApi';
import { Person } from '../../../../types/person';
import { ActionFormProps } from './ActionFormTypes';
import '../../../../Main.css';


const PendingRequestedForm: React.FC<ActionFormProps> = ({ meeting, onSubmit }) => {
    const [addMeetingIntroductorRequest, setAddMeetingIntroductorRequest] = useState<AddMeetingIntroductorRequest>({ introductorId: '' });
    const [personIntroductors, setPersonIntroductors] = useState<Person[]>([]);
    const [selectedPerson, setSelectedPerson] = useState<Person | undefined>(undefined);

    useEffect(() => {
        if (meeting.expert?.personId) {
            getPersonIntroductors(meeting.expert?.personId)
                .then(response => setPersonIntroductors(response.data))
                .catch(error => console.error(error));
        }
    }, [meeting.expert, meeting.expert?.personId]);

    const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedId = e.target.value;
        const person = personIntroductors.find((p) => p.personId === selectedId);
        setSelectedPerson(person);
        if (person) {
            setAddMeetingIntroductorRequest({ introductorId: person.personId });
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (meeting.meetingId && selectedPerson) {
            await addIntroductorToTheMeeting(meeting.meetingId, addMeetingIntroductorRequest);
            onSubmit();
        }
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group>
                <Form.Label>Introductor</Form.Label>
                <Form.Select  value={selectedPerson?.personId || ''} onChange={handleChange}>
                    <option value="">Select Introductor</option>
                    {personIntroductors.map((person) => (
                        <option key={person.personId} value={person.personId}>
                            {person.name} {person.lastName}
                        </option>
                    ))}
                </Form.Select>
            </Form.Group>
            <br/>
            <Button variant="primary" type="submit" className='styled-button'>
                Add Introductor
            </Button>
        </Form>
    );
}

export default PendingRequestedForm;
