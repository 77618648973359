import React from 'react';
import './StyledCheckbox.css';
import { StyledCheckboxProps } from '../types';
import { FormCheck } from 'react-bootstrap';

const StyledCheckbox: React.FC<StyledCheckboxProps> = ({ checked, onChange, required = false, name, label }) => {
  return (
    <div className="custom-checkbox-wrapper">
      <FormCheck
        type="checkbox"
        id={name}
        name={name}
        checked={checked}
        onChange={onChange}
        required={required}
      />
      <label htmlFor={name}>{label}</label> 
    </div>
  );
}

export default StyledCheckbox;