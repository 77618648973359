import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { ActionFormProps } from './ActionFormTypes';
import '../../../../Main.css';

const PendingAllGoodDetailsAddedForm: React.FC<ActionFormProps> = ({ isIntroductor }) => {
    return (
        <Container>
            <Row style={{marginTop: '20px'}}>
              <h4>You are all set for now!</h4>  
            </Row>
            <Row style={{marginTop: '20px'}}>
              <p>
                Your meeting details and proposed times have been sent to the Introductor. 
                If a proposed time is accepted, the meeting will show up on each person's calendar.  
                If none of the proposed times work, the Introductor will contact you with new proposed times. 
                Have a great meeting! We know you'll do great!
              </p>  
            </Row>
        </Container>
    );
}

export default PendingAllGoodDetailsAddedForm;
