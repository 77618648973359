import React, { useState, useEffect, ChangeEvent } from 'react';
import { UpdatePersonRequest } from '../../types/person'
import { getPersonById, updatePerson } from  '../../api/personApi'
import { Form, Container, Row, Col } from 'react-bootstrap';
import './MyProfilePage.css'; 
import '../../Main.css';
import { useAuthContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import { UserReason } from '../../types/user';

import myProfileImage from '../../content/account/profile_account.svg';
import ImageWithTitlesCollage from '../molecules/collages/ImageWithTitlesCollage';
import StyledInput from '../atoms/input/StyledInput';
import { Occupation } from '../../types/occupation';
import StyledActionButton from '../atoms/button/StyledActionButton';
import StyledButton from '../atoms/button/StyledButton';
import StyledSelect from '../atoms/select/StyledSelect';
import { DateUtils, FORMATTED_MONTHS_LIST, FORMATTED_YEARS_LIST, MonthYear, TIMEZONES_SELECT_LIST } from '../../utils/DateUtils';
import { COUNTRIES_SELECT_LIST } from '../../utils/LocationUtils';

const MyProfilePage: React.FC = () => {
  const navigate = useNavigate();
  const { user, setUser, setToken } = useAuthContext();
  const [isUpdated, setIsUpdated] = useState(false);
  const [currentDateSelect, setCurrentDateSelect] = useState<MonthYear>({
      month: '',
      year: ''
  });
  const [lastRoleStartDateSelect, setLastRoleStartDateSelect] = useState<MonthYear>({
      month: '',
      year: ''
  });
  const [lastRoleEndDateSelect, setLastRoleEndDateSelect] = useState<MonthYear>({
      month: '',
      year: ''
  });
  const [currentRole, setCurrentRole] = useState<Occupation>({
    title: '',
    companyName: '',
    startDate: '',
  });
  const [lastRole, setLastRole] = useState<Occupation>({
    title: '',
    companyName: '',
    startDate: '',
    endDate: ''
  });

  const [updatePersonRequest, setUpdatePersonRequest] = useState<UpdatePersonRequest>({
    name: '',
    lastName: '',
    occupationList: [],
    educationList: [],
    linkedin: '',
    city: '',
    country: '',
    timezone: ''
  });

  useEffect(() => {
    if (user.personId !== undefined) {
      getPersonById(user.personId)
          .then(person => {
            setUpdatePersonRequest(person.data);
            const currentRoleStartDate = person.data.occupationList ? (person.data.occupationList.length > 0 ? person.data.occupationList[0].startDate : '') : '';
            const lastRoleStartDate = person.data.occupationList ? (person.data.occupationList.length > 1 ? person.data.occupationList[1].startDate : '') : '';
            const lastRoleEndDate = person.data.occupationList ? (person.data.occupationList.length > 1 ? person.data.occupationList[1].endDate : '') : '';
            setCurrentRole({
              title: person.data.occupationList ? (person.data.occupationList.length > 0 ? person.data.occupationList[0].title : '') : '',
              companyName: person.data.occupationList ? (person.data.occupationList.length > 0 ? person.data.occupationList[0].companyName : '') : '',
              startDate: currentRoleStartDate,
            });
            setLastRole({
              title: person.data.occupationList ? (person.data.occupationList.length > 1 ? person.data.occupationList[1].title : '') : '',
              companyName: person.data.occupationList ? (person.data.occupationList.length > 1 ? person.data.occupationList[1].companyName : '') : '',
              startDate: lastRoleStartDate,
              endDate: lastRoleEndDate,
            });
            setCurrentDateSelect({
              month: DateUtils.getMonthFromDate(currentRoleStartDate),
              year: DateUtils.getYearFromDate(currentRoleStartDate),
            });
            setLastRoleStartDateSelect({
              month: DateUtils.getMonthFromDate(lastRoleStartDate),
              year: DateUtils.getYearFromDate(lastRoleStartDate),
            });
            setLastRoleEndDateSelect({
              month: DateUtils.getMonthFromDate(lastRoleEndDate || ''),
              year: DateUtils.getYearFromDate(lastRoleEndDate || ''),
            });
          })
    }
}, [user.personId]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdatePersonRequest({
      ...updatePersonRequest,
      [event.target.name]: event.target.value,
    });
  };

  const handleMyConnectionsButton = () => {
    navigate('/connections');
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (user.personId !== undefined ) {
      currentRole.startDate = DateUtils.formatDate(currentDateSelect.month, currentDateSelect.year);
      const occupationListTemp = [];
      occupationListTemp.push(currentRole);
      if (lastRole.title) {
        lastRole.startDate = DateUtils.formatDate(lastRoleStartDateSelect.month, lastRoleStartDateSelect.year);
        lastRole.endDate = DateUtils.formatDate(lastRoleEndDateSelect.month, lastRoleEndDateSelect.year);
        occupationListTemp.push(lastRole);
      }
      updatePersonRequest.occupationList = occupationListTemp;
      updatePerson(user.personId, updatePersonRequest).then(
        () => {
          setIsUpdated(true);

        }
      );
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('jwtToken');
    setUser({
        email: '',
        password: '',
        personId: undefined, 
        userReason: UserReason.LOOK_AROUND,
        timezone: undefined,
        personName: undefined,
        personLastName: undefined
    });
    setToken('');
    navigate('/');
}

  function handleChangeCurrentRole(event: ChangeEvent<HTMLInputElement>): void {
    setCurrentRole({
      ...currentRole,
      [event.target.name]: event.target.value,
    });
  }

  function handleLastRole(event: ChangeEvent<HTMLInputElement>): void {
    setLastRole({
      ...lastRole,
      [event.target.name]: event.target.value,
    });
  }

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setUpdatePersonRequest({...updatePersonRequest, [e.target.name]: e.target.value});
  };

  const handleCurrentRoleDateSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setCurrentDateSelect({...currentDateSelect, [e.target.name]: e.target.value});
  };

  const handleLastRoleStartDateSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setLastRoleStartDateSelect({...lastRoleStartDateSelect, [e.target.name]: e.target.value});
  };

  const handleLastRoleEndDateSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setLastRoleEndDateSelect({...lastRoleEndDateSelect, [e.target.name]: e.target.value});
  };

  return (
    <>
      <Container fluid>
          <Row style={{ marginTop: '20px', marginLeft: '20px'}}>
              <Col className="d-flex justify-content-end align-items-center">
                <div style={{minWidth: '150px'}}>
                  <StyledButton label={'Logout'} onClick={handleLogout}/>
                </div>
              </Col>
          </Row>
          <Row style={{ paddingTop: "20px", paddingBottom: '20px' }}>
              <Col md={7} style={{ paddingLeft: "20px" }}>
              <Form onSubmit={handleSubmit}>
                  <Row style={{paddingTop: "34px"}}>
                      <Row>
                        <Col className="d-flex justify-content-center align-items-center">
                          <h4>Update <span className="main-color">Profile</span></h4>
                        </Col>
                        <Col>
                          <StyledActionButton label={'My Connections Panel'} onClick={handleMyConnectionsButton}/>
                        </Col>
                      </Row>
                      <Row style={{paddingTop: "10px"}} className='my-profile-mobile-columns'>
                          <Col>
                            <Form.Group>
                              <StyledInput 
                                value={updatePersonRequest.name} 
                                onChange={handleChange} 
                                type={'text'} 
                                name='name'
                                placeholder='First Name'
                                required={true}
                              />
                          </Form.Group>
                          </Col>
                          <Col className='my-profile-input-element'>
                            <Form.Group>
                                <StyledInput 
                                  value={updatePersonRequest.lastName} 
                                  onChange={handleChange} 
                                  type={'text'} 
                                  name='lastName'
                                  placeholder='Last Name'
                                  required={true}
                                />
                            </Form.Group>
                          </Col>
                      </Row>
                      <Row style={{paddingTop: "10px"}} className='my-profile-mobile-columns'>
                          <Col>
                            <Form.Group>
                              <StyledSelect 
                                value={updatePersonRequest.country ?? ''}
                                name='country'
                                placeholder='Select country'
                                required={true} 
                                onChangeSelect={handleSelectChange} 
                                options={COUNTRIES_SELECT_LIST}                              
                              />
                          </Form.Group>
                          </Col>
                          <Col className='my-profile-input-element'>
                            <Form.Group>
                                <StyledInput 
                                  value={updatePersonRequest.city ?? ''} 
                                  onChange={handleChange} 
                                  type={'text'} 
                                  name='city'
                                  placeholder='Your city'
                                  required={true}
                                />
                            </Form.Group>
                          </Col>
                      </Row>
                      <Row style={{paddingTop: "10px"}} className='my-profile-mobile-columns'>
                          <Col>
                            <Form.Group>
                              <StyledSelect 
                                value={updatePersonRequest.timezone ?? ''}
                                name='timezone'
                                placeholder='Select your timezone'
                                required={true} 
                                onChangeSelect={handleSelectChange} 
                                options={TIMEZONES_SELECT_LIST}                              
                              />
                          </Form.Group>
                          </Col>
                          <Col>
                          </Col>
                      </Row>
                      <Row style={{paddingTop: "10px"}} className='my-profile-mobile-columns'>
                        <Col>
                          <Form.Group>
                            <StyledInput 
                              value={currentRole.title} 
                              onChange={handleChangeCurrentRole} 
                              type={'text'} 
                              name='title'
                              placeholder='Current Role'
                              required={true}
                            />
                          </Form.Group>
                          </Col>
                          <Col className='my-profile-input-element'>
                            <Form.Group>
                                <StyledInput 
                                  value={currentRole.companyName} 
                                  onChange={handleChangeCurrentRole} 
                                  type={'text'} 
                                  name='companyName'
                                  placeholder='Current Company Name'
                                  required={true}
                                />
                            </Form.Group>
                          </Col>
                      </Row>
                      <Row style={{paddingTop: "10px"}}>
                        <p>Current Company Start Date</p>
                      </Row>
                      <Row className='my-profile-mobile-columns'>
                          <Col>
                            <Form.Group>
                              <StyledSelect
                                  onChangeSelect={handleCurrentRoleDateSelectChange}
                                  options={FORMATTED_MONTHS_LIST} 
                                  name={'month'} 
                                  value={currentDateSelect.month}  
                                  placeholder='Company Start Month'   
                                  required={true}                     
                                />
                            </Form.Group>
                          </Col>
                          <Col className='my-profile-input-element'>
                            <Form.Group>
                                <StyledSelect
                                  onChangeSelect={handleCurrentRoleDateSelectChange}
                                  options={FORMATTED_YEARS_LIST} 
                                  name={'year'} 
                                  value={currentDateSelect.year}  
                                  placeholder='Company Start Year'                            
                                  required={true}
                                />
                            </Form.Group>
                          </Col>
                      </Row>
                      <Row style={{paddingTop: "10px"}} className='my-profile-mobile-columns'>
                          <Form.Group>
                            <StyledInput 
                                value={updatePersonRequest.linkedin || ''} 
                                onChange={handleChange} 
                                type={'text'} 
                                name='linkedin'
                                placeholder='Your Linkedin Profile URL'
                            />
                          </Form.Group>
                      </Row>
                      <Row style={{paddingTop: "10px"}} className='my-profile-mobile-columns'>
                        <Col>
                          <Form.Group>
                            <StyledInput 
                              value={lastRole.title} 
                              onChange={handleLastRole} 
                              type={'text'} 
                              name='title'
                              placeholder='Last Role'
                            />
                          </Form.Group>
                          </Col>
                          <Col className='my-profile-input-element'>
                            <Form.Group>
                                <StyledInput 
                                  value={lastRole.companyName} 
                                  onChange={handleLastRole} 
                                  type={'text'} 
                                  name='companyName'
                                  placeholder='Last Company Name'
                                />
                            </Form.Group>
                          </Col>
                      </Row>
                      <Row style={{paddingTop: "10px"}}>
                        <p>Last Company Start Date</p>
                      </Row>
                      <Row className='my-profile-mobile-columns'>
                          <Col>
                            <Form.Group>
                              <StyledSelect
                                  onChangeSelect={handleLastRoleStartDateSelectChange}
                                  options={FORMATTED_MONTHS_LIST} 
                                  name={'month'} 
                                  value={lastRoleStartDateSelect.month}  
                                  placeholder='Last Company Start Month'                        
                                />
                            </Form.Group>
                          </Col>
                          <Col className='my-profile-input-element'>
                            <Form.Group>
                                <StyledSelect
                                  onChangeSelect={handleLastRoleStartDateSelectChange}
                                  options={FORMATTED_YEARS_LIST} 
                                  name={'year'} 
                                  value={lastRoleStartDateSelect.year}  
                                  placeholder='Last Company Start Year'                            
                                />
                            </Form.Group>
                          </Col>
                      </Row>
                      <Row style={{paddingTop: "10px"}}>
                        <p>Last Company End Date</p>
                      </Row>
                      <Row className='my-profile-mobile-columns'>
                          <Col>
                            <Form.Group>
                              <StyledSelect
                                  onChangeSelect={handleLastRoleEndDateSelectChange}
                                  options={FORMATTED_MONTHS_LIST} 
                                  name={'month'} 
                                  value={lastRoleEndDateSelect.month}  
                                  placeholder='Last Company End Month'                        
                                />
                            </Form.Group>
                          </Col>
                          <Col className='my-profile-input-element'>
                            <Form.Group>
                                <StyledSelect
                                  onChangeSelect={handleLastRoleEndDateSelectChange}
                                  options={FORMATTED_YEARS_LIST} 
                                  name={'year'} 
                                  value={lastRoleEndDateSelect.year}  
                                  placeholder='Last Company End Year'                            
                                />
                            </Form.Group>
                          </Col>
                      </Row>
                  </Row>
                  <Row style={{ paddingTop: "18px" }}>
                    <Col></Col>
                    <Col><StyledActionButton label={'Update Info'} type="submit"/></Col>
                    <Col></Col>
                  </Row>
                  </Form>
                  {
                    isUpdated && (
                      <Row style={{ paddingTop: "18px" }}>
                        <Col className="d-flex justify-content-center align-items-center"><p>Updated successfully</p></Col>
                      </Row>
                    )
                  }
              </Col>
              <Col md={5} style={{ padding: 0 }}>
                <ImageWithTitlesCollage imageSrc={myProfileImage} firstTitle={'Update your profile now!'} secondTitle={'ON INTRODUCTABLE'} />
              </Col>
          </Row>
      </Container>
    </>
  );
}

export default MyProfilePage;
