import { Col, Container, Row } from 'react-bootstrap';
import './MyConnectionsPage.css';
import  '../../Main.css';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../App';
import { useNavigate } from 'react-router-dom';
import StyledButton from '../atoms/button/StyledButton';
import StyledTable from '../molecules/table/styledtable/StyledTable';
import StyledActionButton from '../atoms/button/StyledActionButton';
import { getAllIntroductorExperts } from '../../api/connectionApi';
import { RelationTypeEnum, getRelationTypeString } from '../../types/relation';

const MyConnectionsPage: React.FC = () => {
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const [isActive, setIsActive] = useState<boolean>(true);
    const [dashboardTableData, setDashboardTableData] = useState<string[][]>([]);
    const [tableRowIds, setTableRowIds] = useState<string[]>([]);
    const MY_CONNECTIONS_TABLE_HEADERS = ['Name', 'Title', 'Company Name', 'Relationship', 'Action'];
    
    useEffect(() => {
        if (user.personId !== undefined) {
            if (isActive) {
                getAllIntroductorExperts(user.personId)
                    .then(response => {
                        const tableData: React.SetStateAction<string[][]> = [];
                        const ids: React.SetStateAction<string[]>  = [];

                        response.data.map((connection, index) => {
                            const meetingRow = [
                                connection.expertName + ' ' + connection.expertLastName,
                                connection.expertTitle,
                                connection.expertCompany,
                                getRelationTypeString(connection.relation as RelationTypeEnum),
                                'Edit connection'
                        ];
                            tableData.push(meetingRow);
                            ids.push(connection.connectionId);
                        })

                        setDashboardTableData(tableData);
                        setTableRowIds(ids);
                    })
            } else {

            }
        }
    }, []);

    const handleAddNewConnection = () => {
      navigate('/connections/add');
    };

    const handleOnClickRow = (connectionId: string) => {
      navigate(`/connections/${connectionId}`);
    };

    return (
        <> 
        <Container fluid style={{ height: "calc(100vh - 100px)", maxWidth: '90%' }}>
            <Row style={{ paddingTop: "40px"}}>
                <Col style={{maxWidth: "190px"}}><StyledButton label={'Active Connections'} variant={isActive ? 'secondary' : 'third'} onClick={() => setIsActive(true)}/></Col>
                <Col style={{maxWidth: "190px"}}><StyledButton label={'Archive Connections'} variant={!isActive ? 'secondary' : 'third'} onClick={() => setIsActive(false)}/></Col>
            </Row>
            <Row style={{ paddingTop: "30px"}}>
                <Col className="d-flex align-items-center justify-content-start"><h4>{'Connection Panel'}</h4></Col>
                <Col className="d-flex align-items-center justify-content-end">
                    <div style={{maxWidth: '250px'}}><StyledActionButton label={'Create New Connection'} onClick={handleAddNewConnection} /></div>
                </Col>
            </Row>
            <Row style={{ paddingTop: "20px"}}>
                <StyledTable 
                        tableHeaders={MY_CONNECTIONS_TABLE_HEADERS} 
                        tableData={dashboardTableData} 
                        tableRowsIds={tableRowIds}
                        onClickRow={handleOnClickRow}
                    /> 
            </Row>
        </Container>
        </>
    )
};

export default MyConnectionsPage;
