import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import WhyUsComponent from "../organisms/whyus/WhyUsComponent";
import CircledButton from "../atoms/button/CircledButton";
import { useNavigate } from "react-router-dom";
import './ContactUsPage.css';
import '../../Main.css';
import FooterAddition from "../footer/FooterAddition";
import ContactUsImage from '../../content/contactus/contact-us-image.jpg';
import StyledLink from "../atoms/link/StyledLink";


const ContactUsPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <Container fluid>
                <Row>
                    <img src={ContactUsImage} className="contact-us-title-image" alt="AboutUsTitle" />
                </Row>
                <Row style={{ marginTop: '35px', textAlign: 'center' }}>
                    <Col><h2><span className="main-color">Contact us!</span></h2></Col>
                </Row>
            </Container>
            <Container fluid>
                <Row className='contact-us-centered-text' style={{ marginTop: '25px' }}>
                    <p style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px"}}>
                    <b>Looking to say hello? Awesome! </b>
                    Who do you want to connect with? (we believe in the power of connections)
                    </p>
                </Row>
                <Row className='contact-us-centered-text'>
                    <p style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px"}}>
                        <StyledLink href={"mailto:sales@introductable.com"} bold={true}>Sales? </StyledLink> Connect with our awesome sales team. They can help you get started or answer questions for you.
                    </p>
                </Row>
                <Row className='contact-us-centered-text'>
                    <p style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px"}}>
                        <StyledLink href={"mailto:media@introductable.com"} bold={true}>Media? </StyledLink> We love our friends in the media! Digital, traditional, press or something else entirely; nothing but love. 
                    </p>
                </Row>
                <Row className='contact-us-centered-text'>
                    <p style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px"}}>
                        <b>Leadership?</b> Jeff and Chem are always open to our clients or prospective clients! 
                        If you want to pitch your product, better click <StyledLink href={"/meet-founders"} bold={true}>here </StyledLink> 
                        instead to get a response as they take no meetings unless they come via a request on Introductable. 
                        And to keep things fair, there's a nominal fee of just $1 per meeting. 
                        Don’t worry—all proceeds are donated to charity. Currently, we're supporting the National Pediatric Cancer Foundation, 
                        which invests in research and provides support services for families of children with cancer.
                    </p>
                </Row>
                <Row className='contact-us-centered-text'>
                    <p style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px"}}>
                        <StyledLink href={"mailto:info@introductable.com"} bold={true}>General info? </StyledLink> 
                        Have questions or want to learn more about Introductable? Get in touch with our team today.
                        <br/>
                        Email: <a className='contact-link' href='mailto:info@introductable.com'>info@introductable.com</a>
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <WhyUsComponent />
                </Row>
                <Row style={{ marginTop: '35px', marginBottom: '25px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
            </Container>
            <FooterAddition />
        </>
    );
};

export default ContactUsPage;
