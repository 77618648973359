import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './ProcessSteps.css';
import '../../../Main.css';

const ProcessSteps: React.FC = () => {

  return (
    <Container>
      <Row className='process-steps-wrapper'>
        <Col xs={6} md={2} style={{marginTop: '20px'}}>
            <Row>
                <h3 className='main-color'>01</h3>
            </Row>
            <Row>
                <h4>Create your Profile</h4>
            </Row>
            <Row>
                <p>Create a free profile that details your career, network and industry expertise.</p>
            </Row>
        </Col>
        <Col xs={6} md={2} style={{marginTop: '20px'}}>
            <Row>
                <h3 className='main-color'>02</h3>
            </Row>
            <Row>
                <h4>Select Meeting Target</h4>
            </Row>
            <Row>
                <p>Search by name, company or title for your meeting targets.</p>
            </Row>
        </Col>
        <Col xs={6} md={2} style={{marginTop: '20px'}}>
            <Row>
                <h3 className='main-color'>03</h3>
            </Row>
            <Row>
                <h4>Secure Meetings</h4>
            </Row>
            <Row>
                <p>Be connected with a close associate of your target (Introductor) and provide suggested times, the reason and the purpose for the meeting.</p>
            </Row>
        </Col>
        <Col xs={6} md={2} style={{marginTop: '20px'}}>
            <Row>
                <h3 className='main-color'>04</h3>
            </Row>
            <Row>
                <h4>Agree on Meeting Date and Time</h4>
            </Row>
            <Row>
                <p>Your Introductor will arrange a Google Meet time that works best for everyone.</p>
            </Row>
        </Col>
        <Col xs={6} md={2} style={{marginTop: '20px'}}>
            <Row>
                <h3 className='main-color'>05</h3>
            </Row>
            <Row>
                <h4>Meeting</h4>
            </Row>
            <Row>
                <p>Go get 'em! You will not be charged if the meeting doesn't happen.</p>
            </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default ProcessSteps;
