import React from 'react';
import './TopTenReachProspectsPage.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';
import FooterAddition from '../../footer/FooterAddition';

import blogImage from '../../../content/blog/toptenreachprospects/toptenreachprospects.png';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';

const TopTenReachProspectsPage: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
        <Container fluid className='justify-content-center'>

            <Row style={{paddingLeft: "20px", paddingTop: "50px", paddingRight: "20px", paddingBottom: "40px"}} className='justify-content-center'>
                <Row>
                    <Col className='top-ten-reach-prospects-title'><h2>Top 10 Reasons <span className="main-color">Why Introductable Can Help You</span>  Reach Key High-Value Prospects</h2></Col>
                </Row>
                <Row style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={blogImage} className="top-ten-reach-prospects-title-image" alt="topTenReachProspectsImage" />
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>1. Trust-Based Introductions</b></h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        Introductable leverages trusted networks, connecting you with prospects through verified, warm introductions. This approach helps bypass cold outreach and creates a solid foundation for productive conversations.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>2. Quality Over Quantity</b></h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                    The platform focuses on generating fewer but higher-quality leads, helping you reach decision-makers who are truly relevant to your business, improving the chances of conversion.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>3. Relationship-Driven Prospecting</b></h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                    By leveraging relationships, Introductable ensures that prospects are more likely to respond positively. They are approached by someone they trust, which dramatically improves engagement rates.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>4. Access to High-Value Networks</b></h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        Introductable provides access to exclusive, hard-to-reach networks. These are often senior executives or key decision-makers who would typically be difficult to connect with through traditional outreach methods.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>5. Efficient Lead Generation</b></h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                    The platform saves time by filtering out low-quality leads. This allows sales teams to focus on prospects that are likely to generate higher returns, maximizing efficiency.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>6. Personalized Introductions</b></h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                    Each introduction is tailored to ensure relevance, aligning your value proposition with the prospect's needs. This personalization enhances rapport from the get-go, making the conversation more meaningful.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>7. Improved Close Rates</b></h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                    Since the prospects are already vetted and introduced via trusted sources, your close rates increase. This means you spend less time on unproductive outreach and more time closing deals with qualified leads.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>8. Building Long-Term Relationships</b></h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                    Introductable isn’t just about making one-time introductions. It fosters long-term business relationships by prioritizing mutual benefit and trust, laying the groundwork for future opportunities.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>9. Enhanced Credibility</b></h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                    Being introduced by someone a prospect already trusts instantly boosts your credibility. This positions you as a solution-provider rather than just another salesperson, increasing your influence and authority.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>10. Simplified Prospecting Process</b></h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                    Introductable streamlines the entire process, making it simpler and less stressful to reach out to high-value prospects. This frees up more time for strategic selling rather than being bogged down by administrative tasks and cold outreach.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                    By focusing on trust-based, relationship-driven introductions, Introductable effectively solves the key challenges sales professionals face when trying to engage with high-value prospects.
                    </p>
                </Row>
                <Row className='justify-items-start' style={{ marginTop: '35px', marginBottom: '25px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
    }

export default TopTenReachProspectsPage;
