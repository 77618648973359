import { Col, Container, Row } from "react-bootstrap";
import StyledRadio from "../../atoms/radio/StyledRadio";
import { StyledRadioGroupProps } from "../types";
import './StyledRadioGroup.css';

const StyledRadioGroup: React.FC<StyledRadioGroupProps> = ({ options, groupName, selectedValue, onChange }) => {
    return (
      <Container fluid>
        <Row className='styled-radio-wrapper'>
            {options.map(option => (
                <Col>
                    <StyledRadio
                        key={option.value}
                        checked={selectedValue === option.value}
                        onChange={() => onChange(option.value)}
                        name={groupName}
                        label={option.label}
                    />
                </Col>
            ))}
        </Row>
    </Container>
    );
  };
  
  export default StyledRadioGroup;
  