import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import successIcons from '../../content/account/success.svg';
import StyledLabel from '../atoms/label/StyledLabel';

const PaymentSuccessPage: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const meetingId = searchParams.get('meeting_id');
    const [seconds, setSeconds] = useState(5);

    useEffect(() => {
        if (seconds > 0) {
            const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
            return () => clearTimeout(timer);
        } else {
            
            navigate(`/meetings/${meetingId}`);
        }
    }, [seconds, navigate, meetingId]); 


    return (
        <>
            <Container fluid style={{ height: "calc(100vh - 100px)" }}>
                <Row className="justify-content-center mb-2" style={{ paddingTop: "80px"}}>
                    <Col className="text-center">
                        <h4>Success!</h4>
                    </Col>
                </Row>
                <Row className="justify-content-center mb-2">
                    <Col className="text-center">
                        <StyledLabel align='center' label={'Payment has been successful'} />
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <StyledLabel align='center' label={`You will be redirected to the meeting page in ${seconds} seconds...`} />
                    </Col>
                </Row>
                <Row className="justify-content-center" style={{ paddingTop: "20px"}}>
                    <Col style={{ paddingLeft: "30%", paddingRight: "30%" }}> 
                        <img src={successIcons} style={{ width: "100%", height: "auto" }} alt="Success Icons" />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default PaymentSuccessPage;
