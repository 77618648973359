import React from 'react';
import './FooterAddition.css';
import '../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logoWhite from '../../content/logo/logo-white.png';


const FooterAddition: React.FC = () => {
    return (
        <Container fluid className="footer-addition-wrapper">
            <Row className="footer-addition-elements-position">
                <Col xs={12} md={3}>
                    <Link to="/">
                        <img src={logoWhite} alt="Logo" className='footer-additon-logo-image' />
                    </Link>
                </Col>
                <Col xs={6} md={3} className='footer-mobile-menu'>
                    <h5 className='footer-addition-column-header' >MENU</h5>
                    <Link to="/" className='footer-addition-column-link'>
                        <div>Home</div>
                    </Link>
                    <Link to="/about-us" className='footer-addition-column-link'>
                        <div>About</div>
                    </Link>
                    <Link to="/news" className='footer-addition-column-link'>
                        <div>Company Blog</div>
                    </Link>
                </Col>
                <Col xs={6} md={3} className='footer-mobile-menu'>
                    <h5 className='footer-addition-column-header' >NEWS</h5>
                    <Link to="/blog/value-of-trusted-introductions" className='footer-addition-column-link'>
                        <div>Value of Trusted Introductions</div>
                    </Link>
                    <Link to="/blog/power-of-warm-introductions" className='footer-addition-column-link'>
                        <div>Power of Warm Introductions</div>
                    </Link>
                    <Link to="/blog/ai-at-introductable" className='footer-addition-column-link'>
                        <div>AI in Introductable</div>
                    </Link>
                    <Link to="/blog/rehumanizing-network" className='footer-addition-column-link'>
                        <div>Re Humanizing Networking</div>
                    </Link>
                    <Link to="/blog/lead-generation" className='footer-addition-column-link'>
                        <div>Demand Generation</div>
                    </Link>
                    <Link to="/meet-founders" className='footer-addition-column-link'>
                        <div>Why Introductable</div>
                    </Link>
                </Col>
                <Col xs={5} md={3} className='footer-mobile-menu'>
                    <h5 className='footer-addition-column-header' >INFORMATION</h5>
                    <Link to="/privacy-policy" className='footer-addition-column-link'>
                        <div>Privacy</div>
                    </Link>
                    <Link to="/terms-conditions" className='footer-addition-column-link'>
                        <div>Terms & Conditions</div>
                    </Link>
                    <Link to="/faq" className='footer-addition-column-link'>
                        <div>FAQ</div>
                    </Link>
                    <Link to="/pricing" className='footer-addition-column-link'>
                        <div>Pricing</div>
                    </Link>
                </Col>
                <Col xs={6} md={12} className='footer-mobile-menu'>
                    <a href='mailto:info@introductable.com' className='footer-addition-column-link'>
                        info@introductable.com
                    </a>
                </Col>
            </Row>
        </Container>
    );
}

export default FooterAddition;
