import React from "react";
import './TermsConditionsComponent.css'; 
import { Container } from "react-bootstrap";
import FooterAddition from "../footer/FooterAddition";
import HorizontalLineSimple from "../atoms/lines/HorizontalLineSimple";
import StyledLink from "../atoms/link/StyledLink";

const TermsConditionsComponent: React.FC = () => {
    return (
        <>
        <Container fluid className="terms-conditions">
            <h2>Introductable Terms and Conditions</h2>
            
            <p>These terms and conditions ("Terms") govern your use of Introductable ("the Service"), provided by Introductable ("we", "us", or "our"). By accessing or using the Service, you agree to be bound by these Terms. If you do not agree to these Terms, please do not use the Service.</p>
            
            <h4 style={{marginTop: '20px'}}>Use of the Service</h4>
            <p>You must be at least 18 years old or have the consent of a parent or legal guardian to use the Service.</p>
            <p>You agree to use the Service only for lawful purposes and in accordance with these Terms.</p>
            <p>You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account. You agree to accept responsibility for all activities that occur under your account.</p>
            <HorizontalLineSimple/>

            <h4 style={{marginTop: '20px'}}>Intellectual Property</h4>
            <p>The Service and its original content, features, and functionality are owned by Introductable and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.</p>
            <p>You may not modify, reproduce, distribute, create derivative works of, publicly display, publicly perform, republish, download, store, or transmit any of the material on the Service, except as provided by these Terms.</p>
            <HorizontalLineSimple/>

            <h4 style={{marginTop: '20px'}}>Privacy</h4>
            <p>Your use of the Service is subject to our Privacy Policy, which is incorporated by reference into these Terms. By using the Service, you consent to the collection and use of your information in accordance with 
                our <StyledLink href={"/privacy-policy"} > Privacy Policy</StyledLink>.</p>
            
            <h4 style={{marginTop: '20px'}}>Payment and Subscription</h4>
            <p>Certain features of the Service may require payment of fees. By subscribing to these features, you agree to pay all fees and charges incurred in connection with your account.</p>
            <p>Fees are subject to change upon notice from us. All prices are in United States dollars and are non-refundable; except as per our MEET guarantee. Introductable shall have sole discretion applying the guarantee.</p>
            <HorizontalLineSimple/>

            <h4 style={{marginTop: '20px'}}>Publicity Clause</h4>
            <p>By using Introductable ("the Service"), you agree that with written, prior authorization, Introductable may use your name, likeness, logo, and any content provided by you (such as testimonials, feedback, or reviews) for promotional and marketing purposes, including but not limited to advertising, marketing materials, social media posts, case studies, and press releases, without further consent or compensation. You hereby grant Introductable a perpetual, worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use, reproduce, distribute, and display such materials in connection with the promotion of the Service.</p>
            <HorizontalLineSimple/>

            <h4 style={{marginTop: '20px'}}>Termination</h4>
            <p>We reserve the right to terminate or suspend your access to the Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.</p>
            <p>Upon termination, your right to use the Service will immediately cease. All provisions of these Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>
            <HorizontalLineSimple/>

            <h4 style={{marginTop: '20px'}}>Disclaimer</h4>
            <p>The Service is provided "as is" and "as available" without any warranties, express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement.</p>
            <p>We do not warrant that the Service will be uninterrupted, timely, secure, or error-free, that defects will be corrected, or that the Service or the server that makes it available are free of viruses or other harmful components.</p>
            <HorizontalLineSimple/>

            <h4 style={{marginTop: '20px'}}>Limitation of Liability</h4>
            <p>In no event shall Introductable, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.</p>
            <HorizontalLineSimple/>

            <h4 style={{marginTop: '20px'}}>Governing Law</h4>
            <p>These Terms shall be governed and construed in accordance with the laws of Delaware, without regard to its conflict of law provisions.</p>
            <p>Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect.</p>
            <HorizontalLineSimple/>

            <h4 style={{marginTop: '20px'}}>Changes</h4>
            <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.</p>
            <HorizontalLineSimple/>

            <h4 style={{marginTop: '20px'}}>Contact Us</h4>
            <p>If you have any questions about these Terms, please contact us at info@introductable.com</p>
            <HorizontalLineSimple/>

            <br />
            <br />
            <h2>Terms and Conditions - Meeting Payment and Guarantee</h2>

            <h4 style={{marginTop: '20px'}}>Meeting Payment</h4>
            <p>By utilizing Introductable's meeting payment feature, the payee agrees to pay the specified amount for each scheduled meeting facilitated through the platform.</p>
            <HorizontalLineSimple/>

            <h4 style={{marginTop: '20px'}}>Refund Guarantee</h4>
            <p>Introductable guarantees that the payee will only be charged for meetings that are successfully held as scheduled. In the event that a scheduled meeting does not occur due to reasons beyond the control of the payee (e.g., no-show by the other party, technical issues with the platform), Introductable will provide a full refund for the meeting fee.</p>
            <HorizontalLineSimple/>

            <h4 style={{marginTop: '20px'}}>Responsibility for Non-Occurrence</h4>
            <p>The payee acknowledges that they are not responsible for the non-occurrence of scheduled meetings if such non-occurrence is due to factors outside of their control. Introductable reserves the right to determine the validity of claims for refunds based on the circumstances surrounding the non-occurrence of scheduled meetings.</p>
            <HorizontalLineSimple/>

            <h4 style={{marginTop: '20px'}}>Refund Process</h4>
            <p>To request a refund for a meeting that did not occur, the payee must submit a refund request through Introductable's designated channels within three business days. Introductable will review the request and process the refund promptly if deemed valid.</p>
            <HorizontalLineSimple/>

            <h4 style={{marginTop: '20px'}}>Limitation of Liability</h4>
            <p>Introductable shall not be liable for any damages, losses, or expenses incurred by the payee as a result of the non-occurrence of scheduled meetings, except for the refund of the meeting fee as specified in this clause. </p>
            <HorizontalLineSimple/>

            <h4 style={{marginTop: '20px'}}>Hold Harmless Clause</h4>
            <p>By agreeing to these terms and conditions, the user agrees to hold Introductable, its affiliates, and partners blameless for any disputes, damages, or losses arising from the scheduling or non-occurrence of meetings facilitated through the platform. The user acknowledges that Introductable acts solely as a facilitator in connecting parties and is not responsible for the actions or inactions of users or external factors that may impact meeting outcomes.</p>
            <HorizontalLineSimple/>

            <h4 style={{marginTop: '20px'}}>Indemnification</h4>
            <p>The user agrees to indemnify and hold harmless Introductable, its officers, directors, employees, agents, and partners from any claims, demands, liabilities, damages, losses, or expenses, including legal fees, arising out of or in connection with the user's use of the platform, including but not limited to any disputes related to scheduled meetings, payment disputes, or breaches of these terms and conditions.</p>
            <HorizontalLineSimple/>

            <h4 style={{marginTop: '20px'}}>Modification of Terms</h4>
            <p>Introductable reserves the right to modify or update these terms and conditions related to meeting payment and guarantee at any time without prior notice. It is the responsibility of the payee to review and adhere to the most current version of the terms and conditions.</p>
            <HorizontalLineSimple/>
            <br />
            <br />
            <p>By using Introductable's meeting payment feature, the payee acknowledges and agrees to abide by the terms and conditions outlined herein.</p>
            <HorizontalLineSimple/>
            <br />
            <br />

            <h2>Meetings Compensation Terms and Conditions</h2>

            <br />
            <br />
            <p>By utilizing Introductable's matchmaking platform to set meetings with potential connections, users agree to the following terms and conditions regarding compensation for facilitated meetings.</p>
            <HorizontalLineSimple/>

            <br />
            <br />
            <p>1. Compensation Payment: Users who successfully set meetings through Introductable will be eligible for compensation, which will be paid within 30 days after the completion of the meeting and the conclusion of the feedback window.</p>
            <HorizontalLineSimple/>

            <br />
            <br />
            <p>2. Completion of Meeting: A meeting is considered complete once both parties have attended the scheduled meeting at the agreed-upon time and location, or via virtual means as mutually agreed.</p>
            <HorizontalLineSimple/>

            <br />
            <br />
            <p>3. Feedback Window: Following the meeting, both parties will have a specified period to provide feedback on the meeting's outcome and overall experience. The feedback window will typically remain open for a predetermined period after the meeting date.</p>
            <HorizontalLineSimple/>

            <br />
            <br />
            <p>4. Compensation Calculation: The compensation amount will be determined based on the terms herein. Introductable will facilitate the payment process according to these agreed-upon terms. Introductors will be compensated between 40-60% of the completed meeting pricing. A bonus may be paid based upon Introductor rating.</p>
            <HorizontalLineSimple/>

            <br />
            <br />
            <p>5. Payment Processing: Payments will be processed within 30 days after the meeting completion date and the conclusion of the feedback window. Users are responsible for ensuring that accurate payment information is provided to facilitate timely payment processing.</p>
            <HorizontalLineSimple/>

            <br />
            <br />
            <p>6. All required taxes to be paid are the responsibility of the Introductor.</p>
            <HorizontalLineSimple/>

            <br />
            <br />
            <p>7. Dispute Resolution: In the event of disputes regarding meeting completion, feedback validity, or compensation amounts, Introductable reserves the right to investigate the matter and make a final determination based on the available information.</p>
            <HorizontalLineSimple/>

            <br />
            <br />
            <p>8. Compliance with Terms: Users are expected to comply with all terms and conditions outlined in Introductable's Terms of Service and Privacy Policy. Failure to adhere to these terms may result in the forfeiture of compensation eligibility.</p>
            <HorizontalLineSimple/>

            <br />
            <br />
            <p>By setting meetings through Introductable, users acknowledge their acceptance of these terms and agree to abide by them in their entirety. Introductable reserves the right to update or modify these terms at any time, with changes communicated to users through appropriate channels.</p>
            <HorizontalLineSimple/>

            <br />
            <br />
            <p>By using Introductable, you agree to comply with all applicable laws and regulations regarding the recording of meetings facilitated through our platform. Users are solely responsible for ensuring that any recording activities adhere to legal requirements, including obtaining necessary consents from participants.</p>
            <HorizontalLineSimple/>

            <br />
            <br />
            <p>Introductable shall not be held liable for any violations of meeting recording laws by users. By using our platform, you agree to indemnify and hold Introductable harmless against any claims, damages, or liabilities arising from your use of meeting recordings.</p>
            <HorizontalLineSimple/>

            <br />
            <br />
            <p>It is the responsibility of each user to understand and abide by local, state, and federal laws pertaining to meeting recordings. If you have any questions or concerns about what is your laws allow, please contact your attorney.</p>
            <HorizontalLineSimple/>

        </Container>
        <FooterAddition />
        </>
    );
};

export default TermsConditionsComponent;