import React from 'react';
import './VideoComponent.css';
import { Col, Container, Row } from 'react-bootstrap';
import videoImage from '../../../content/landing-page/video-image.png';
import { VideoComponentProps } from '../types';


const VideoComponent: React.FC<VideoComponentProps> = ({videoUrl}) => {

    return (
        <Container className='video-component-styled-text'>
            <Row style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px"}}>
            <Row>
                <h5 className="main-color video-component-text-margin" style={{fontFamily: 'Raleway'}}>Why Choose Us</h5>
            </Row>
            <Row>
                <Col><h2 className='video-component-text-margin video-component-text-title'>Process To <span className="main-color">Find Right Person</span></h2></Col>
            </Row>
            <Row className='video-component-text-margin' style={{ marginTop: '20px' }}>
                <p>
                    Introductable empowers businesses to unlock their full potential through efficient and effective networking while giving individuals 
                    the opportunity to be financially rewarded for making real connections happen.
                </p>
            </Row>
            <Row style={{ marginTop: '15px' }}>
                <a href={`${videoUrl}`} target='_blank' rel="noreferrer">
                    <img src={videoImage} className='video-component-image' alt="videoImage" />
                </a>
            </Row>
            </Row>
        </Container>
    );
    }

export default VideoComponent;
