import React from 'react';
import './BoxedButton.css';
import { BoxedButtonProps } from '../types';
import { Container, Row } from 'react-bootstrap';
import StyledActionButton from '../../atoms/button/StyledActionButton';
import StyledLabel from '../../atoms/label/StyledLabel';


const BoxedButton: React.FC<BoxedButtonProps> = ({ title, buttonLabel, onClick }) => {
  return (
    <Container className='boxed-button'>
        <Row style={{ paddingTop: "20px", paddingLeft: "20px", paddingRight: "20px", paddingBottom: "20px"}}>
            <StyledLabel align='center' label={title} />
        </Row>
        <Row style={{ marginLeft: "20%", marginRight: "20%", paddingBottom: "20px"}}>
            <StyledActionButton label={buttonLabel} onClick={onClick}></StyledActionButton>
        </Row>
    </Container>
  );
}

export default BoxedButton;
