import React from 'react';
import './PowerOfWarmIntroductionsComponent.css';
import '../../../Main.css';
import logoCircled from '../../../content/logo/logo_circle.svg';
import person from '../../../content/landing-page/warm_introduction.jpeg';
import { Container } from 'react-bootstrap';


const PowerOfWarmIntroductionsComponent: React.FC = () => {

        return (
            <Container className='power-introductions-component-wrapper'>
                <div><img src={logoCircled} className='power-introductions-component-logo-circled' alt="logoCircled" /></div>
                <div className='power-introductions-component-rectangle'>
                    <h4 style={{color: 'white'}}>The Power of</h4>
                    <h4 style={{color: 'white'}}>Warm Introductions</h4>
                    <p style={{color: 'white', marginTop: '15px'}}>
                    Introductable connects those seeking to generate business meetings or demos with those who have a close relationship with the meeting target. 
                    These warm introductions immediately establish a higher level of trust and credibility than traditional cold outreach methods.
                    </p>
                </div>
                <div><img src={person} className='power-introductions-component-person-image' alt="person" /></div>
            </Container>
        );
    }

export default PowerOfWarmIntroductionsComponent;
