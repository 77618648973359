import {useState, FC, createContext, useContext, useEffect} from 'react';
import Main from './main';
import Menu from './components/menu/Menu';
import { BrowserRouter as Router } from 'react-router-dom';
import { User, UserReason } from './types/user';
import './App.css';
import setupAxiosInterceptors from './api/axiosConfig';
import Footer from './components/footer/Footer';

export type AuthContent = {
    user: User
    setUser:(user: User) => void
    token: string
    setToken:(token: string) => void
}

export const AuthContext = createContext<AuthContent>({
    user: { email: '', personId: undefined, userReason: UserReason.LOOK_AROUND, personName: '', personLastName: ''},
    setUser: () => {},
    token: '',
    setToken: () => {}
});

export const useAuthContext = () => useContext(AuthContext)

const getToken = () => localStorage.getItem('jwtToken');
const handleTokenExpired = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('jwtToken');
    window.location.href = '/signin';
};

setupAxiosInterceptors(getToken, handleTokenExpired);

const App: FC = () => {
    const [user, setUser] = useState<User>(() => {
        const savedUser = localStorage.getItem('user');
        return savedUser ? JSON.parse(savedUser) : { userId: 0, email: '', personId: undefined, userReason: UserReason.LOOK_AROUND, timezone: undefined, personName: '', personLastName: ''};
    });

    const [token, setToken] = useState<string>(() => {
        const savedToken = localStorage.getItem('jwtToken');
        return savedToken || '';
    });
    
    useEffect(() => {
        localStorage.setItem('user', JSON.stringify(user));
    }, [user]);

    useEffect(() => {
        localStorage.setItem('jwtToken', token);
    }, [token]);

    return (
        <div className="site-wrapper">
            <AuthContext.Provider value = {{user, setUser, token, setToken}}>
                    <Router>
                        <Menu />
                        <div className="content-wrap">
                            <Main />
                        </div>
                        <Footer />
                    </Router>
            </AuthContext.Provider>
        </div>
    );
}

export default App;
