import React, { useRef, useState } from 'react';
import { Col, Container, FormControl, Row } from 'react-bootstrap';
import './SearchTiny.css';
import { SlMagnifier } from "react-icons/sl";


export interface SearchBarProps {
    onSearch: (searchPhrase: string) => void;
    searchText?: string;
}

const SearchTiny: React.FC<SearchBarProps> = ({ onSearch, searchText = '' }) => {
    const [searchTerm, setSearchTerm] = useState<string>(searchText);
    const inputRef = useRef<HTMLInputElement>(null);

    const handleSearchClick = () => {
        onSearch(searchTerm);
        setSearchTerm('');
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if ( e.key === 'Enter') {
            handleSearchClick();
        }
    };

    const handleContainerClick = () => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      };

    return (
        <Container fluid className="search-light-container" onClick={handleContainerClick}>
          <Row style={{minWidth: '100%'}}>
            <Col className="tiny-search-icon-column">
              <SlMagnifier />
            </Col>
            <Col className="tiny-search-input-column">
              <FormControl
                ref={inputRef}
                className="tiny-search-input"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                type="text"
                placeholder="Search for meetings..."
                onKeyDown={handleKeyDown}
              />
            </Col>
          </Row>
        </Container>
      );
};

export default SearchTiny;
