import React, { useState } from 'react';
import { Meeting } from '../../types/meeting';
import PendingRequestedForm from './actions/forms/PendingRequestedForm';
import PendingIntroductorAddedForm from './actions/forms/PendingIntroductorAddedForm';
import PendingMeetingConfirmedForm from './actions/forms/PendingMeetingConfirmedForm';
import PendingMeetingPaidForm from './actions/forms/PendingMeetingPaid';
import SuccessNoReviewForm from './actions/forms/SuccessNoReviewForm';
import { MeetingSubstatusEnum } from '../../types/meetingStatus';
import PendingMeetingPaidNewProposalsForm from './actions/forms/PendingMeetingPaidNewProposals';
import PendingDetailsAddedForm from './actions/forms/PendingDetailsAddedForm';
import { useAuthContext } from '../../App';
import PendingAllGoodForm from './actions/forms/PendingAllGoodForm';
import PendingAllGoodDetailsAddedForm from './actions/forms/PendingAllGoodDetailsAddedForm';
import PendingAllGoodRequestorNewTimesForm from './actions/forms/PendingAllGoodRequestorNewTimesForm';
import PendingMeetingRescheduleNeededForm from './actions/forms/PendingMeetingRescheduleNeededForm';
import PendingAllGoodRequestorRescheduleForm from './actions/forms/PendingAllGoodRequestorRescheduleForm';

export type MeetingActionFormProps = {
    onSubmit: () => void;
    meeting: Meeting;
};

const MeetingActionForm: React.FC<MeetingActionFormProps> = ({ onSubmit, meeting }) => {
    const { user } = useAuthContext();
    const [isIntroductor] = useState<boolean>(user.personId === meeting.introductor?.personId);
    let renderedForm;

    switch(meeting.meetingSubstatus) {
        case MeetingSubstatusEnum.PENDING_MEETING_REQUESTED:
            renderedForm = <PendingRequestedForm meeting={meeting} onSubmit={onSubmit} />
            break;
        case MeetingSubstatusEnum.PENDING_INTRODUCTOR_ADDED:
            renderedForm = !isIntroductor ? <PendingIntroductorAddedForm meeting={meeting} onSubmit={onSubmit} /> : <PendingAllGoodForm isIntroductor={isIntroductor} meeting={meeting} onSubmit={onSubmit}/>
            break;
        case MeetingSubstatusEnum.PENDING_MEETING_PAID:
            renderedForm = !isIntroductor ? <PendingMeetingPaidForm meeting={meeting} onSubmit={onSubmit} /> : <PendingAllGoodForm isIntroductor={isIntroductor} meeting={meeting} onSubmit={onSubmit}/>
            break;
        case MeetingSubstatusEnum.PENDING_DETAILS_ADDED:
            renderedForm = isIntroductor ? <PendingDetailsAddedForm meeting={meeting} onSubmit={onSubmit} /> : <PendingAllGoodDetailsAddedForm isIntroductor={isIntroductor} meeting={meeting} onSubmit={onSubmit}/>
            break;
        case MeetingSubstatusEnum.PENDING_MEETING_CONFIRMED:
            renderedForm = <PendingMeetingConfirmedForm meeting={meeting} onSubmit={onSubmit} />
            break;
        case MeetingSubstatusEnum.PENDING_MEETING_PAID_NEW_PROPOSAL:
            renderedForm = !isIntroductor ? <PendingMeetingPaidNewProposalsForm meeting={meeting} onSubmit={onSubmit} /> : <PendingAllGoodForm isIntroductor={isIntroductor} meeting={meeting} onSubmit={onSubmit}/>
            break;
        case MeetingSubstatusEnum.PENDING_DETAILS_ADDED_NEW_PROPOSAL:
            renderedForm = isIntroductor ? <PendingDetailsAddedForm meeting={meeting} onSubmit={onSubmit} /> : <PendingAllGoodRequestorNewTimesForm isIntroductor={isIntroductor} meeting={meeting} onSubmit={onSubmit}/>
            break;
        case MeetingSubstatusEnum.PENDING_MEETING_RESCHEDULE_NEEDED:
            renderedForm = isIntroductor ? <PendingMeetingRescheduleNeededForm meeting={meeting} onSubmit={onSubmit} /> : <PendingAllGoodRequestorRescheduleForm />
            break;
        case MeetingSubstatusEnum.SUCCESS_NO_REVIEW:
            renderedForm = !isIntroductor ? <SuccessNoReviewForm meeting={meeting} onSubmit={onSubmit} /> : <PendingAllGoodForm isIntroductor={isIntroductor} meeting={meeting} onSubmit={onSubmit}/>
            break;
        default:
            renderedForm = <p>No suitable form found.</p>
    }

    return (
        <>
            {renderedForm}
        </>
    )
}

export default MeetingActionForm;
