import React, { ChangeEvent, useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { confirmNewTimeProposals, getMeetingTimeProposals } from '../../../../api/meetingApi';
import { ActionFormProps } from './ActionFormTypes';
import '../../../../Main.css';
import { TimeProposal } from '../../../../types/meeting';
import TimeProposalComponent from '../../timeproposal/TimeProposalComponent';
import StyledActionButton from '../../../atoms/button/StyledActionButton';
import StyledCheckbox from '../../../atoms/checkbox/StyledCheckbox';
import TimeProposedComponent from '../../timeproposal/TimeProposedComponent';

const PendingMeetingPaidNewProposalsForm: React.FC<ActionFormProps> = ({ meeting, onSubmit }) => {
    const [timeProposals, setTimeProposals] = useState<TimeProposal[]>([]);
    const [introductorTimeProposals, setIntroductorTimeProposals] = useState<TimeProposal[]>([]);

    useEffect(() => {
        if (meeting.meetingId) {
            getMeetingTimeProposals(meeting.meetingId)
                .then(response => {                    
                    const newProposals = response.data.filter(timeProposal => timeProposal.proposerId === meeting.introductor?.personId);
                    setIntroductorTimeProposals(newProposals);  
            })
        }
    }, [meeting, meeting.meetingId]);

    const submitNewTimes = () => {
        if (meeting.meetingId) {
            confirmNewTimeProposals(meeting.meetingId, timeProposals, false).then(
                () => {
                    onSubmit();    
                }
            );
        }
    };

    const handleTimeProposalChange = (newProposals: TimeProposal[]) => {
        setTimeProposals(newProposals);
    };

    return (
        <>
        <Container>
            <Row>
                <h4 style={{fontFamily: 'Raleway'}}>Uh Oh! No Proposed Times Work</h4>
                <p>
                    None of your proposed times worked for your Meeting Target. 
                    Introductor is proposing new times. Pick a time! Please select from options below and fill in dates and times below. 
                    (FYI chat can be used to discuss times and issues, too!)
                </p>
            </Row>
            {
                introductorTimeProposals.length > 0 && (
                <Row style={{marginTop: '20px'}}>
                    <TimeProposedComponent 
                        readOnlyTimeProposals={introductorTimeProposals}
                        title={'Introductor Proposed Dates and Times'}            
                    />
                </Row>)
            }
            <Row style={{marginTop: '20px'}}>
                <TimeProposalComponent 
                    timeProposalsProp={timeProposals}
                    onTimeProposalsChange={handleTimeProposalChange} 
                    title={<p><span style={{fontWeight: '600', fontSize: '20px'}}>Availability </span>(The more times you suggest, the better chance that meeting will occur)</p>}
                    timeFromOnly={true}
                />
            </Row>
            <Row style={{marginTop: '20px'}}>
                <StyledActionButton label={'Confirm date time'} onClick={submitNewTimes} />
            </Row>
        </Container>
        </>
    );
}

export default PendingMeetingPaidNewProposalsForm;
