import axios, { AxiosResponse } from 'axios';
import { Connection, ConnectionDetailsResponse, ConnectionListItemResponse, ConnectionRequest, ConnectionWithExpertRequest, ExpertIntroductorsResponse } from '../types/connection';
import { Relation } from '../types/relation';
import { Person } from '../types/person';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/v1/connection`;

export async function addConnection(connectionRequest: ConnectionRequest): Promise<AxiosResponse<Connection>> {
  return await axios.post(`${API_BASE_URL}`, connectionRequest);
}

export async function addConnectionWithExpert(connectionRequest: ConnectionWithExpertRequest): Promise<AxiosResponse<ConnectionDetailsResponse>> {
  return await axios.post(`${API_BASE_URL}/with-new-expert`, connectionRequest);
}

export async function updateConnection(connectionId: string, connectionRequest: ConnectionWithExpertRequest): Promise<AxiosResponse<ConnectionDetailsResponse>> {
  return await axios.put(`${API_BASE_URL}/${connectionId}/update`, connectionRequest);
}

export async function addRelationsToConnection(id: string, relations: Relation[]): Promise<AxiosResponse<Connection>> {
  return await axios.post(`${API_BASE_URL}/${id}/relations`, relations);
}

export async function getConnectionById(id: string): Promise<AxiosResponse<ConnectionDetailsResponse>> {
  return await axios.get(`${API_BASE_URL}/${id}`);
}

export async function getAllExperts(): Promise<AxiosResponse<Person[]>> {
  return await axios.get(`${API_BASE_URL}/experts`);
}

export async function getAllExpertIntroductors(expertId: string): Promise<AxiosResponse<ExpertIntroductorsResponse[]>> {
  return await axios.get(`${API_BASE_URL}/experts/${expertId}/introductors`);
}

export async function getAllIntroductorExperts(introductorId: string): Promise<AxiosResponse<ConnectionListItemResponse[]>> {
  return await axios.get(`${API_BASE_URL}/introductors/${introductorId}/experts`);
}
