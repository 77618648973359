import blog1Image from '../../content/blog/newsitems/blog1.png';
import blog2Image from '../../content/blog/newsitems/blog2.png';
import blog3Image from '../../content/blog/newsitems/blog3.png';
import blog4Image from '../../content/blog/newsitems/blog4.png';
import blog5Image from '../../content/blog/newsitems/blog5.png';
import blog6Image from '../../content/blog/newsitems/blog6.png';
import blog7Image from '../../content/blog/newsitems/blog7.png';
import blog8Image from '../../content/blog/newsitems/blog8.png';
import blog9Image from '../../content/blog/newsitems/blog9.png';
import blog10Image from '../../content/blog/newsitems/blog10.png';
import blog12Image from '../../content/blog/newsitems/blog12.png';
import blogGdImage from '../../content/blog/greatdaytoleavemealone/great_day_to_leave_me_alone.png';
import blogssImage from '../../content/blog/summerslowdown/summer_slowdown.png';
import blogmdsImage from '../../content/blog/businessmeetingdeathstars/business_meetings_death_stars.png';
import blogmcmsImage from '../../content/blog/cultivatingmarketingsuccess/CultivatingMarketingSuccessImage.png';
import blogogImage from '../../content/blog/olympicgold/OlympicGoldImage.png';
import blogwtdImage from '../../content/blog/whenturningdown/WhenTurningDownImage.png';
import bloghtgwiImage from '../../content/blog/howtogetwarm/howToGetWarm.png';
import blognalImage from '../../content/blog/notalead/notaleadimage.png';
import blogslctImage from '../../content/blog/saleslifecycle/tree.jpg';
import blogsamaccImage from '../../content/blog/coldcallmonday/cold-call.jpg';
import blogttrImage from '../../content/blog/toptenreasons/top_ten_reasons.png';
import blogasImage from '../../content/blog/agencyshowdown/agencyShowdownImage.png';
import blogrhImage from '../../content/blog/ruthlesshonesty/ruthlessHonetyImage.png';
import blogfssImage from '../../content/blog/fbistrategiessales/fbiStrategiesSales.png';
import bloglgtImage from '../../content/blog/leadgeneration/LeadGenerationTypesImage.png';
import blogsgpImage from '../../content/blog/sharedgoalsprospect/SharedGoalsProspects.png';
import blogttrpImage from '../../content/blog/toptenreachprospects/toptenreachprospects.png';
import blogbbImage from '../../content/blog/beyondbonus/beyondbonusimage.jpg';
import blogrhcImage from '../../content/blog/ratinghaloweencandy/RatingHaloweenCandyImage.png';
import blogdacdImage from '../../content/blog/diagnoseandcuredeal/DiagnoseAndCureDeal.png';
import blogbtnImage from '../../content/blog/breakthroughthenoise/BreakthroughTheNoiseImage.png';

export const NewsBlogItems = [
    {
        title: 'Breakthrough the Noise: Land Meetings with Hard-to-Reach Prospects',
        publishDate: '8 November 2024',
        description: `In today's hyper-competitive sales landscape, getting face time with key decision-makers has never been more challenging.`,
        image: blogbtnImage,
        blogLink: 'https://introductable.com/blog/breakthrough-the-noise'
    },{
        title: 'Diagnose and Cure Deal Stalls',
        publishDate: '5 November 2024',
        description: `It’s just how it is. How we look at where and why deals stall is often neglected.`,
        image: blogdacdImage,
        blogLink: 'https://introductable.com/blog/diagnose-and-cure-deal'
    },
    {
        title: 'Rating Halloween Candy: Do you want to be the cool house or the house that gets egged?',
        publishDate: '1 November 2024',
        description: `And yeah, I know Halloween was yesterday. This article isn’t a day late, it’s a year early.`,
        image: blogrhcImage,
        blogLink: 'https://introductable.com/blog/rating-haloween-candy'
    },
    {
        title: 'Beyond the Bonus: Your Guide to a Happier, More Productive Sales Force',
        publishDate: '30 October 2024',
        description: `Sales teams are the backbone of revenue generation in any organization, and keeping them motivated, happy, and focused is essential for sustained success.`,
        image: blogbbImage,
        blogLink: 'https://introductable.com/blog/beyond-bonus'
    },
    {
        title: 'Top 10 Reasons Why Introductable Can Help You Reach Key High-Value Prospects',
        publishDate: '25 October 2024',
        description: `By focusing on trust-based, relationship-driven introductions, Introductable effectively solves the key challenges sales professionals...`,
        image: blogttrpImage,
        blogLink: 'https://introductable.com/blog/top-ten-reach-prospects'
    },
    {
        title: 'How to Develop Shared Goals With Your Prospects',
        publishDate: '18 October 2024',
        description: `In sales, establishing a genuine connection with prospects is key component...`,
        image: blogsgpImage,
        blogLink: 'https://introductable.com/blog/shared-goals-prospect'
    },
    {
        title: 'Lead Generation Types and Statistics: How to Feed the Funnel',
        publishDate: '11 October 2024',
        description: `In today's fiercely competitive business landscape, the ability to consistently attract and convert potential customers is the lifeblood of any successful enterprise...`,
        image: bloglgtImage,
        blogLink: 'https://introductable.com/blog/lead-generation-types'
    },
    {
        title: 'From Standoff to Sales Call: FBI Strategies for Building Trust',
        publishDate: '08 October 2024',
        description: `How to Make a Connection and Leave a Favorable Impression with a Sales Prospect on an Initial Call (Inspired by FBI Negotiation Tactics)`,
        image: blogfssImage,
        blogLink: 'https://introductable.com/blog/fbi-strategies-sales'
    },
    {
        title: 'The Need for Ruthless Honesty in Lead Evaluation',
        publishDate: '04 October 2024',
        description: `While it may be tempting to view every lead as a potential goldmine...`,
        image: blogrhImage,
        blogLink: 'https://introductable.com/blog/need-the-ruthless-honesty'
    },
    {
        title: 'Agency Showdown! PFP vs Traditional, Who Ya Got?',
        publishDate: '27 September 2024',
        description: `At the end of the day, both PFP and Traditional agencies have their strengths...`,
        image: blogasImage,
        blogLink: 'https://introductable.com/blog/agency-showdown'
    },
    {
        title: 'Top Ten Reasons Introductable Speeds Up The Sales Process',
        publishDate: '16 September 2024',
        description: `Introductable decreases time to close by 40% over traditional marketing channels.`,
        image: blogttrImage,
        blogLink: 'https://introductable.com/blog/top-ten-reasons'
    },
    {
        title: 'Another Monday, Another Cold Call or A Salesman Who\'s Seen Too Much',
        publishDate: '12 September 2024',
        description: `The rain had been beating down on the city since the early hours, a rhythmic, mocking drumming against the office windows`,
        image: blogsamaccImage,
        blogLink: 'https://introductable.com/blog/cold-call-monday'
    },
    {
        title: 'Sales Process vs. The Life Cycle of a Tree',
        publishDate: '4 September 2024',
        description: `Joyce Kilmer wrote, “I think that I shall never see A poem lovely as a tree.”`,
        image: blogslctImage,
        blogLink: 'https://introductable.com/blog/sales-life-cycle-tree'
    },
    {
        title: 'THAT\'S NOT A LEAD!!!',
        publishDate: '28 August 2024',
        description: `Or, Buying A Scraped List is just Buying An Expensive Phone Book...`,
        image: blognalImage,
        blogLink: 'https://introductable.com/blog/not-a-lead'
    },
    {
        title: 'How to Get Warm Introductions',
        publishDate: '21 August 2024',
        description: `You already know that warm introductions instantly establish credibility and trust...`,
        image: bloghtgwiImage,
        blogLink: 'https://introductable.com/blog/how-to-get-warm-introductions'
    },
    {
        title: 'Mondays and Cold Outreach',
        publishDate: '14 August 2024',
        description: `We've all been there. The alarm blares on Monday morning, and suddenly you're longing...`,
        image: blog10Image,
        blogLink: 'https://introductable.com/blog/mondays-cold-outreach'
    },
    {
        title: 'Olympic Gold Or Olympic Last?',
        publishDate: '11 August 2024',
        description: `Carl Lewis won Olympic gold in 1988, earning him the title of the world’s fastest man...`,
        image: blogogImage,
        blogLink: 'https://introductable.com/blog/olympic-gold-or-olympic-last'
    },
    {
        title: 'When Turning Down Payment is the Right Move',
        publishDate: '04 August 2024',
        description: `In the world of business, it's rare to hear of a company turning down payment for services rendered.`,
        image: blogwtdImage,
        blogLink: 'https://introductable.com/blog/when-turning-down-payment'
    },
    {
        title: 'Cultivating Marketing Success: How Do You Till the Business Landscape?',
        publishDate: '31 July 2024',
        description: `In the vast fields of the business world, many have become accustomed to, and dependent on...`,
        image: blogmcmsImage,
        blogLink: 'https://introductable.com/blog/cultivating-marketing-success'
    },
    {
        title: 'Business Meetings and Death Stars',
        publishDate: '25 July 2024',
        description: `Comparing a business networking platform to a galaxy far, far away? Blasphemy!`,
        image: blogmdsImage,
        blogLink: 'https://introductable.com/blog/business-meetings-death-stars'
    },
    {
        title: 'Funnel Vision: Techniques to Amplify Your Lead Generation',
        publishDate: '20 July 2024',
        description: `No one needs to tell you that filling the sales funnel with high-quality leads is more crucial than ever.`,
        image: blog1Image,
        blogLink: 'https://introductable.com/blog/funnel-vision'
    },
    {
        title: '50 Shades of Nay: How Cold Calling Is Somehow Worse Than Tinder',
        publishDate: '17 July 2024',
        description: `Would you rather spend your day getting rejected by strangers over the phone or getting rejected by strangers on your phone?`,
        image: blog12Image,
        blogLink: 'https://introductable.com/blog/fifty-shades-of-nay'
    },
    {
        title: 'Summer Slowdown: How Vacation Season Impacts B2B Sales and Marketing',
        publishDate: '14 July 2024',
        description: `As the temperature rises, many B2B sales and marketing professionals find their outreach efforts cooling off.`,
        image: blogssImage,
        blogLink: 'https://introductable.com/blog/summer-slowdown'
    },
    {
        title: 'Top Ten Leave Me Alone Email Responders',
        publishDate: '09 July 2024',
        description: `As I bask in the glow of my vacation, far from the ping of incoming emails and the buzz of meeting reminders...`,
        image: blogGdImage,
        blogLink: 'https://introductable.com/blog/great-day-to-leave-me-alone'
    },
    {
        title: 'Confession: I\'m a CEO Who Lurks',
        publishDate: '04 July 2024',
        description: `I'm often lurking in marketing subreddits and LinkedIn groups trying to keep my finger on the zeitgeist of marketing...`,
        image: blog2Image,
        blogLink: 'https://introductable.com/blog/confession-ceo-who-lurks'
    },
    {
        title: 'The Tragic Comedy of Cold Outreach; Let’s Commiserate Together',
        publishDate: '27 June 2024',
        description: `Picture this: It's another glorious Monday morning. You've armed yourself with a triple-shot espresso and the unwavering belief that today, yes today, your cold outreach efforts will finally pay off.`,
        image: blog9Image,
        blogLink: 'https://introductable.com/blog/tragic-comedy-of-cold-outreach'
    },
    {
        title: 'Top 10 Reasons Why Cold Outreach Makes Us Want to Crawl Under a Rock',
        publishDate: '22 June 2024',
        description: `Nothing says "I love you" like a copy-pasted message to 10,000 strangers`,
        image: blog8Image,
        blogLink: 'https://introductable.com/blog/ten-reasons-cold-outreach'
    },
    {
        title: 'Use “Relationship Insurance” to Shorten the Sales Cycle',
        publishDate: '10 June 2024',
        description: `One of the best ways to accelerate sales cycles is by injecting a trusted source or referral as early into the process as possible.`,
        image: blog7Image,
        blogLink: 'https://introductable.com/blog/relationship-insurance'
    },
    {
        title: 'The Power of Warm Introductions',
        publishDate: '20 May 2024',
        description: `In today's hyper-connected world, building valuable relationships is pivotal to personal and professional success.`,
        image: blog1Image,
        blogLink: 'https://introductable.com/blog/power-of-warm-introductions'
    },
    {
        title: 'AI at Introductable',
        publishDate: '13 May 2024',
        description: `Introductable leverages artificial intelligence (AI) technology to revolutionize the way professionals connect and network.`,
        image: blog6Image,
        blogLink: 'https://introductable.com/blog/ai-at-introductable'
    },
    {
        title: '“Sometimes to have a revolution, you need to go back to the past.”',
        publishDate: '06 May 2024',
        description: `Networking used to be done in person or by a friend or colleague making an introduction. It worked. Why? Trusted relationships.`,
        image: blog3Image,
        blogLink: 'https://introductable.com/blog/rehumanizing-network'
    },
    {
        title: 'Introductable is great for companies with ongoing demand generation needs!',
        publishDate: '29 April 2024',
        description: `Leveraging a trusted third party through Introducable provides access to a vast network of qualified leads and potential clients.`,
        image: blog4Image,
        blogLink: 'https://introductable.com/blog/lead-generation'
    },
    {
        title: 'The Value of Trusted Introductions',
        publishDate: '22 April 2024',
        description: `At Introductable, we've 're-revolutionized' the way professionals connect and generate demand for their businesses by focusing on the power of trusted relationships`,
        image: blog5Image,
        blogLink: 'https://introductable.com/blog/value-of-trusted-introductions'
    },
    {
        title: 'Explore the Origin and Inspiration Behind Our Company',
        publishDate: '15 April 2024',
        description: `Meet Our Founders`,
        image: blog2Image,
        blogLink: 'https://introductable.com/meet-founders'
    }
];
