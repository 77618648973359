import React from "react";
import './Footer.css';
import { FaLinkedinIn, FaInstagram, FaFacebookF } from 'react-icons/fa';
import { FaRegCopyright } from "react-icons/fa6";
import { FaXTwitter } from 'react-icons/fa6';
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";

const Footer: React.FC = () => {
    return (
        <Container fluid className="footer-wrapper">
            <Row className="footer-elements-position">
                <Col xs={12} md="auto">
                    <FaRegCopyright style={{ fontSize: '16px'}}/> {new Date().getFullYear()} Introductable. All rights reserved.
                </Col>
                <Col>
                    <div className="footer-elements-second">
                        <Link to="/privacy-policy" className="link-footer mx-2">Privacy</Link>
                        <div>|</div>
                        <Link to="/terms-conditions" className="link-footer mx-2">Terms & Conditions</Link>
                        <a href="https://www.linkedin.com/company/introductable-com/about/" target="_blank" rel="noopener noreferrer" className="mx-1 social-icons">
                            <FaLinkedinIn />
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=61556334233580" target="_blank" rel="noopener noreferrer" className="mx-1 social-icons">
                            <FaFacebookF />
                        </a>
                        <a href="https://www.instagram.com/intro.ductable" target="_blank" rel="noopener noreferrer" className="mx-1 social-icons">
                            <FaInstagram />
                        </a>
                        <a href="https://twitter.com/Introductable" target="_blank" rel="noopener noreferrer" className="mx-1 social-icons">
                            <FaXTwitter /> 
                        </a>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default Footer;
