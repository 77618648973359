import { Col, Container, Form, Row } from "react-bootstrap";
import { ChangeEvent, FormEvent, useState } from "react";
import StyledInput from "../atoms/input/StyledInput";
import loginPicture from '../../content/login/login_scaled.jpeg';
import StyledActionButton from "../atoms/button/StyledActionButton";
import { changePasswordForUser } from "../../api/authApi";
import { useNavigate, useSearchParams } from "react-router-dom";

const ChangePasswordComponent: React.FC = () => {
    const navigate = useNavigate();
    const [errors, setErrors] = useState({password: ''});
    const [searchParams] = useSearchParams();
    const token = searchParams.get('token');
    const [resetPasswordSent, setResetPasswordSent] = useState<boolean>(false);
    const [changePassword, setChangePassword] = useState<string>('');
    const [confirmPassword, setConfirmPassword] = useState<string>('');

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setResetPasswordSent(true);
        if (token !== null){
            changePasswordForUser(changePassword, token)
            .then(() => {
                navigate('/signin');
            }).catch(
                
            );
        }
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let newErrors = { ...errors };
        
        if (name === "changePassword") {
            if (!isValidPassword(value)) {
                newErrors.password = "Password must be at least 8 characters long, include a number, an uppercase and a lowercase letter.";
            } else {
                newErrors.password = ''; 
            }
            setChangePassword(e.target.value);
        }

        setErrors(newErrors);
    };

    const isValidPassword = (password: string) => {
        const minLength = 8;
        const hasNumber = /[0-9]/.test(password);
        const hasUpper = /[A-Z]/.test(password);
        const hasLower = /[a-z]/.test(password);
    
        return password.length >= minLength && hasNumber && hasUpper && hasLower;
    };

    const passwordMatch = changePassword === confirmPassword;

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={4} style={{ padding: 0 }}>
                        <img src={loginPicture} className='login-image' alt="Login" />
                    </Col>
                    <Col md={8} style={{ paddingLeft: "10%", paddingRight: "10%", paddingTop: "10%" }}>
                        <Row style={{paddingTop: "34px"}}>
                            <Row>
                                <h4>Change Your <span className="main-color">Password</span></h4>
                            </Row>
                        </Row>
                        <Row style={{ paddingTop: "18px" }}>
                            {
                                resetPasswordSent ? (
                                    <p>
                                        Redirecting...
                                    </p>
                                ) : (
                                    <Form onSubmit={handleSubmit}>
                                        <Row style={{ paddingTop: "18px" }}>
                                            <Form.Group>
                                                <StyledInput 
                                                    type="password"
                                                    name="changePassword"
                                                    value={changePassword || ''}
                                                    onChange={handleChange}
                                                    required
                                                    placeholder='Password'
                                                />
                                                {errors.password.length > 0 && <div style={{ color: 'red' }}>{errors.password}</div>}
                                            </Form.Group>
                                        </Row>
                                        <Row style={{ paddingTop: "18px" }}>
                                        <Form.Group>
                                                <StyledInput 
                                                    type="password"
                                                    name="confirmPassword"
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                    required
                                                    placeholder='Confirm Password'
                                                />
                                                {confirmPassword != '' && !passwordMatch && <div style={{ color: 'red' }}>Passwords do not match</div>}
                                            </Form.Group>
                                        </Row>
                                        <Form.Group style={{ paddingTop: "18px" }}>
                                            <StyledActionButton label={'Reset Your Password'} type="submit" />
                                        </Form.Group>
                                    </Form>
                                )
                            }
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ChangePasswordComponent;
