import React from 'react';
import './MondaysAndColdOutreachPage.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';

import peopleTalk from '../../../content/blog/people-talking_scaled.jpeg'
import FooterAddition from '../../footer/FooterAddition';
import StyledLink from '../../atoms/link/StyledLink';
import { GoDotFill } from 'react-icons/go';

const MondaysAndColdOutreachPage: React.FC = () => {
    return (
        <>
        <Container fluid className='justify-content-center'>
            <Row>
                    <img src={peopleTalk} className="mondays-cold-outreach-title-image" alt="peopleTalk" />
            </Row>
            <Row style={{paddingLeft: "20px", paddingTop: "50px", paddingRight: "20px", paddingBottom: "40px"}} className='justify-content-center'>
            <Row>
                <Col className='mondays-cold-outreach-title'><h2><span className="main-color">Mondays and Cold Outreach:</span> A Match Made in Misery (And How to Break Free)</h2></Col>
            </Row>
            <Row className='mondays-cold-outreach-styled-text' style={{ marginTop: '20px' }}>
                <p>
                    We've all been there. The alarm blares on Monday morning, and suddenly you're longing for the weekend that just slipped away. And what could possibly make Monday even worse? A new call sheet with little hope of actually landing a sales meeting. 
                    <br/><br/>
                    Just like Mondays, cold outreach has become a necessary evil in the business world. But why do we put ourselves through this torture? Let's break down the similarities:
                </p>
            </Row>
            <Row style={{ marginTop: '20px' }}>
                <h4>Inject Trusted Referrals Early</h4>
                <p>
                    One of the best ways to accelerate sales cycles is by injecting a trusted source or referral as early into the process as possible. 
                    Introductions through established professional relationships can tremendously accelerate sales cycles and prevent deals from stalling out. 
                    When initial meetings occur thanks to a warm referral from a trusted connection, 
                    prospects are 7x more likely to remain actively engaged throughout the entire process.
                </p>
            </Row>
            <Row>
                <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                    <GoDotFill className='main-color'/> <b>1. The Dread Factor:</b> Monday: "Ugh, another week of meetings and deadlines." Cold Outreach: "Great, another day of crafting emails no one will read."
                    <br/><br/>
                    <GoDotFill className='main-color'/> <b>2. The Success Rate:</b> Monday: How often do you actually leap out of bed, excited for the week ahead? Cold Outreach: Your chances of getting a response are about as high as winning the lottery. Twice.
                    <br/><br/>
                    <GoDotFill className='main-color'/> <b>3. The Energy Drain:</b> Monday: By lunchtime, you're already daydreaming about Friday. Cold Outreach: After sending your 50th unanswered email, you're ready to change careers.
                    <br/><br/>
                    <GoDotFill className='main-color'/> <b>4. The Repetitive Nature:</b> Monday: Same commute, same coffee, same grind. Cold Outreach: Same script, same silence, same disappointment.
                    <br/><br/>
                    <GoDotFill className='main-color'/> <b>5. The False Hope:</b> Monday: "Maybe this week will be different!" Cold Outreach: "Maybe this prospect will actually reply!"
                </p>
            </Row>
            <Row>
                <p>
                    But here's the good news: Just as we've found ways to make Mondays more bearable (hello, casual Mondays and office donuts), there's a solution to the cold outreach conundrum. Enter Introductable – the platform that's doing for sales what three-day weekends do for work-life balance.
                </p>
            </Row>
            <Row>
                <p>
                    Introductable is like that friend who knows everyone at the party. Instead of cold-calling strangers, you're getting warm introductions to decision-makers through mutual connections. It's the difference between awkwardly hovering at the edge of a networking event and being personally introduced to the CEO by a trusted colleague.
                </p>
            </Row>
            <Row>
                <p>
                    With Introductable:
                </p>
            </Row>
            <Row>
                <p>
                    <GoDotFill className='main-color'/> You say goodbye to the soul-crushing silence of ignored outreach.
                    <br/><br/>
                    <GoDotFill className='main-color'/> Your messages actually get read (and responded to!)
                    <br/><br/>
                    <GoDotFill className='main-color'/> You start conversations from a place of mutual trust and interest.
                    <br/><br/>
                    <GoDotFill className='main-color'/> Your Monday mornings might just become exciting again, filled with promising new connections.
                    <br/><br/>
                    <GoDotFill className='main-color'/> Pay only for meetings that get held.
                </p>
            </Row>
            <Row>
                <p>
                    So, while we can't eliminate Mondays (trust us, we've tried), we can certainly do better than relying solely on cold outreach. Embrace the Friday closing time that is Introductable, and transform your sales strategy from a case of the Mondays to a Friday afternoon happy hour – productive, enjoyable, and full of potential.
                </p>
            </Row>
            <Row>
                <p>
                    Remember, life's too short for bad Mondays and even worse cold outreach. It's time to introduce yourself to a better way of doing business.
                </p>
            </Row>
            <Row>
                <p>
                    <StyledLink href={'/signup'}><b>Create account here:</b></StyledLink> to find people able and willing to make introductions to your prospects.
                </p>
            </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
    }

export default MondaysAndColdOutreachPage;
