import React from 'react';
import { ImSpinner2 } from 'react-icons/im'; 
import './LoadingIndicator.css'; 

const LoadingIndicator: React.FC = () => {
    return (
        <div className="loading-container">
            <ImSpinner2 className="loading-spinner" />
            <p>Loading...</p>
        </div>
    );
};

export default LoadingIndicator;
