import { SelectOption } from "../components/atoms/types";

export class DateUtils {

    static formatDate(month: string, year: string): string {
        const paddedMonth = month.padStart(2, '0');
        const date = new Date(`${year}-${paddedMonth}-01T00:00:00Z`);

        return date.toISOString();
    }

    static getMonthFromDate(dateString: string): string {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return '';
        }
        const month = date.getMonth() + 1; 
        return month.toString().padStart(2, '0'); 
    }

    static getYearFromDate(dateString: string): string {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return '';
        }
        return date.getFullYear().toString();
    }

    static formatReadableDateTime(dateString: string): string {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return 'Invalid date';
        }
        const year = date.getFullYear().toString().slice(-2); 
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const period = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    
        return `${month}/${day}/${year} ${formattedHours}:${minutes} ${period}`;
    }    

    static formatReadableDate(dateString: string): string {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return 'Invalid date';
        }
        const year = date.getFullYear().toString().slice(-2); 
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');

        return `${month}/${day}/${year}`;
    }

    static formatReadableTime(dateString: string): string {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return 'Invalid date';
        }
        const hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const period = hours >= 12 ? 'pm' : 'am';
    
        const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    
        return `${formattedHours}:${minutes} ${period}`;
    }

    static nowIso(): string {
        const now = new Date();
        return now.toISOString();
    }

    static isValidDate = (dateString: string): boolean => {
        const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;
        if (!dateRegex.test(dateString)) return false;
    
        const [year, month, day] = dateString.split('-').map(Number);
        const date = new Date(year, month - 1, day);
        return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
    };
    

    static isValidTime = (timeString: string): boolean => {
        const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
        if (!timeRegex.test(timeString)) return false;
    
        const [hours, minutes] = timeString.split(':').map(Number);
        return hours >= 0 && hours <= 23 && minutes >= 0 && minutes <= 59;
    };
}

export type MonthYear = {
    month: string,
    year: string
}

const MONTHS_LIST = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
const MONTH_NAMES = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const FORMATTED_MONTHS_LIST: SelectOption[] = MONTHS_LIST.map((month, index) => {
    const monthName = MONTH_NAMES[index];
    return { value: month, label: monthName };
});


const currentYear = new Date().getFullYear();

export const FORMATTED_YEARS_LIST: SelectOption[] = Array.from({ length: 61 }, (_, i) => { 
    const year = (currentYear - i).toString(); 
    return { value: year, label: year };
});

export interface GeneratedTimeOption {
    value: string;
    label: string;
    nextOption?: GeneratedTimeOption;
}

export const generateTimeOptions = (): GeneratedTimeOption[] => {
    const options: GeneratedTimeOption[] = [];
    for (let hour = 7; hour < 21; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
            const hourValue = hour.toString().padStart(2, '0');
            const minuteValue = minute.toString().padStart(2, '0');
            const formattedHour = hour % 12 || 12;
            const period = hour >= 12 ? 'pm' : 'am';
            const formattedMinute = minute.toString().padStart(2, '0');
            const formattedTimeString = `${formattedHour}:${formattedMinute} ${period}`;
            const timeString = `${hourValue}:${minuteValue}`;
            options.push({ value: timeString, label: formattedTimeString });
        }
    }

    for (let i = 0; i < options.length - 1; i++) {
        options[i].nextOption = options[i + 1];
    }

    return options;
};

export enum TimeZone {
    UTC = "Coordinated Universal Time",
    EST = "Eastern Standard Time",
    PST = "Pacific Standard Time",
    MST = "Mountain Standard Time",
    CST = "Central Standard Time",
    IST = "India Standard Time",
    GMT = "Greenwich Mean Time",
    CET = "Central European Time",
    BST = "British Summer Time",
    CDT = "Central Daylight Time",
    EDT = "Eastern Daylight Time",
    PDT = "Pacific Daylight Time",
    JST = "Japan Standard Time"
}

export const TIMEZONES_SELECT_LIST: SelectOption[] = Object.entries(TimeZone).map(([abbreviation, fullName]) => {
    return { value: abbreviation, label: `${abbreviation} - ${fullName}` };
});