import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import StyledInput from '../atoms/input/StyledInput';
import StyledActionButton from '../atoms/button/StyledActionButton';
import './SearchBar.css';
import '../../Main.css';

export interface SearchBarProps {
    onSearch: (searchPhrase: string) => void;
    searchText?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch, searchText = '' }) => {
    const [searchTerm, setSearchTerm] = useState<string>(searchText);

    const handleSearchClick = () => {
        onSearch(searchTerm);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if ( e.key === 'Enter') {
            handleSearchClick();
        }
    };

    return (
        <Container className="search-bar-container">
            <Row style={{ paddingTop: "20px", paddingLeft: "20px"}}>
                <h4>I want to have a meeting with.</h4>
            </Row>
            <Row style={{paddingBottom: "25px"}}>
                <Col md={8} style={{paddingLeft: "20px"}}>
                    <StyledInput 
                        value={searchTerm} 
                        type={'text'} 
                        placeholder="Enter name, company name, job title..."
                        onChange={(e) => setSearchTerm(e.target.value)} 
                        onKeyDown={handleKeyDown}
                    />
                </Col>
                <Col md={4} className='search-bar-button'>
                    <StyledActionButton label={'Search'} onClick={handleSearchClick} />
                </Col>
            </Row>
        </Container>
    );
};

export default SearchBar;
