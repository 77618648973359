import React from 'react';
import './ColdCallMondayPage.css';
import '../../../Main.css';
import { Container, Row } from 'react-bootstrap';
import FooterAddition from '../../footer/FooterAddition';
import blogImage from '../../../content/blog/coldcallmonday/cold-call.jpg';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';


const ColdCallMondayPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
        <Container fluid>
            <Row className='cold-call-monday-wrapper'>
                <Row style={{marginTop: '30px'}}>
                    <h2 className='cold-call-monday-mobile-text' >Another Monday, <span className="main-color">Another Cold Call</span> or A Salesman Who's Seen Too Much</h2>
                </Row>
                <Row style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={blogImage} className="cold-call-monday-image" alt="coldCallMondayImage" />
                </Row>
                <Row style={{marginTop: '40px'}}>
                    <p>
                        The rain had been beating down on the city since the early hours, a rhythmic, mocking drumming against the office windows. Monday again. Of course, it was. Monday’s always had a way of creeping up on you when you least wanted it. Like the past catching up, it slinks in with cold fingers wrapping around your gut, reminding you of every failure you’ve tried to drown in coffee and missed quotas. 
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        I sat at my desk, the phone staring at me like an old enemy. I hadn’t touched the thing in days. And I could feel it, the weight of what was coming. Cold calling. I hated it. But it was the business, the life I'd chosen—or, if I'm honest, the life that had chosen me, like some back-alley deal you can’t walk away from. You know the type. 
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        I flicked open my CRM, seeing the names on the list, all of them just numbers now. Faceless prospects. Each one was just another door slammed in my face, another polite rejection that stung more than the ones where they hung up without a word. At least when they hung up, they were honest about what they thought of you.
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        My fingers hovered over the phone, but I didn’t dial. Not yet. What’s the point of rushing into rejection before your coffee's gone cold? Besides, I knew what the odds were. Slim. And getting slimmer with every passing week. Maybe I’d lost my touch. Or maybe the game had changed, and I hadn’t kept up. 
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        In the old days, you could hit the streets, shake hands, flash a smile, and close the deal before the ink had time to dry. Now? Now it’s all about tech and tools and systems. "Get more leads, close faster, automate your follow-ups," they tell you. Yeah, right. You know what they don’t tell you? They don’t tell you what it feels like to hear “no” a hundred times before lunch. 
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        But they weren’t wrong about one thing. If you didn’t have something to help you sort through the pile of names and numbers, you’d drown in it. That’s where *Introductable* came in. I’d heard of it, seen the name around. Some slick piece of software designed to help people like me actually get people to talk to me. Hell, friends of theirs would set the meeting up for me and maybe—God forbid—actually close deals. But even with the fancy tools, at the end of the day, we don’t have it and it’s still just you and the phone, staring each other down like two gunslingers waiting to see who flinches first. 
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        I sipped my coffee, already bitter and cold. It matched my mood. The kind of day where the sun could be shining, and you wouldn’t even notice because the cloud over your head follows you wherever you go.
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        "Time to make the first call," I muttered to myself, though no one else was around to hear it. The phone felt heavy in my hand, like the weight of a career built on slim hopes and false promises. I dialed the number, my fingers moving out of habit more than conviction.
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        The line rang. Once. Twice. Three times. Finally, a voice on the other end.
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        “Who is this?” they asked.
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        I cleared my throat, already feeling the words dry up in my mouth. “You don’t know me, but my name is…,” I said, forcing a smile that didn't quite reach my voice. "Just wondering if now might be a good time to talk business."
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        There was a pause. A long one. And in that silence, I knew how this one was going to end. I always know. 
                    </p>
                </Row>
                <Row className='justify-items-start' style={{ marginTop: '35px', marginBottom: '25px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
}

export default ColdCallMondayPage;
