import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MeetingList from './components/meetings/MeetingList';
import MyProfilePage from './components/profile/MyProfilePage';
import LandingPage from './components/landingPage/LandingPage';
import AboutUs from './components/aboutus/AboutUs';
import Dashboard from './components/dashboard/Dashboard';
import MeetingPage from './components/meetings/MeetingPage';
import LoginComponent from './components/user/LoginComponent';
import SignupComponent from './components/user/SignupComponent';
import PersonPanelComponent from './components/person/PersonPanelComponent';
import PrivacyPolicyComponent from './components/privacy/PrivacyPolicyComponent';
import TermsConditionsComponent from './components/termsconditions/TermsConditionsComponent';
import PricingComponent from './components/pricing/PricingPage';
import AuthHandler from './components/auth/AuthHandler';
import ResultPage from './components/search/ResultPage';
import OptionalDetailsNewUser from './components/profile/OptionalDetailsNewUser';
import BasicNewUser from './components/profile/BasicNewUser';
import SuccessNewUser from './components/profile/SuccessNewUser';
import UploadUserResume from './components/profile/UploadUserResume';
import PricingEssentials from './components/pricing/PricingEssentials';
import PricingProfessional from './components/pricing/PricingProfessional';
import PricingEnterprise from './components/pricing/PricingEnterprise';
import PowerOfWarmIntroductionsPage from './components/blog/warmintroductions/PowerOfWarmIntroductionsPage';
import MeetFoundersPage from './components/blog/meetfounder/MeetFoundersPage';
import AIIntroductablePage from './components/blog/aiintroductable/AIIntroductablePage';
import RehumanizingNetworkPage from './components/blog/rehumanization/RehumanizingNetworkPage';
import LeadGenerationPage from './components/blog/leadgeneration/LeadGenerationPage';
import FAQPage from './components/faq/FAQPage';
import './Main.css';
import ValueOfTrustedIntroductionsPage from './components/blog/valueoftrustedintroductions/ValueOfTrustedIntroductionsPage';
import PaymentSuccessPage from './components/payment/PaymentSuccessPage';
import MyConnectionsPage from './components/connections/MyConnectionsPage';
import ConnectionPage from './components/connections/ConnectionPage';
import ForgetPasswordComponent from './components/user/ForgetPasswordComponent';
import ChangePasswordComponent from './components/user/ChangePasswordComponent';
import NewsPage from './components/news/NewsPage';
import RequestorRolePage from './components/profile/roles/RequestorRolePage';
import IntroductorRolePage from './components/profile/roles/IntroductorRolePage';
import RelationshipInsurancePage from './components/blog/relationshipinsurance/RelationshipInsurancePage';
import TenReasonsColdReachPage from './components/blog/tenreasonscoldreach/TenReasonsColdReachPage';
import TragicComedyColdOutreachPage from './components/blog/tragiccomedy/TragicComedyColdOutreachPage';
import ConfessionCeoWhoLurksPage from './components/blog/confessionceowholurks/ConfessionCeoWhoLurksPage';
import GreatDayToLeaveMeAlonePage from './components/blog/greatdaytoleavemealone/GreatDayToLeaveMeAlonePage';
import NotFoundPage from './components/notfound/NotFoundPage';
import SummerSlowdownPage from './components/blog/summerslowdown/SummerSlowdownPage';
import FiftyShadesOfNayPage from './components/blog/fiftyshadesofnay/FiftyShadesOfNayPage';
import FunnelVisionPage from './components/blog/funnelvision/FunnelVisionPage';
import BusinessDeathStarsPage from './components/blog/businessdeathstars/BusinessDeathStarsPage';
import CultivatingMarketingSuccessPage from './components/blog/cultivatingmarketing/CultivatingMarketingSuccessPage';
import OlympicGoldOrOlympicLastPage from './components/blog/olympicgold/OlympicGoldOrOlympicLastPage';
import WhenTurningDownPaymentPage from './components/blog/whenturningdown/WhenTurningDownPaymentPage';
import MondaysAndColdOutreachPage from './components/blog/mondaysandcoldoutreach/MondaysAndColdOutreachPage';
import HowToGetWarmIntroductionsPage from './components/blog/howtogetwarmintroductions/HowToGetWarmIntroductionsPage';
import NotALeadPage from './components/blog/notalead/NotALeadPage';
import SalesLifeCycleTreePage from './components/blog/saleslifecycle/SalesLifeCycleTreePage';
import ColdCallMondayPage from './components/blog/coldcallmonday/ColdCallMondayPage';
import TopTenReasonsPage from './components/blog/toptenreasons/TopTenReasonsPage';
import ContactUsPage from './components/contactus/ContactUsPage';
import AgencyShowdownPage from './components/blog/agencyshowdown/AgencyShowdownPage';
import NeedRuthlessHonestyPage from './components/blog/needruthleshonestly/NeedRuthlessHonestyPage';
import FbiStrategiesSalesPage from './components/blog/fbistrategiessales/FbiStrategiesSalesPage';
import LeadGenerationTypesPage from './components/blog/leadgenerationtypes/LeadGenerationTypesPage';
import SharedGoalsProspectPage from './components/blog/sharedGoalsprospect/SharedGoalsProspectPage';
import TopTenReachProspectsPage from './components/blog/toptenreachprospects/TopTenReachProspectsPage';
import BeyondBonusPage from './components/blog/beyondbonus/BeyondBonusPage';
import RatingHaloweenCandyPage from './components/blog/ratinghaloweencandy/RatingHaloweenCandyPage';
import DiagnoseAndCureDealPage from './components/blog/diagnosecuredeal/DiagnoseAndCureDealPage';
import BreakthroughTheNoisePage from './components/blog/breakthroughnoise/BreakthroughTheNoisePage';

const Main: React.FC= () => {

    return (
        <>
          <div className='main-container'>    
            <Routes>
              <Route path="/" element={<LandingPage /> }/>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/news" element={<NewsPage />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/contact-us" element={<ContactUsPage />} />
              <Route path="/meet-founders" element={<MeetFoundersPage />} />

              <Route path="/experts/:expertId" element={<PersonPanelComponent />} />
              <Route path="/connections" element={<MyConnectionsPage />} />
              <Route path="/connections/add" element={<ConnectionPage />} />
              <Route path="/connections/:connectionId" element={<ConnectionPage />} />

              <Route path="/meetings" element={<MeetingList />} />
              <Route path="/meetings/:meetingId" element={<MeetingPage />} />

              <Route path="/profile" element={<MyProfilePage />} />
              <Route path="/signup" element={<SignupComponent />} />
              <Route path="/signin" element={<LoginComponent />} />

              <Route path="/auth-handler" element={<AuthHandler />} />

              <Route path="/search" element={<ResultPage />} />
              <Route path="/search/result" element={<ResultPage />} />
  
              <Route path="/profile/basic" element={<BasicNewUser />} />
              <Route path="/profile/optional" element={<OptionalDetailsNewUser />} />
              <Route path="/profile/resume" element={<UploadUserResume />} />
              <Route path="/profile/start" element={<SuccessNewUser />} />
              <Route path="/forget-password" element={<ForgetPasswordComponent />} />
              <Route path="/change-password" element={<ChangePasswordComponent />} />
              <Route path="/roles/requestor" element={<RequestorRolePage />} />
              <Route path="/roles/introductor" element={<IntroductorRolePage />} />
              

              <Route path="/faq" element={<FAQPage />} />
              <Route path="/payment/success" element={<PaymentSuccessPage />} />

              <Route path="/blog/power-of-warm-introductions" element={<PowerOfWarmIntroductionsPage />} />
              <Route path="/blog/ai-at-introductable" element={<AIIntroductablePage />} />
              <Route path="/blog/rehumanizing-network" element={<RehumanizingNetworkPage />} />
              <Route path="/blog/lead-generation" element={<LeadGenerationPage />} />
              <Route path="/blog/value-of-trusted-introductions" element={<ValueOfTrustedIntroductionsPage />} />
              <Route path="/blog/relationship-insurance" element={<RelationshipInsurancePage />} />
              <Route path="/blog/ten-reasons-cold-outreach" element={<TenReasonsColdReachPage />} />
              <Route path="/blog/tragic-comedy-of-cold-outreach" element={<TragicComedyColdOutreachPage />} />
              <Route path="/blog/confession-ceo-who-lurks" element={<ConfessionCeoWhoLurksPage />} />
              <Route path="/blog/great-day-to-leave-me-alone" element={<GreatDayToLeaveMeAlonePage />} />
              <Route path="/blog/summer-slowdown" element={<SummerSlowdownPage />} />
              <Route path="/blog/fifty-shades-of-nay" element={<FiftyShadesOfNayPage />} />
              <Route path="/blog/funnel-vision" element={<FunnelVisionPage />} />
              <Route path="/blog/business-meetings-death-stars" element={<BusinessDeathStarsPage />} />
              <Route path="/blog/cultivating-marketing-success" element={<CultivatingMarketingSuccessPage />} />
              <Route path="/blog/olympic-gold-or-olympic-last" element={<OlympicGoldOrOlympicLastPage />} />
              <Route path="/blog/when-turning-down-payment" element={<WhenTurningDownPaymentPage />} />
              <Route path="/blog/mondays-cold-outreach" element={<MondaysAndColdOutreachPage />} />
              <Route path="/blog/how-to-get-warm-introductions" element={<HowToGetWarmIntroductionsPage />} />
              <Route path="/blog/not-a-lead" element={<NotALeadPage />} />
              <Route path="/blog/sales-life-cycle-tree" element={<SalesLifeCycleTreePage />} />
              <Route path="/blog/cold-call-monday" element={<ColdCallMondayPage />} />
              <Route path="/blog/top-ten-reasons" element={<TopTenReasonsPage />} />
              <Route path="/blog/agency-showdown" element={<AgencyShowdownPage />} />
              <Route path="/blog/need-the-ruthless-honesty" element={<NeedRuthlessHonestyPage />} />
              <Route path="/blog/fbi-strategies-sales" element={<FbiStrategiesSalesPage />} />
              <Route path="/blog/lead-generation-types" element={<LeadGenerationTypesPage />} />
              <Route path="/blog/shared-goals-prospect" element={<SharedGoalsProspectPage />} />
              <Route path="/blog/top-ten-reach-prospects" element={<TopTenReachProspectsPage />} />
              <Route path="/blog/beyond-bonus" element={<BeyondBonusPage />} />
              <Route path="/blog/rating-haloween-candy" element={<RatingHaloweenCandyPage />} />
              <Route path="/blog/diagnose-and-cure-deal" element={<DiagnoseAndCureDealPage />} />
              <Route path="/blog/breakthrough-the-noise" element={<BreakthroughTheNoisePage />} />

              <Route path="/pricing" element={<PricingComponent />} />
              <Route path="/pricing/essentials" element={<PricingEssentials />} />
              <Route path="/pricing/professional" element={<PricingProfessional />} />
              <Route path="/pricing/enterprise" element={<PricingEnterprise />} />
              <Route path="/privacy-policy" element={<PrivacyPolicyComponent />} />
              <Route path="/terms-conditions" element={<TermsConditionsComponent />} />
              <Route path='*' element={<NotFoundPage />} />
            </Routes>
          </div>
        </>
      );
};

export default Main;
