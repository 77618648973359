import React from 'react';
import { BlogItemComponentProps } from '../types';
import { Container, Row } from 'react-bootstrap';
import '../../../Main.css';

import './BlogItemComponent.css';

const BlogItemComponent: React.FC<BlogItemComponentProps> = ({ title, publishDate, description, image, blogLink }) => {
    return (
        <a href={blogLink} style={{ textDecoration: 'none', color: 'inherit' }}>
            <Container className='blog-item-wrapper'>
                <Row>
                    <img src={image} className="blog-item-image" alt={`blog-item-image${title}`} />
                </Row>
                <Row style={{marginTop: '12px'}}>
                    <h5><b>{title}</b></h5>
                </Row>
                <Row style={{marginTop: '12px'}}>
                    <p>{publishDate}</p>
                </Row>
                <Row style={{marginTop: '12px'}}>
                    <p>{description}</p>
                </Row>
            </Container>
        </a>
    );
}

export default BlogItemComponent;