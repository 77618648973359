import axios from 'axios';

const setupAxiosInterceptors = (getToken: () => string | null, onTokenExpired: () => void) => {
    axios.interceptors.request.use(
        config => {
            const token = getToken();
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
            return config;
        },
        error => Promise.reject(error)
    );

    axios.interceptors.response.use(
        response => response,
        error => {
            const { response } = error;
            if (response && response.status === 401) {
                const token = getToken();
                if (token) {
                    onTokenExpired();
                }
            }
            return Promise.reject(error);
        }
    );
};

export default setupAxiosInterceptors;
