import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { ActionFormProps } from './ActionFormTypes';
import '../../../../Main.css';

const PendingAllGoodForm: React.FC<ActionFormProps> = ({ isIntroductor }) => {
    return (
        <Container>
            <Row style={{marginTop: '20px'}}>
              <h4>You are all set for now</h4>  
            </Row>
            <Row style={{marginTop: '20px'}}>
              <p>It's all good on your side. We will contact {isIntroductor ? 'Requestor' : 'Introductor'} about next steps</p>  
            </Row>
        </Container>
    );
}

export default PendingAllGoodForm;
