import { UUID } from "crypto";
import { TimeZone } from "../utils/DateUtils";

export enum UserReason {
    FIND_MEETINGS = "FIND_MEETINGS",
    ARRANGE_MEETINGS = "ARRANGE_MEETINGS",
    LOOK_AROUND = "LOOK_AROUND",
}

export const userReasonOptions = {
    FIND_MEETINGS: "Find Meetings",
    ARRANGE_MEETINGS: "Arrange Meetings",
    LOOK_AROUND: "Just Looking Around"
};


export type User = {
    email: string,
    password?: string,
    personId?: UUID,
    userReason?: UserReason,
    termsConditions?: boolean,
    rememberMe?: boolean,
    timezone?: TimeZone,
    personName?: string,
    personLastName?: string
};

export type UserResponse = {
    userId: number,
    status: string,
    personId: UUID
}

export type SignInResponse = {
    token: string,
    personId: UUID,
    email: string,
    personName?: string,
    personLastName?: string,
    timezone?: TimeZone
}