import React from 'react';
import './HorizontalLine.css';

const HorizontalLineSimpleLight: React.FC = () => {
  return (
    <div className='horizontal-line-wrapper'>
      <div className='horizontal-line-light'/>
    </div>
  );
}

export default HorizontalLineSimpleLight;
