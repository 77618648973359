import { Col, Container, Row } from "react-bootstrap";
import { TimeProposal } from "../../../types/meeting";
import StyledLabel from "../../atoms/label/StyledLabel";
import { DateUtils } from "../../../utils/DateUtils";

type TimeProposedComponentProps = {
    readOnlyTimeProposals?: TimeProposal[];
    title: string;
};

const TimeProposedComponent: React.FC<TimeProposedComponentProps> = ({ readOnlyTimeProposals = [], title }) => {

    const getTimeProposals = () => {
        return (
            <>
                <Row>
                    <Col>
                        <StyledLabel label={'Date'} />
                    </Col>
                    <Col>
                        <StyledLabel label={'Start Time'} />
                    </Col>
                    <Col>
                        <StyledLabel label={'End Time'} />
                    </Col>
                </Row>
                <Row className='read-only-single-proposal-wrapper'>
                {readOnlyTimeProposals.map(timeProposal => (
                    <Row>
                        <Col>
                            <p>{DateUtils.formatReadableDate(timeProposal.proposedStartTime)}</p>
                        </Col>
                        <Col>
                            <p>{DateUtils.formatReadableTime(timeProposal.proposedStartTime)}</p>
                        </Col>
                        <Col>
                            <p>{DateUtils.formatReadableTime(timeProposal.proposedEndTime)}</p>
                        </Col>
                    </Row>
                ))}
                </Row>
            </>
        );
    };

    return (
        <>
        {readOnlyTimeProposals.length > 0 && (
            <Container>
                <Row>
                    <Row>
                        <p><span style={{fontWeight: '600', fontSize: '20px'}}>{title}</span></p>
                    </Row>
                    <Row>
                        {getTimeProposals()}
                    </Row>
                </Row>
            </Container>
        )}
        </> 
    );
};

export default TimeProposedComponent;