import React, { ChangeEvent, useState } from 'react';
import { Container, Form, Row } from 'react-bootstrap';
import { ActionFormProps } from './ActionFormTypes';
import '../../../../Main.css';
import './ActionForm.css';
import { TimeProposal, UpdateMeetingDetailsRequest } from '../../../../types/meeting';
import TimeProposalComponent from '../../timeproposal/TimeProposalComponent';
import StyledInput from '../../../atoms/input/StyledInput';
import StyledCheckbox from '../../../atoms/checkbox/StyledCheckbox';
import StyledActionButton from '../../../atoms/button/StyledActionButton';
import { updateMeetingDetails } from '../../../../api/meetingApi';
import { useAuthContext } from '../../../../App';

const PendingMeetingPaidForm: React.FC<ActionFormProps> = ({ meeting, onSubmit }) => {
    const { user } = useAuthContext();

    const [updateMeetingDetailsRequest, setUpdateMeetingDetailsRequest] = useState<UpdateMeetingDetailsRequest>({
        title: `Introduction call with ${meeting.interessant?.name + ' ' + meeting.interessant?.lastName} via ${meeting.introductor?.name}`, 
        description: 
`       Hi ${meeting.expert?.name}
I'm glad that ${meeting.introductor?.name} was able to connect us and I am looking forward to sharing a bit about us and how I think we can help you this year! Just a quick proposed agenda for our meeting:

        - Introductions: Get to know each other
        - Exploring Solutions and Discussion of Needs: A little about what we do and how we can help
        - Next Steps: Best way to move forward

Looking forward to learning more about you and how we can collaborate. 

Best regards,
${meeting.interessant?.name + ' ' + meeting.interessant?.lastName}`, 
        timeProposals: [], 
        anyTime: false}
    );
    const [timeProposals, setTimeProposals] = useState<TimeProposal[]>([]);
    const [anyTime, setAnyTime] = useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUpdateMeetingDetailsRequest({...updateMeetingDetailsRequest, [event.target.name]: event.target.value});
    };

    const handleSubmit = async () => {
        if (meeting.meetingId) {
            await updateMeetingDetails(meeting.meetingId, updateMeetingDetailsRequest).then(
                () => {
                    onSubmit();    
                }
            )
        }
    }

    const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked } = e.target;
        setAnyTime(checked);
        setUpdateMeetingDetailsRequest({...updateMeetingDetailsRequest, anyTime: checked});
        if(checked) {
            setUpdateMeetingDetailsRequest(prevState => ({
                ...prevState,
                timeProposals: []
            }));
        } else {
            setUpdateMeetingDetailsRequest(prevState => ({
                ...prevState,
                timeProposals: timeProposals
            }));
        }
    };

    const handleTimeProposalChange = (newProposals: TimeProposal[]) => {
        setTimeProposals(newProposals);
        setUpdateMeetingDetailsRequest(prevState => ({
            ...prevState,
            timeProposals: newProposals
        }));
    };

    return (
        <>
        <Container>
            <Form>
                <Row>
                    <h4 style={{fontFamily: 'Raleway'}}>Connect with your Introductor</h4>
                </Row>
                <Row>
                    <p>Share details about your meeting and propose meeting times.</p>
                </Row>
                <Row>
                    <Form.Group>
                        <p><span style={{fontWeight: '600', fontSize: '20px'}}>Meeting Title: </span>This is what will be used as the title in your Google Meeting</p>
                        <StyledInput 
                            name="title"
                            value={updateMeetingDetailsRequest.title || ''}
                            onChange={handleChange} 
                            type={'text'}                  
                            placeholder='ex: Meeting with Introductable'      
                        />
                    </Form.Group>
                </Row>
                <Row style={{marginTop: '20px'}}>
                    <p><span style={{fontWeight: '600', fontSize: '20px'}}>Propose a date and time or choose “Any of my working hours”. </span>The more times you propose, the better the chance for the meeting to occur!</p>
                    <StyledCheckbox 
                        checked={anyTime} 
                        name='anyTime'
                        onChange={handleCheckboxChange} 
                        label={<span className='main-color' style={{fontWeight: '600', fontSize: '16px'}}>{`Any of my working hours. ${user.timezone ?? ''} `}</span>} />
                </Row>
                {
                    !anyTime && (
                        <Row style={{marginTop: '20px'}}>
                            <TimeProposalComponent 
                                    timeProposalsProp={updateMeetingDetailsRequest.timeProposals}
                                    onTimeProposalsChange={handleTimeProposalChange} 
                                    title={""} 
                            />
                        </Row>
                    )
                }
                <Row style={{marginTop: '20px'}}>
                    <Form.Group>
                        <p><span style={{fontWeight: '600', fontSize: '20px'}}>Meeting Description:</span> This is what will be included in the body of your Google Meeting. We strongly encourage you to edit it to reflect both you, and your meeting details!</p>
                        <Form.Control
                            as="textarea"
                            name="description"
                            value={updateMeetingDetailsRequest.description || ''}
                            onChange={handleChange}
                            placeholder={`ex: Hey Pat thanks for making this introduction! I'm planning on sharing how Introductable can raise their revenue 10% in 3 months. using us.`}
                            style={{minHeight: '400px'}}
                        />
                    </Form.Group>
                </Row>
                <Row style={{marginTop: '20px'}}>
                    <StyledActionButton 
                        label={'Send Google Calendar Meet Details'}         
                        onClick={handleSubmit}               
                    />
                </Row>
                <Row style={{marginTop: '20px', marginBottom: '20px'}}>
                    <p>Connect with your Introductor to share info on you and the reason for the meeting. Be genuine! You’ll get better results. Email and chat are both available for messaging.</p>
                </Row>
            </Form>
        </Container>
        </>
    );
}

export default PendingMeetingPaidForm;
