import React, { useRef } from 'react';
import DatePicker from 'react-datepicker';
import { parse, format } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import './StyledCalendarSelect.css';
import ReactDatePicker from 'react-datepicker';
import { FaRegCalendarAlt } from "react-icons/fa";
import { Col, Container, Row } from 'react-bootstrap';

interface StyledCalendarSelectProps {
  value: string;
  onChange: (date: string, field?: string, index?: number) => void;
  [key: string]: any;
}

const StyledCalendarSelect: React.FC<StyledCalendarSelectProps> = ({ value, onChange, ...props }) => {
  const datePickerRef = useRef<ReactDatePicker | null>(null);

  const parseDate = (dateString: string) => {
    return parse(dateString, 'yyyy-MM-dd', new Date());
  };

  const formatDate = (date: Date) => {
    return format(date, 'yyyy-MM-dd');
  };

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = formatDate(date);
      onChange(formattedDate);
    }
  };

  const handleDivClick = () => {
    if (datePickerRef.current) {
      datePickerRef.current.setOpen(true);
    }
  };

  return (
    <Container fluid className="custom-calendar-wrapper" onClick={handleDivClick}>
      <Row>
        <Col>  
          <FaRegCalendarAlt style={{marginRight: '10px'}}/>
          <DatePicker
            ref={(ref) => (datePickerRef.current = ref)}
            selected={value ? parseDate(value) : null}
            onChange={handleDateChange}
            dateFormat="MM/dd/yyyy"
            className="custom-calendar-input"
            placeholderText='mm/dd/yyyy'
            {...props}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default StyledCalendarSelect;
