import { Col, Container, Row } from "react-bootstrap";
import PricingComponent from "../organisms/pricing/PricingComponent";
import { GiProgression } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import './PricingPage.css';

const PricingProfessional: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <Container fluid>
                <Row className="centred-element" style={{ marginTop: "50px"}}>
                    <h2><span className="main-color">Professional</span></h2>
                </Row>
                <Row className="justify-content-space-between" style={{ marginBottom: "20px"}} >
                    <Col className="pricing-single-component"> 
                        <PricingComponent 
                            icon={<GiProgression className="color-element" />}
                            title={"Professional"}
                            pricePerMonth={"$599.00"}
                            benefitList={[
                                'Designed for organizations with consistent demand generation needs.',
                                'Request support and outreach for unmatched meeting targets.',
                                'Priority outreach for high-demand meeting targets over Essentials.',
                                'Enhanced features and capabilities tailored for growing businesses.',
                                'Expanded access to a larger pool of qualified leads and potential connections.',
                                'Lowered meeting costs: $950/meeting',
                            ]}
                            linkHref='/pricing/professional'
                            onClickButton={() => navigate(`/pricing/professional`)} 
                            buttonLabel={'Purchase Now'}                        
                        />
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default PricingProfessional;