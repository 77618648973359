import React, {  } from "react";
import { Col, Container, Row } from 'react-bootstrap';
import './LandingPage.css';
import '../../Main.css';

import { useNavigate } from "react-router-dom";

import WantMeetingComponent from "../organisms/wantmeeting/WantMeetingComponent";
import CircledButton from "../atoms/button/CircledButton";
import FooterAddition from "../footer/FooterAddition";
import PowerOfWarmIntroductionsComponent from "../organisms/warmintroductions/PowerOfWarmIntroductionsComponent";
import VideoComponent from "../organisms/videocomponent/VideoComponent";
import topBannerLeftPeopleImage from '../../content/landing-page/left_people_title.png';
import topBannerRightPeopleImage from '../../content/landing-page/right_people_title.png';
import UnlockThePowerComponent from "../organisms/unlockthepower/UnlockThePowerComponent";
import Testimonials from "../organisms/testimonials/Testimonials";
import NewsPageBannerComponent from "../organisms/newspagebanner/NewsPageBannerComponent";
import StyledLink from "../atoms/link/StyledLink";
import StyleVideoComponent from "../molecules/video/StyleVideoComponent";
import mainVideo from '../../content/video/Introducable_v2-short_with_subtitles.mp4';
import ProcessSteps from "../organisms/processsteps/ProcessSteps";


const LandingPage: React.FC = () => {
    const navigate = useNavigate();
    const INTRODUCTABLE_MARKETING_URL = 'https://www.youtube.com/embed/39r7m3FyVpk'; 

    const onGetStartedClick = () => {
        navigate(`/signup`);
    }

    return (
        <>
            <Container fluid>
            <div className="landing-page-content">
                <Row className="top-banner landing-page-gradient">
                    <Col xs={12} md={2} className="banner-image" style={{ marginTop: '30px' }} >
                        <img src={topBannerLeftPeopleImage} className="banner-image-left" alt="topBannerLeftPeopleImage" />
                    </Col>
                    <Col xs={12} md={8} className="banner-title">
                        <Row className="landin-page-main-title" style={{ marginTop: '30px' }}>
                            <h4 className="main-color" style={{fontFamily: 'Raleway'}}>Get introduced with Introductable</h4>
                        </Row>
                        <Row className="landin-page-main-title" style={{ marginTop: '20px' }}>
                            <Col>
                                <h1>The Best Way to Have the <span className="main-color">Meetings You Want</span> With 
                                <span className="main-color"> Who You Want</span></h1>
                            </Col> 
                        </Row>
                        <Row style={{ marginTop: '50px' }}>
                            <Col style={{ textAlign: 'center' }}>
                                <h5 style={{fontFamily: 'Raleway', paddingBottom: '10px'}}>
                                    I Want to Be Paid For Setting Meetings
                                </h5>
                                <CircledButton 
                                    label={"Become an Introductor"} 
                                    onClick={() => navigate(`/signup`)} 
                                    transitionX={"20px"} 
                                    maxWidth={'260px'}
                                />
                                <p style={{paddingTop: '16px'}}>
                                    Looking to arrange meetings and be paid for it? Become an Introductor and create your free account 
                                    to be matched with people looking for introductions into your network.
                                    <br/>
                                    <StyledLink href={'/roles/introductor'}>Learn more...</StyledLink>
                                </p>
                            </Col>
                            <Col style={{ textAlign: 'center' }}>
                                <h5 style={{fontFamily: 'Raleway', paddingBottom: '10px'}}>
                                    I Want to Have Meetings Set For Me
                                </h5>
                                <CircledButton 
                                    label={"Become a Requestor"} 
                                    onClick={() => navigate(`/signup`)} 
                                    transitionX={"25px"} 
                                    maxWidth={'260px'}
                                />
                                <p style={{paddingTop: '16px'}}>
                                    Looking to have sales or other types of meetings set for you? 
                                    Become a Requestor by creating a free account: then search for your prospects and have meetings set for you!
                                    <br/>
                                    <StyledLink href={'/roles/requestor'}>Learn more...</StyledLink>
                                </p>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '50px', maxWidth: '80%' }}>
                            <StyleVideoComponent isLooped={true} videoSrc={mainVideo} />
                        </Row>
                    </Col>
                    <Col xs={12} md={2} className="banner-image" style={{ marginTop: '30px' }} >
                        <img src={topBannerRightPeopleImage} className="banner-image-right" alt="topBannerRightPeopleImage" />
                    </Col>
                </Row>
                <Row className="top-banner-mobile landing-page-gradient">
                    <Col xs={12} className="banner-title" style={{marginBottom: '20px'}}>
                        <Row className="landin-page-main-title" style={{ marginTop: '30px' }}>
                            <h4 className="main-color" style={{fontFamily: 'Raleway'}}>Get introduced with Introductable</h4>
                        </Row>
                        <Row className="landin-page-main-title" style={{ marginTop: '20px' }}>
                            <Col>
                                <h1>The Best Way to Have the <span className="main-color">Meetings You Want</span> With 
                                <span className="main-color"> Who You Want</span></h1>
                            </Col> 
                        </Row>
                    </Col>
                    <Col xs={12}>
                        <Row style={{ marginTop: '30px', marginBottom: '30px' }}>
                            <Col xs={12} style={{ textAlign: 'center' }}>
                                <h5 style={{fontFamily: 'Raleway', paddingBottom: '10px'}}>
                                    I Want to Be Paid For Setting Meetings
                                </h5>
                                <CircledButton 
                                    label={"Become an Introductor"} 
                                    onClick={onGetStartedClick} 
                                    transitionX={"20px"} 
                                    maxWidth={'260px'}
                                />
                                <p style={{paddingTop: '10px'}}>
                                    <StyledLink href={'/roles/introductor'}>Learn more...</StyledLink>
                                </p>
                            </Col>
                            <Col xs={12} style={{ textAlign: 'center' }}>
                                <h5 style={{fontFamily: 'Raleway', paddingBottom: '10px', marginTop: '20px'}}>
                                    I Want to Have Meetings Set For Me
                                </h5>
                                <CircledButton 
                                    label={"Become a Requestor"} 
                                    onClick={onGetStartedClick} 
                                    transitionX={"25px"} 
                                    maxWidth={'260px'}
                                />
                                <p style={{paddingTop: '10px'}}>
                                    <StyledLink href={'/roles/requestor'}>Learn more...</StyledLink>
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <StyleVideoComponent isLooped={true} videoSrc={mainVideo} />
                        </Row>
                    </Col>
                    <Col xs={5} className="banner-image">
                        <img src={topBannerLeftPeopleImage} className="banner-image-left" alt="topBannerLeftPeopleImage" />
                    </Col>
                    <Col xs={2}></Col>
                    <Col xs={5} className="banner-image">
                        <img src={topBannerRightPeopleImage} className="banner-image-right" alt="topBannerRightPeopleImage" />
                    </Col>
                </Row>
                <Row style={{ marginTop: '60px', display: 'flex', justifyContent: 'center' }}>
                    <PowerOfWarmIntroductionsComponent />
                </Row>
                {/* <Row style={{ marginTop: '20px' }}>
                    <WhyUsComponent />
                </Row> */}
                <Row style={{ marginTop: '20px' }}>
                    <NewsPageBannerComponent />
                </Row>
                <Row style={{ marginTop: '30px' }}>
                    <ProcessSteps />
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <WantMeetingComponent />
                </Row>
                {/* <Row style={{ marginTop: '20px' }}>
                    <OurFeaturesComponent />
                </Row>      */}
                <Row className="landing-page-gradient-with-top" style={{ marginTop: '20px', paddingBottom: '40px' }}>
                    {/* <FAQComponent /> */}
                    <VideoComponent videoUrl={INTRODUCTABLE_MARKETING_URL} />
                    <span style={{marginTop: '20px'}}></span>
                    <Testimonials />
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <UnlockThePowerComponent />
                </Row>
                </div>
            </Container>
            <FooterAddition />
        </>
    )
};

export default LandingPage;

