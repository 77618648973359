import axios, { AxiosResponse } from 'axios';
import { Payment, CreatePaymentRequest, CreatePaymentResponse, CheckCouponResponse } from '../types/payment';

const API_BASE_URL = `${process.env.REACT_APP_API_BASE_URL}/v1/payment`;

export async function createPaymentSession(paymentRequest: CreatePaymentRequest): Promise<AxiosResponse<CreatePaymentResponse>> {
  return await axios.post(`${API_BASE_URL}/stripe`, paymentRequest);
}

export async function getPaymentById(id: string): Promise<AxiosResponse<Payment>> {
  return await axios.get(`${API_BASE_URL}/${id}`);
}

export async function checkCoupon(discountCode: string): Promise<AxiosResponse<CheckCouponResponse>> {
  return await axios.post(`${API_BASE_URL}/check-coupon`, {discountCode: discountCode});
}