import React from 'react';
import './ValueOfTrustedIntroductionsPage.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';
import FooterAddition from '../../footer/FooterAddition';

const ValueOfTrustedIntroductionsPage: React.FC = () => {
    return (
        <>
        <Container fluid className='justify-content-center'>
        <Row style={{paddingLeft: "20px", paddingTop: "50px", paddingRight: "20px", paddingBottom: "40px"}} className='justify-content-center'>
            <Row className='justify-content-center'>
                <h5 className="main-color value-of-truested-introductions-styled-text">The Value of Trusted Introductions</h5>
            </Row>
            <Row>
                <Col className='value-of-truested-introductions-title'>
                    <h2>
                    <span className="main-color"> Moving Ahead </span>
                    By Looking in the Past:  
                    <span className="main-color"> A new old way </span>
                    to network
                    </h2>
                </Col>
            </Row>
            <Row className='value-of-truested-introductions-styled-text' style={{ marginTop: '20px' }}>
                <p>
                    At Introductable, we’ve ‘re-revolutionized’ the way professionals connect and generate demand for their businesses by 
                    focusing on the power of trusted relationships. Here's why we're your newest and best-performing solution for fostering 
                    genuine new business relationships and driving growth:
                    Authentic Connections: We believe in the power of authentic relationships. 
                    By facilitating introductions made by individuals who personally know each other, we ensure that every connection is meaningful and relevant. 
                    This authenticity resonates with our users, leading to a higher likelihood of successful meetings and fruitful business collaborations.
                    <br/><br/>
                        Quality Over Quantity: Unlike other networking platforms that prioritize quantity of connections, we focus on quality. 
                        We will never bring 10000 new leads a month to your organization but we will enable your highest-performing meetings. 
                        Our users seek introductions from trusted contacts who have deep, meaningful relationships with their meeting targets. 
                        This ensures that each introduction is valuable and has the potential to drive successful business outcomes.
                    <br/><br/>
                        Clear Value Proposition: Our platform offers a clear and compelling value proposition for both seekers of introductions (Requestors) 
                        and those making introductions (Introductors). Requestors can efficiently find and connect with relevant contacts, while Introductors 
                        are incentivized to facilitate meaningful meetings. This mutual benefit drives demand for our platform as users see tangible results 
                        from their interactions.
                        Secure and Convenient Process: We've designed our platform to be safe, easy to use, and transparent. 
                        Our escrow system ensures that meeting fees are held securely until introductions are successfully made and meetings held, 
                        providing peace of mind for all involved. This security and convenience encourage users to actively engage with our platform, 
                        driving demand and usage over time.
                    <br/><br/>
                        Community Engagement: We foster a vibrant community of professionals who actively participate in making and seeking introductions. 
                        This sense of community fosters trust, encourages collaboration, and amplifies the reach of our platform through word-of-mouth referrals. 
                        As our community grows, so does the demand for our services and so do the opportunities for connection.
                    <br/><br/>
                        Empowering Users: By providing a platform where users can leverage their existing networks to drive business opportunities, we empower 
                        individuals to take control of their networking efforts. This empowerment resonates with professionals who seek more meaningful and impactful 
                        ways to grow their businesses, driving demand for our unique solution.
                        In summary, Introductable is not just a networking or demand generation platform – it's a catalyst for authentic connections and meaningful 
                        business relationships. Our focus on authenticity, quality, value, security, community, and empowerment makes us the ultimate solution for 
                        professionals seeking to generate demand and drive growth in their endeavors. Join us and experience the power of genuine connections firsthand.
                    </p>
            </Row>
        </Row>
        </Container>
        <FooterAddition />
        </>
    );
    }

export default ValueOfTrustedIntroductionsPage;
