import React from 'react';
import './ImageWithTitlesCollage.css';
import { Col, Container, Row } from 'react-bootstrap';
import { ImageWithTitlesCollageProps } from '../types';


const ImageWithTitlesCollage: React.FC<ImageWithTitlesCollageProps> = ({imageSrc, firstTitle, secondTitle}) => {
    return (
        <Container fluid>
            <Row className='image-with-titles-collage-wrapper'>
                <Col md={3} className="d-flex align-items-center justify-content-flex-start image-with-titles-collage-title image-with-titles-collage-title-first">
                    <div>{firstTitle}</div>
                </Col>
                <Col md={6} className="d-flex align-items-center justify-content-center">
                    <img src={imageSrc} className="image-with-titles-collage-image" alt="collageImage" />
                </Col>
                <Col md={3} className="d-flex align-items-center justify-content-flex-end image-with-titles-collage-title image-with-titles-collage-title-second">
                    <div>{secondTitle}</div>
                </Col>
            </Row>
        </Container>
    );
}

export default ImageWithTitlesCollage;
