import { Col, Container, Row } from "react-bootstrap";
import { signUpUser } from "../../api/authApi";
import { User } from "../../types/user";
import UserForm from "./LoginForm";
import { useNavigate } from "react-router-dom";
import './LoginComponent.css';
import GoogleComponent from "./google/GoogleComponent";
import signupPicture from '../../content/signup/signup.jpeg';
import stepperOne from '../../content/account/stepper_1.svg';
import logo from '../../content/logo/logo_opacity.png';
import HorizontalLine from "../atoms/lines/HorizontalLine";
import StyledLabel from "../atoms/label/StyledLabel";
import StyledLink from "../atoms/link/StyledLink";
import { useState } from "react";

const SignupComponent: React.FC = () => {
    const navigate = useNavigate();
    const [isErrorSignUp, setIsErrorSigup] = useState<Boolean>(false);

    const handleSignUp = async (user: User) => {
        await signUpUser(user)
            .then(response => {
                if (response.status === 200) {
                    navigate('/signin');
                }
            }).catch((responseError) => {
                setIsErrorSigup(true);
            });
    };

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={4} style={{ padding: 0 }}>
                        <img src={signupPicture} className="signup-image" alt="Login" />
                    </Col>
                    <Col md={8} style={{ paddingLeft: "10%", paddingRight: "10%", paddingTop: "50px" }}>
                        <Row>
                            <img src={logo} style={{ width: "50%", height: "100%", objectFit: "cover" }} alt="Logo" />
                        </Row>
                        <Row>
                            <img src={stepperOne} style={{ width: "100%", height: "100%", objectFit: "cover", paddingTop: "20px" }} alt="stepper" />
                        </Row>
                        <Row style={{paddingTop: "34px"}}>
                            <Row>
                                <h4>Create your <span className="main-color">Account</span></h4>
                            </Row>
                            <Row style={{paddingTop: "10px"}}>
                                <p>You’re just a few steps away from finding your meeting.</p>
                            </Row>
                        </Row>
                        <Row  style={{paddingTop: "20px"}}>
                            <UserForm
                                onSubmit={handleSignUp}
                                isNewUser={true}
                            />
                            {isErrorSignUp ? <p style={{ color: 'red', paddingTop: '10px' }}>User already exist</p> : <></>}
                        </Row>
                        <Row className="mt-2 align-items-center justify-content-center" style={{ paddingTop: "10px" }}>
                            <Col xs="auto" className="pe-0"><StyledLabel label="Already have an account?" /></Col>
                            <Col xs="auto" className="ps-2"><StyledLink href="/signin" bold={true}>Sign in</StyledLink></Col>
                        </Row>
                        <Row style={{ paddingTop: "18px" }}>
                            <HorizontalLine label={'or'}/>      
                        </Row>
                        <Row style={{ paddingTop: "18px", paddingLeft: "15%", paddingRight: "15%", paddingBottom: "20px" }}>
                            <GoogleComponent />
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
};


export default SignupComponent;
