import React from 'react';
import './HowToGetWarmIntroductionsPage.css';
import '../../../Main.css';
import { Container, Row } from 'react-bootstrap';
import FooterAddition from '../../footer/FooterAddition';
import blogImage from '../../../content/blog/howtogetwarm/howToGetWarm.png';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';


const HowToGetWarmIntroductionsPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
        <Container fluid>
            <Row className='how-to-get-warm-introductions-wrapper'>
                <Row style={{marginTop: '30px'}}>
                    <h2 className='how-to-get-warm-introductions-mobile-text' >How to Get <span className="main-color">Warm Introductions</span></h2>
                </Row>
                <Row style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={blogImage} className="business-death-stars-image" alt="howToGetWarmImage" />
                </Row>
                <Row style={{marginTop: '40px'}}>
                    <p>
                    You already know that warm introductions instantly establish credibility and trust, bypassing the skepticism often associated with cold outreach and are absolutely invaluable for your business. 
                    </p>
                    <p>
                        But how do you get warm introductions? Let’s talk strategy. 
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        1. <b>Nurture your existing network</b>
                        <br/>
                            Build and maintain strong relationships with your current contacts. These connections can become valuable sources of introductions.
                        <br/>
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        2. <b>Be specific in your requests</b>
                        <br/>
                            When asking for an introduction, be clear about who you want to meet and why. This helps your contact understand how they can help.
                        <br/>
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        3. <b>Offer value in return</b>
                        <br/>
                        Always think about how you can provide value to both the person introducing you and the person you're being introduced to. This creates a win-win situation.
                        <br/>
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        4. <b>Use LinkedIn strategically</b>
                        <br/>
                            LinkedIn can be a great tool for identifying mutual connections. Look for second-degree connections and see who in your network might be able to introduce you.
                        <br/>
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        5. <b>Attend industry events and conferences</b>
                        <br/>
                            These gatherings provide excellent opportunities to meet new people and potentially get introduced to key figures in your industry.
                        <br/>
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        6. <b>Join professional organizations</b>
                        <br/>
                            Being part of industry-specific groups can help you build relationships that lead to warm introductions.
                        <br/>
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        7. <b>Leverage alumni networks</b>
                        <br/>
                            If you have connections from your educational background, these can be great sources for introductions in various industries.
                        <br/>
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        8. <b>Be prepared with your pitch</b>
                        <br/>
                            When you do get an introduction, be ready to clearly articulate who you are and what you're looking for.
                        <br/>
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        9. <b>Follow up promptly</b>
                        <br/>
                            After receiving an introduction, follow up quickly and professionally. This shows respect for both parties' time.
                        <br/>
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        10. <b>Express gratitude</b>
                        <br/>
                            Always thank the person who made the introduction, regardless of the outcome. This encourages them to help you again in the future.
                        <br/>
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        11. <b>Offer to make introductions for others</b>
                        <br/>
                            By being proactive in connecting people in your network, you increase the likelihood that others will do the same for you.
                        <br/>
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        12. <b>Use a formal introduction service</b>
                        <br/>
                            Consider using a professional introduction service or platform that specializes in facilitating business connections. *cough Introductable*
                        <br/>
                    </p>
                </Row>
                <Row className='justify-items-start' style={{ marginTop: '35px', marginBottom: '25px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
}

export default HowToGetWarmIntroductionsPage;
