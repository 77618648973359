import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import './NewsPageBannerComponent.css';
import StyledButton from '../../atoms/button/StyledButton';

const NewsPageBannerComponent: React.FC = () => {

    return (
        <Container className='news-page-banner-wrapper'>
            <Row style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px"}}>
                <Row style={{marginTop: '16px'}}>
                    <h5 className="main-color" style={{fontFamily: 'Raleway'}}>Our Blog</h5>
                </Row>
                <Row>
                    <Col>
                        <h2>
                            Discover the latest <span className="main-color">insights and trends.</span>
                            <br/>
                            Check <span className="main-color">our newest blog</span> and stay informed!
                        </h2>
                    </Col>
                </Row>
                <Row style={{marginTop: '16px', maxWidth: '250px', marginLeft: 'auto', marginRight: 'auto'}}>
                    <StyledButton label={'Check News Here!'} onClick={() => window.location.href = '/news'}/>
                </Row>
            </Row>
        </Container>
    );
}

export default NewsPageBannerComponent;

