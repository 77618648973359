import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import aboutUsImageTitle from '../../content/aboutus/aboutus.jpeg';
import MissionComponent from "../organisms/mission/MissionComponent";
import OurTeamComponent from "../organisms/ourteam/OurTeamComponent";
import ContactComponent from "../organisms/contact/ContactComponent";
import WhyUsComponent from "../organisms/whyus/WhyUsComponent";
import WantMeetingComponent from "../organisms/wantmeeting/WantMeetingComponent";
import CircledButton from "../atoms/button/CircledButton";
import { useNavigate } from "react-router-dom";
import './AboutUs.css';
import '../../Main.css';
import FooterAddition from "../footer/FooterAddition";
import OurValues from "../molecules/ourvalues/OurValues";


const AboutUs: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <Container fluid>
                <Row>
                    <img src={aboutUsImageTitle} className="about-us-title-image" alt="AboutUsTitle" />
                </Row>
                <Row style={{ marginTop: '35px', textAlign: 'center' }}>
                    <Col><h1>Welcome to <span className="main-color">Introductable</span></h1></Col>
                </Row>
                <Row style={{ textAlign: 'center' }}>
                    <h4>Meet with Us</h4>
                </Row>
            </Container>
            <Container fluid>
                <Row className='about-us-centered-text' style={{ marginTop: '25px' }}>
                    <p style={{paddingLeft: "20px", paddingTop: "20px", paddingRight: "20px"}}>
                        At Introductable, we believe in the power of meaningful connections. 
                        We know that when honest and real introductions occur, magic can happen. 
                        Ideas get shared, business opportunities are discovered and people become better for the experience. 
                        In an ever increasingly impersonal and automated world filled with spam, email blasts and generic AI-created outreach, 
                        the ability to have real people speak directly with others is more important, and more rare, than ever before.
                    </p>
                </Row>
                <Row style={{ marginTop: '30px' }}>
                    <MissionComponent />
                </Row>
                <Row style={{ marginTop: '30px' }}>
                    <OurTeamComponent />
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <WantMeetingComponent />
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <OurValues />
                </Row>           
                <Row style={{ marginTop: '30px' }}>
                    <ContactComponent />
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <WhyUsComponent />
                </Row>
                <Row style={{ marginTop: '35px', marginBottom: '25px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
            </Container>
            <FooterAddition />
        </>
    );
};

export default AboutUs;
