import React from 'react';
import './FiftyShadesOfNayPage.css';
import '../../../Main.css';
import { Container, Row } from 'react-bootstrap';
import FooterAddition from '../../footer/FooterAddition';
import { GoDotFill } from 'react-icons/go';


const FiftyShadesOfNayPage: React.FC = () => {
    return (
        <>
        <Container fluid>
            <Row className='fifty-shades-of-nay-wrapper'>
                <Row style={{marginTop: '30px'}}>
                    <h2 className='fifty-shades-of-nay-mobile-text' ><span className="main-color">50 Shades of Nay:</span> How Cold Calling Is Somehow Worse Than Tinder Email Responders</h2>
                </Row>
                <Row style={{marginTop: '40px'}}>
                    <p>
                        Would you rather spend your day getting rejected by strangers <b>over the phone</b> or getting rejected by strangers <b>on your phone</b>? 
                        For those poor, brave souls who've experienced both the chills of cold calling and the spills of Tinder swiping, 
                        we present the definitive guide to why dialing for dollars makes dating apps look positively lovely.
                    </p>
                    <p>
                        Buckle up, buttercup - we're about to dive into a world where "ghosting" is the best-case scenario and "It's not you, 
                        it's me" becomes "It's absolutely you." Let's swipe left on sanity and right on soul-crushing rejection 
                        and see if Tinder or cold calling is more likely to lead to crippling personal rejection! 
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        1. <b>Immediate Rejection:</b>
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Tinder: A left swipe is quick and silent.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Cold Calling: You get to hear lots of hold music and hang ups. 
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Winner: <b>Tinder</b>
                        <br/>
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        2. <b>Scripted Conversations:</b>
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Tinder: You can be witty and spontaneous.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Cold Calling: You're stuck following a script that sounds painfully robotic.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Winner: <b>Tinder</b>
                        <br/>
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        3. <b>Who Are You?:</b>
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Tinder: Your matches don't know who you are until you start chatting.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Cold Calling: Prospects see an unknown number and ignore you before you even start.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Winner: <b>Tinder</b>
                        <br/>
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        4. <b>No Escape:</b>
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Tinder: You can swipe left and move on in a second.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Cold Calling: You’re committed to the cal…and a next…and a next…please let it stop.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Winner: <b>Tinder</b>
                        <br/>
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        5. <b>Awkward Silence:</b>
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Tinder: Ghosting is expected.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Cold Calling: Ghosting gets you lots of meetings with your boss!
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Winner: <b>Tinder</b>
                        <br/>
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        6. <b>Excitement:</b>
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Tinder: You might see someone you know! 
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Cold Calling: You might actually hear a live voice 1 out of 100 times.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Winner: <b>Tinder</b>
                        <br/>
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        7. <b>Rudeness:</b>
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Tinder: You don’t have to actually read it you know…
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Cold Calling: They don’t get it…they don’t want it…they won’t listen but rarely make fun of you.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Winner: <b>Cold calling</b>
                        <br/>
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        8. <b>Unwanted Advice:</b>
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Tinder: Rarely does someone tell you how to improve your profile.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Cold Calling: Prospects love to tell you why your pitch is terrible.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Winner: <b>Tinder</b>
                        <br/>
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        9. <b>Time Wasted:</b>
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Tinder: A quick swipe, and you’re done.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Cold Calling: Minutes spent dialing, waiting, pitching, and getting rejected. Then doing it all again whenever your sequence says.
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Winner: <b>Tinder</b>
                        <br/>
                    </p>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        10. <b>Gatekeepers:</b>
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Tinder: No middlemen, just direct ignoring. 
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Cold Calling: Assistants, so polite…so definitely not taking a message. 
                        <br/>
                        <GoDotFill className='main-color' style={{marginLeft: '30px'}}/> Winner: <b>Tinder</b>
                        <br/>
                    </p>
                </Row>
                <Row style={{marginTop: '20px'}}>
                    <p>
                        By a score of 7-2 with 1 tie, Tinder comes out as less cringe than cold calling! 
                    </p>
                    <p>
                        Maybe try actually meeting someone instead? (see how I snuck in a reference to Introductable there?)
                    </p>
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
}

export default FiftyShadesOfNayPage;
