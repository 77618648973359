import React from 'react';
import './CircledButton.css'; 
import { CircledButtonSmallProps } from '../types';
import { Col, Container, Row } from 'react-bootstrap';
import { IoIosArrowRoundForward } from "react-icons/io";



const CircledButtonSmall: React.FC<CircledButtonSmallProps> = ({ onClick }) => {
  return (
    <Container onClick={onClick} className='circled-button-small-wrapper'>
            <Row className="justify-content-between align-items-center">
                <Col>
                  <Row style={{ paddingLeft: "10px"}}>
                  </Row>
                </Col>
                <Col xs="auto">
                    <div className='circle-arrow-wrapper'>
                        <IoIosArrowRoundForward />
                    </div>
                </Col>
            </Row>
        </Container>
  );
};

export default CircledButtonSmall;

