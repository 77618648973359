import React from 'react';
import './StyledSelect.css';
import { StyledSelectProps } from '../types';

const StyledSelect: React.FC<StyledSelectProps> = ({ onChangeSelect, options, placeholder, value, ...props }) => {
  return (
    <select className="custom-select" onChange={onChangeSelect} value={value} {...props}>
      <option value="" disabled>
        {placeholder}
      </option>
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

export default StyledSelect;
