import React from 'react';
import './SingleImageCollage.css';
import { Container, Row } from 'react-bootstrap';
import { SingleImageCollageProps } from '../types';


const SingleImageCollage: React.FC<SingleImageCollageProps> = ({imageSrc}) => {

    return (
        <Container className='single-image-collage-wrapper'>
            <Row>
                <div className="single-image-collage-yellow-rectangle"></div>
                <img src={imageSrc} className="single-image-collage-image" alt="collageImage" />
                <div className="single-image-collage-green-rectangle"></div>
            </Row>
        </Container>
    );
    }

export default SingleImageCollage;
