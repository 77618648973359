import React from "react";
import './PricingPage.css'; 
import '../../Main.css';
import { Col, Container, Row } from "react-bootstrap";
import PricingComponent from "../organisms/pricing/PricingComponent";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { BsBuildings } from "react-icons/bs";
import { GiProgression } from "react-icons/gi";
import StyledLabel from "../atoms/label/StyledLabel";
import { useNavigate } from "react-router-dom";


const PricingPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <>
            <Container fluid className="pricing-main-wrapper">
                <Row className="centred-element" style={{ marginTop: "50px"}}>
                    <div className='color-paragraph'>Packages</div>
                </Row>
                <Row className="centred-element" style={{ marginTop: "5px"}}>
                    <h2>Our <span className="main-color">Pricing Plans</span></h2>
                </Row>
                <Row className="justify-content-center">
                    <StyledLabel align="center" label="Choose from three plans (Essentials, Professional, Enterprise) tailored to different needs and budgets." ></StyledLabel>
                </Row>
                <Row className="pricing-components-wrapper justify-content-space-between" style={{ marginTop: "50px"}}>
                    <Col style={{ marginTop: "20px"}}> 
                        <PricingComponent 
                            icon={<IoMdCheckmarkCircleOutline className="color-element"/>} 
                            title={"Essentials (Free):"} 
                            pricePerMonth={"$0.00"} 
                            benefitList={[
                                'Perfect for those primarily making introductions or with occasional lead/meeting needs.',
                                'Access to Introductable\'s matchmaking platform.',
                                'Ideal for exploring without commitment.',
                                'Basic access to meeting targets.',
                                'Email support.',
                            ]} 
                            linkHref='/signup'
                            onClickButton={() => navigate(`/signup`)}
                            buttonLabel='Free Account'
                        />
                    </Col>
                    <Col style={{ marginTop: "20px"}}> 
                        <PricingComponent 
                            icon={<GiProgression className="color-element"/>} 
                            title={"Professional"} 
                            pricePerMonth={"$599.00"} 
                            benefitList={[
                                'Designed for organizations with consistent demand generation needs.',
                                'Request support and outreach for unmatched meeting targets.',
                                'Priority outreach for high-demand meeting targets over Essentials.',
                                'Enhanced features and capabilities tailored for growing businesses.',
                                'Expanded access to a larger pool of qualified leads and potential connections.',
                                'Lowered meeting costs: $750/meeting',
                            ]} 
                            linkHref='/pricing/professional'
                            onClickButton={() => navigate(`/pricing/professional`)}
                            buttonLabel='Purchase Now'
                        />
                    </Col>
                    <Col style={{ marginTop: "20px"}}> 
                        <PricingComponent 
                            icon={<BsBuildings className="color-element"/>} 
                            title={"Enterprise"} 
                            pricePerMonth={"$1499.00"} 
                            benefitList={[
                                'Premium features for enterprise and high-volume users.',
                                'Unlimited access to Introductable\'s matchmaking platform with priority matching.',
                                'Top priority for AI and human assistance for unavailable meeting targets.',
                                'Priority access to highest-rated Introductors.',
                                'Personalized assistance and onboarding.',
                                'Meeting priority over Professional and Essential plans for high-demand targets.',
                                'Full access to all meeting targets.',
                                'Lowest meeting costs: $700/meeting',
                            ]} 
                            linkHref='/pricing/enterprise'
                            onClickButton={() => navigate(`/pricing/enterprise`)} 
                            buttonLabel='Purchase Now'
                        />
                    </Col>
                </Row>
                <Row style={{marginBottom: "20px"}}>

                </Row>
            </Container>
        </>
    );
};

export default PricingPage;

