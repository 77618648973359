import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Meeting } from "../../types/meeting";
import { getMeetingById } from "../../api/meetingApi";
import { Col, Container, Row } from "react-bootstrap";
import MeetingActionForm from "./MeetingActionForm";
import { MeetingSubstatusEnum, getUIText } from "../../types/meetingStatus";
import '../../Main.css';
import './MeetingPage.css';
import PersonDetailsComponent from "../molecules/persondetails/PersonDetailsComponent";
import StyledLabel from "../atoms/label/StyledLabel";
import ChatComponent from '../chat/ChatComponent';
import { DateUtils } from "../../utils/DateUtils";

const MeetingPage: React.FC = () => {
    const { meetingId } = useParams<{ meetingId: string }>();
    const [meeting, setMeeting] = useState<Meeting | null>(null);
    const chatStatuses: MeetingSubstatusEnum[] = [
        MeetingSubstatusEnum.PENDING_MEETING_PAID, 
        MeetingSubstatusEnum.PENDING_MEETING_PAID_NEW_PROPOSAL, 
        MeetingSubstatusEnum.PENDING_DETAILS_ADDED,
        MeetingSubstatusEnum.PENDING_MEETING_CONFIRMED,
        MeetingSubstatusEnum.PENDING_MEETING_RESCHEDULE_NEEDED,
    ]; 

    const fetchMeetingData = async (meetingId: string) => {
        try {
            const response = await getMeetingById(meetingId);
            setMeeting(response.data);
        } catch (error) {
            console.error('Error fetching meeting data:', error);
        }
    };

    useEffect(() => {
        try {
            if (meetingId) {
                fetchMeetingData(meetingId);
            }
        } catch (error) {
            console.error('Error fetching meeting data:', error);
        }
    
    }, [meetingId]);

    const handleMeetingActionFormSubmit = () => {
        if (meetingId) {
            fetchMeetingData(meetingId);
        }
    }

    return (
        <>
            <Container fluid style={{marginTop: '50px', paddingLeft: '20px', paddingRight: '20px'}}>
                <Row>
                    <Col>
                        <h3>Meeting Overview</h3>
                        <div style={{ display: 'flex', alignItems: 'center' }} className="align-items-center">
                            <div className='meeting-page-status-text'>Status:&nbsp;</div> 
                            <StyledLabel label={meeting && meeting.meetingSubstatus ? getUIText(meeting.meetingSubstatus) : ''} color="green" />
                        </div>        
                    </Col>
                                                                
                </Row>
                <Row style={{marginTop: '20px'}}>
                    <Col md={3}>
                        {meeting && (
                            <>
                                {
                                   meeting.meetingSubstatus !== MeetingSubstatusEnum.PENDING_INTRODUCTOR_ADDED && (
                                    <>
                                        <Row className='meeting-page-description-title'>
                                            <h4 style={{fontFamily: 'Raleway'}}>Meeting Title: {meeting.title}</h4>
                                        </Row>
                                        <Row style={{paddingTop: '10px', paddingLeft: '10px'}} className='meeting-page-description-wrapper'>
                                            <h5 style={{fontFamily: 'Raleway'}}>Scheduled Meeting Date Time:</h5>
                                            <p> {meeting.meetingDateTime ? DateUtils.formatReadableDateTime(meeting.meetingDateTime) : ''}</p>
                                        </Row>
                                        </>
                                )}
                                <Row style={{marginTop: '20px'}}>
                                    <PersonDetailsComponent 
                                        title={'Meeting Target'} 
                                        personName={`${meeting.expert?.name} ${meeting.expert?.lastName}`} 
                                        occupation={`${meeting.expert?.occupationList ? meeting.expert?.occupationList[0].title : ''}`} 
                                        company={`${meeting.expert?.occupationList ? meeting.expert?.occupationList[0].companyName : ''}`} 
                                    />
                                </Row>
                                <Row style={{marginTop: '20px'}}>
                                    <PersonDetailsComponent 
                                        title={'Introductor'} 
                                        personName={`${meeting.introductor?.name} ${meeting.introductor?.lastName}`} 
                                        occupation={`${meeting.introductor?.occupationList ? meeting.introductor?.occupationList[0].title : ''}`} 
                                        company={`${meeting.introductor?.occupationList ? meeting.introductor?.occupationList[0].companyName : ''}`} 
                                    />
                                </Row>
                                <Row style={{marginTop: '20px'}}>
                                    <PersonDetailsComponent 
                                        title={'Requestor'} 
                                        personName={`${meeting.interessant?.name} ${meeting.interessant?.lastName}`} 
                                        occupation={`${meeting.interessant?.occupationList ? meeting.interessant?.occupationList[0].title : ''}`} 
                                        company={`${meeting.interessant?.occupationList ? meeting.interessant?.occupationList[0].companyName : ''}`} 
                                    />
                                </Row>
                            </>
                        )}
                    </Col>
                    <Col md={6}>
                        {meeting && (
                            <>
                                <Row style={{marginLeft: '20px'}}>
                                    <MeetingActionForm 
                                            onSubmit={handleMeetingActionFormSubmit} 
                                            meeting={meeting}         
                                        /> 
                                </Row>
                            </>
                        )}
                    </Col>
                    <Col md={3}>
                        {
                            meeting && meeting.meetingId && meeting.introductor && meeting.interessant && meeting.meetingSubstatus && chatStatuses.includes(meeting.meetingSubstatus) && (
                                <ChatComponent meeting={meeting} />
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </>
    );
}


export default MeetingPage;

