import React from 'react';
import './BreakthroughTheNoisePage.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';
import FooterAddition from '../../footer/FooterAddition';
import { GoDotFill } from 'react-icons/go';

import blogImage from '../../../content/blog/breakthroughthenoise/BreakthroughTheNoiseImage.png';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';

const BreakthroughTheNoisePage: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
        <Container fluid className='justify-content-center'>

            <Row style={{paddingLeft: "20px", paddingTop: "50px", paddingRight: "20px", paddingBottom: "40px"}} className='justify-content-center'>
                <Row>
                    <Col className='breakthrough-the-noise-title'><h2>Breakthrough the Noise: <span className="main-color">Land Meetings with Hard-to-Reach</span> Prospects</h2></Col>
                </Row>
                <Row style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={blogImage} className="breakthrough-the-noise-title-image" alt="breakthroughTheNoiseImage" />
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                        In today's hyper-competitive sales landscape, getting face time with key decision-makers has never been more challenging. Your target prospects are inundated with cold outreach, to the point where they've built up an almost impenetrable fortress around their calendars. So how do you breakthrough the noise and book those critical discovery calls?
                    </p>
                    <p>
                        Enter the "introductable" approach - a strategic, referral-based outreach method that leverages your prospects’ own network to gain access to otherwise untouchable prospects.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>What is the 'Introductable' Technique?</b></h5>
                    <p>
                    The premise of the "introductable" is simple: instead of going direct with a cold email or call, introductable arranges for a connection who can personally introduce you. This pathway through a trusted intermediary results in a 500X increase in your chances of getting a response and securing that all-important meeting.
                    </p>
                    <p>
                    The key is identifying the right person who has a strong relationship with your target prospect. It could be a friend, colleague, industry peer, or even a client. The crucial factor is that this mutual connection must be "introductable" - meaning they have enough credibility and goodwill with the prospect to vouch for you.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>The Science Behind the 'Introductable' Advantage</b></h5>
                    <p>
                        There's a wealth of behavioral psychology research that explains why the "introductable" approach is so effective:
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <GoDotFill className='main-color'/> <b>1. Overcoming the Gatekeeper Effect:</b> When you come in through a mutual connection, you bypass the natural skepticism and resistance that prospects have built up towards cold outreach. The introduction establishes instant trust and credibility.
                        <br/>
                        <GoDotFill className='main-color'/> <b>2. Leveraging Social Proof:</b> People are heavily influenced by the recommendations and endorsements of people they know and respect. An introduction from a trusted source carries far more weight than a cold pitch.
                        <br/>
                        <GoDotFill className='main-color'/> <b>3. Triggering Reciprocity:</b> By accepting an introduction, the prospect now feels a sense of obligation to reciprocate by granting you a meeting. They don't want to let down their mutual connection.
                        <br/>
                        <GoDotFill className='main-color'/> <b>4. Tapping into Existing Relationships:</b> Shared connections foster a feeling of familiarity and comfort, making prospects more open to engaging with you.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5><b>Unlock the Power of Referrals</b></h5>
                    <p>
                        In an era of relentless digital noise, the "introductable" approach stands out as a refreshingly human, relationship-driven tactic. By leveraging your prospects’ network, you can breakthrough the barriers that keep your hardest-to-reach prospects at arm's length. With persistence and the right mutual connections, you can turn those untouchable targets into valuable new clients.
                    </p>
                </Row>
                <Row className='justify-items-start' style={{ marginTop: '35px', marginBottom: '25px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
    }

export default BreakthroughTheNoisePage;
