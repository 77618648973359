import React from 'react';
import './LeadGenerationTypesPage.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';
import FooterAddition from '../../footer/FooterAddition';
import { GoDotFill } from 'react-icons/go';

import blogImage from '../../../content/blog/leadgeneration/LeadGenerationTypesImage.png';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';

const LeadGenerationTypesPage: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
        <Container fluid className='justify-content-center'>

            <Row style={{paddingLeft: "20px", paddingTop: "50px", paddingRight: "20px", paddingBottom: "40px"}} className='justify-content-center'>
                <Row>
                    <Col className='lead-generation-types-title'><h2>Lead Generation Types and Statistics: <span className="main-color">How to Feed the Funnel</span></h2></Col>
                </Row>
                <Row style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={blogImage} className="lead-generation-types-title-image" alt="agencyShowdownImage" />
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                        In today's fiercely competitive business landscape, the ability to consistently attract and convert potential customers is the lifeblood of any successful enterprise. With 61% of marketers citing lead generation as their top challenge (HubSpot, 2021), understanding and leveraging various lead generation strategies has never been more critical.
                    </p>
                    <p>
                        This article delves into seven key types of lead generation and we'll explore not just the 'what' and 'how' of each approach, but also provide you with hard data and statistics that underscore their effectiveness. 
                    </p>
                    <p>
                        As we navigate through content marketing, social media strategies, email campaigns, SEO techniques, PPC advertising, referral programs, and virtual events, remember that the most successful lead generation strategies are often those that combine multiple approaches, tailored to your specific audience and business goals. Let's dive in and explore the numbers behind these powerful lead generation types.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h4>1. Content Marketing</h4>
                    <p>
                        Content marketing involves creating and sharing valuable content to attract and convert prospects into customers.
                        <br/>
                    </p>
                    <p>
                        <b>Statistics:</b>
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <GoDotFill className='main-color'/> 70% of marketers actively invest in content marketing (HubSpot, 2020)
                        <br/>
                        <GoDotFill className='main-color'/> Content marketing generates 3 times as many leads as outbound marketing but costs 62% less (DemandMetric)
                        <br/>
                        <GoDotFill className='main-color'/> Businesses with blogs produce 67% more leads per month than those without (DemandMetric)
                        <br/>
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h4>2. Social Media Marketing</h4>
                    <p>
                        Social media platforms offer powerful tools for engaging potential customers and generating leads.
                        <br/>
                    </p>
                    <p>
                        <b>Statistics:</b>
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <GoDotFill className='main-color'/> 54% of social browsers use social media to research products (GlobalWebIndex, 2018)
                        <br/>
                        <GoDotFill className='main-color'/> LinkedIn is responsible for 80% of B2B leads from social media (LinkedIn)
                        <br/>
                        <GoDotFill className='main-color'/> 66% of marketers report that social media generates leads (Salesforce)
                        <br/>
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h4>3. Email Marketing</h4>
                    <p>
                        Despite being one of the oldest digital marketing channels, email remains a highly effective lead generation tool.
                        <br/>
                    </p>
                    <p>
                        <b>Statistics:</b>
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <GoDotFill className='main-color'/> Email marketing has an ROI of 4200% (DMA, 2019)
                        <br/>
                        <GoDotFill className='main-color'/> 87% of B2B marketers say email is one of their top free organic distribution channels (Content Marketing Institute, 2020)
                        <br/>
                        <GoDotFill className='main-color'/> Segmented email campaigns can lead to a 760% increase in revenue (Campaign Monitor)
                        <br/>
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h4>4. Search Engine Optimization (SEO)</h4>
                    <p>
                        Optimizing your website and content for search engines can significantly boost organic lead generation.
                        <br/>
                    </p>
                    <p>
                        <b>Statistics:</b>
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <GoDotFill className='main-color'/> 61% of B2B marketers state that SEO and organic traffic generate more leads than any other marketing initiative (HubSpot, 2020)
                        <br/>
                        <GoDotFill className='main-color'/> The first five organic results in Google account for 67.60% of all clicks (Zero Limit Web, 2020)
                        <br/>
                        <GoDotFill className='main-color'/> 76% of people who search for something nearby on their smartphone visit a related business within a day (Google)
                        <br/>
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h4>5. Pay-Per-Click (PPC) Advertising</h4>
                    <p>
                    PPC campaigns can quickly drive targeted traffic and generate leads, especially for new businesses or products.
                        <br/>
                    </p>
                    <p>
                        <b>Statistics:</b>
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <GoDotFill className='main-color'/> PPC visitors are 50% more likely to purchase something than organic visitors (Unbounce)
                        <br/>
                        <GoDotFill className='main-color'/> The average conversion rate in AdWords across all industries is 3.75% for search and 0.77% for display (WordStream, 2021)
                        <br/>
                        <GoDotFill className='main-color'/> For every $1 spent on Google Ads, businesses earn an average revenue of $2 (Google)
                        <br/>
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h4>6. Referral Marketing</h4>
                    <p>
                        Referral marketing, also known as word-of-mouth marketing, is a strategy that encourages and incentivizes existing customers and networks to recommend a company's products or services to their friends, family, and colleagues. This method leverages the trust and credibility of personal relationships to generate high-quality leads.
                        <br/>
                    </p>
                    <p>
                        <b>Key Components of Referral Marketing:</b>
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <GoDotFill className='main-color'/> <b>1. Customer Advocacy:</b> Turning satisfied customers into brand advocates who willingly promote your products or services.
                        <br/>
                        <GoDotFill className='main-color'/> <b>2. Incentive Programs:</b> Offering rewards, discounts, or other benefits to both the referrer and the new customer to encourage participation.
                        <br/>
                        <GoDotFill className='main-color'/> <b>3. Referral Tracking:</b> Implementing systems to track referrals and attribute new customers to their sources.
                        <br/>
                        <GoDotFill className='main-color'/> <b>4. Multi-Channel Approach:</b> Utilizing various channels like email, social media, and in-person interactions to facilitate referrals.
                        <br/>
                        <GoDotFill className='main-color'/> <b>5. Seamless User Experience:</b> Creating easy-to-use referral processes that don't require significant effort from customers.
                    </p>
                    <p>
                        <b>Benefits of Referral Marketing:</b>
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <GoDotFill className='main-color'/> <b>Higher Trust:</b> Leads from referrals are typically pre-qualified and more likely to trust your brand.
                        <br/>
                        <GoDotFill className='main-color'/> <b>Cost-Effective:</b> Often less expensive than traditional advertising methods.
                        <br/>
                        <GoDotFill className='main-color'/> <b>Higher Conversion Rates:</b> Referred leads tend to convert at higher rates than leads from other sources.
                        <br/>
                        <GoDotFill className='main-color'/> <b>Increased Customer Lifetime Value:</b> Referred customers often have higher retention rates and spend more over time.
                        <br/>
                    </p>
                    <p>
                        <b>Benefits of Introductable</b>
                        <br/>
                        Introductable is the most effective referral marketing platform in the world with Introductable having a 7x higher close rates compared to traditional methods like PPC, content marketing, and email marketing. Other benefits include:
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <GoDotFill className='main-color'/> Close times for Introductable leads are 40% shorter than those from other lead generation methods
                        <br/>
                        <GoDotFill className='main-color'/> The average lifetime value of an Introductable customer is more than 156% higher than non-Introductable customers
                        <br/>
                    </p>
                    <p>
                        <b>Statistics:</b>
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <GoDotFill className='main-color'/> 92% of consumers trust referrals from people they know (Nielsen)
                        <br/>
                        <GoDotFill className='main-color'/> Referred customers have a 37% higher retention rate (Deloitte)
                        <br/>
                        <GoDotFill className='main-color'/> B2B companies with referrals have a 70% higher conversion rate (Influitive)
                        <br/>
                        <GoDotFill className='main-color'/> Referral leads convert 30% better than leads generated from other marketing channels (R&G Technologies)
                        <br/>
                        <GoDotFill className='main-color'/> 83% of satisfied customers are willing to refer products and services, but only 29% actually do (Texas Tech University)
                        <br/>
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h4>7. Webinars and Virtual Events</h4>
                    <p>
                        Webinars and virtual events provide opportunities to showcase expertise and generate qualified leads.
                        <br/>
                    </p>
                    <p>
                        <b>Statistics:</b>
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <GoDotFill className='main-color'/> 73% of B2B marketers and sales leaders say a webinar is the best way to generate high-quality leads (GoToWebinar, 2019)
                        <br/>
                        <GoDotFill className='main-color'/> 20-40% of webinar attendees turn into qualified leads (ReadyTalk)
                        <br/>
                        <GoDotFill className='main-color'/> 91% of professionals say webinars are their preferred learning method (Wyzowl, 2020)
                        <br/>
                    </p>
                    <p>
                        Introductable is an innovative lead generation method that leverages personal connections and targeted introductions to create high-quality leads.
                    </p>
                    <p>
                        <b>Statistics:</b>
                    </p>
                    <p style={{paddingLeft: '20px'}}>
                        <GoDotFill className='main-color'/> Introductable leads have 7x higher close rates compared to traditional methods like PPC, content marketing, and email marketing
                        <br/>
                        <GoDotFill className='main-color'/> Close times for Introductable leads are 40% shorter than those from other lead generation methods
                        <br/>
                        <GoDotFill className='main-color'/> The average lifetime value of an Introductable customer is more than 156% higher than non-Introductable customers
                        <br/>
                    </p>
                    <p>
                        These impressive statistics highlight the effectiveness of the Introductable method in not only generating leads but also in producing higher-quality leads that convert faster and provide more long-term value to businesses.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                        The introduction of Introductable as a lead generation method presents a compelling option for businesses looking to improve their conversion rates and customer lifetime value. Its significantly higher close rates and shorter close times compared to traditional methods like PPC, content marketing, and email marketing make it an attractive choice for many businesses.
                    </p>
                    <p>
                        The introduction of Introductable as a lead generation method presents a compelling option for businesses looking to improve their conversion rates and customer lifetime value. Its significantly higher close rates and shorter close times compared to traditional methods like PPC, content marketing, and email marketing make it an attractive choice for many businesses.
                    </p>
                    <p>
                        Remember, the key to success lies not just in implementing these strategies, but in continuously analyzing their performance, adapting to changes in consumer behavior, and refining your approach to maximize results. Consider experimenting with a combination of these methods, including the promising Introductable approach, to find the optimal lead generation mix for your business.
                    </p>
                </Row>
                
                <Row className='justify-items-start' style={{ marginTop: '35px', marginBottom: '25px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
    }

export default LeadGenerationTypesPage;
