import React from 'react';
import './StyledLink.css';
import { StyledLinkProps } from '../types';
import { Link } from 'react-router-dom';

const StyledLink: React.FC<StyledLinkProps> = ({ href, children, align = 'right', bold = false, ...props }) => {
  const alignmentClass = align === 'right' ? 'custom-link-align-right' : 'custom-link-align-left';
  const boldClass = bold ? 'custom-link-bold' : '';
  return (
    <Link className={`custom-link ${alignmentClass} ${boldClass}`} to={href} {...props}>
      {children}
    </Link>
  );
};

export default StyledLink;
