import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SearchBar from './SearchBar';
import { getPersonLightSearch, getPersonSearch } from '../../api/searchApi';
import StyledTable from '../molecules/table/styledtable/StyledTable';
import { useAuthContext } from '../../App';
import StyledLink from '../atoms/link/StyledLink';
import '../../Main.css';
import { FaArrowCircleUp } from 'react-icons/fa';

const ResultPage: React.FC = () => {
    const navigate = useNavigate();
    const { token } = useAuthContext();
    const [searchParams] = useSearchParams();
    const [searchResultsTableData, setSearchResultsTableData] = useState<string[][]>([]);
    const [tableRowIds, setTableRowIds] = useState<string[]>([]);
    const [keywords, setKeywords] = useState<string>('');
    const [numberOfResults, setNumberOfResults] = useState<number>(0);
    const SEARCH_RESULTS_TABLE_HEADERS = ['First Name', 'Last Name', 'Job Title', 'Company Name', 'Location'];

    const onSearch = (searchText: string) => {
        setKeywords(searchText);
        navigate(`/search/result?keywords=${searchText}`);
    }

    const goToExpert = (expertId: string) => {
        navigate(`/experts/${expertId}`);
    }

    const handleGoUpClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    useEffect(() => {
        setKeywords(searchParams.get('keywords') || '');

        if (keywords) {
            const fetchSearchResults = async () => {
                getPersonSearch(keywords.split(' '))
                .then(response => {
                    const tableData: React.SetStateAction<string[][]> = [];
                    const ids: React.SetStateAction<string[]>  = [];
                    setNumberOfResults(response.data.numberOfResults);

                    response.data.searchResult.map((expert) => {
                        const expertRow = [
                            expert.name ?? '-',
                            expert.lastName ?? '-',
                            expert.title ?? '-',
                            expert.companyName ?? '-',
                            (expert.country ? expert.country : '') + ' ' + (expert.city ? expert.city : '')
                    ];
                        tableData.push(expertRow);
                        ids.push(expert.personId);
                    })

                    setSearchResultsTableData(tableData);
                    setTableRowIds(ids);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
            }

            const fetchLightSearchResults = async () => {
                getPersonLightSearch(keywords.split(' ')).then( response => 
                    setNumberOfResults(response.data.numberOfResults)
                )
            }

            if (token !== '' && token !== undefined && token != null) {
                fetchSearchResults();
            } else {
                fetchLightSearchResults();
            }
        }
    }, [keywords, searchParams, token]);

    return (
        <Container style={{minHeight: 'calc(100vh - 100px)'}}>
            <Row style={{paddingTop: "20px"}}>
                <SearchBar onSearch={onSearch} searchText={searchParams.get('keywords') || ''} />
            </Row>
            {
                keywords && (searchResultsTableData.length > 0 ? (
                        <>
                            <Row style={{ marginTop: "20px"}}>
                                <Col><h4>{`Showing results ${tableRowIds.length} of ${numberOfResults} for:`} <span className="highlighted-keywords">{keywords}</span></h4></Col>
                            </Row>
                            <Row className="lp-search-container">
                                <StyledTable 
                                        tableTitle=''
                                        tableHeaders={SEARCH_RESULTS_TABLE_HEADERS} 
                                        tableData={searchResultsTableData} 
                                        tableRowsIds={tableRowIds}
                                        onClickRow={goToExpert}
                                    />
                            </Row>
                        </>
                ) : (
                    numberOfResults > 0 ? 
                    <>
                        <Row style={{ marginTop: "20px"}}>
                            <h4>Slow down, tiger! You need to log in or to create an account first!</h4>
                            <h5>{`There are ${numberOfResults} search results for:`} <span className="highlighted-keywords">{keywords}</span></h5>
                        </Row>
                        <Row style={{ marginTop: "20px"}}>
                            <p>
                                It's not just about seeing results - it's about getting your own personalized experience. We'll remember your likes, keep your info safe, and make your experience awesome. Plus, it helps us improve our results for you. 
                            </p>
                        </Row>
                        <Row>
                            <Col><StyledLink href={'/signup'}><b style={{fontSize: '16px'}}>Sign up here to see full results</b></StyledLink></Col>
                        </Row>
                    </>
                    : 
                    <Row style={{ marginTop: "20px"}}>
                        <h3 className='main-color'>It happens!</h3>
                        <h4>No results for: <span className="grey-highlighted-keywords">{keywords}</span></h4>
                        <p>Even we don’t know everyone in the world!</p>
                        <p>Just checking… are you sure you typed their name correctly? Maybe try searching by company or title instead?</p>
                        <p>If that still doesn’t work, <StyledLink href={'mailto:info@introductable.com'}>let us know</StyledLink> who they are, along with any info you have and we will get them added!</p>
                    </Row>
                ))
            }
            {
                numberOfResults > 20 &&
                <Row style={{ marginTop: "20px"}}>
                    <Col>
                        <p>
                            <b>How bold of you!</b> Or maybe just too broad because we have a lot of names returned! 
                            How about refining your search a bit? <FaArrowCircleUp className='main-color' style={{fontSize: '20px'}} onClick={handleGoUpClick}/>
                        </p>
                    </Col>

                </Row>
            }
            <Row style={{ marginTop: "20px"}}>

            </Row>

        </Container>
    );
}

export default ResultPage;