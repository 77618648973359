import { SelectOption } from "../components/atoms/types";

export enum Country {
    USA = "United States of America",
    UK = "United Kingdom",
    POL = "Poland",
    CAN = "Canada",
    MEX = "Mexico",
    BRA = "Brazil",
    ARG = "Argentina",
    FRA = "France",
    GER = "Germany",
    ITA = "Italy",
    RUS = "Russia",
    IND = "India",
    CHN = "China",
    JPN = "Japan",
    AUS = "Australia",
    RSA = "South Africa"
}

export const COUNTRIES_SELECT_LIST: SelectOption[] = Object.entries(Country).map(([code, name]) => {
    return { value: code, label: name };
});