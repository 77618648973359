import React, { ChangeEvent, useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import optionalInfoPicture from '../../content/account/profile_optional.jpeg';
import stepperThree from '../../content/account/stepper_3.svg';
import logo from '../../content/logo/logo_opacity.png';
import './NewUser.css';
import '../../Main.css';
import StyledInput from '../atoms/input/StyledInput';
import StyledActionButton from '../atoms/button/StyledActionButton';
import { getPersonById, updatePerson } from '../../api/personApi';
import { useAuthContext } from '../../App';
import { Person } from '../../types/person';
import { DateUtils, FORMATTED_MONTHS_LIST, FORMATTED_YEARS_LIST, MonthYear } from '../../utils/DateUtils';
import StyledSelect from '../atoms/select/StyledSelect';

const OptionalDetailsNewUser: React.FC = () => {
    const navigate = useNavigate();
    const { user } = useAuthContext();
    const [person, setPerson] = useState<Person>();
    const [lastDateSelect, setLastDateSelect] = useState<MonthYear>({
        month: '',
        year: ''
    });
    const [optionalUserInfo, setOptionalUserInfo] = useState({
        collegeName: '',
        linkedinUrl: '',
        lastRole: '',
        lastCompany: '',
        lastCompanyStartDate: '',
        lastCompanyEndDate: ''
    });

    useEffect(() => {
        if(user.personId) {
            getPersonById(user.personId)
            .then( respnse => 
                setPerson(respnse.data)
            )
        }
    }, [])
  
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const { name, value } = event.target;
      setOptionalUserInfo(prevState => ({
        ...prevState,
        [name]: value,
      }));
    };
  
    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (user.personId) {
        updatePerson(user.personId, {    
            name: person?.name || '',
            lastName: person?.lastName || '',
            city: person?.city,
            country: person?.country,
            timezone: person?.timezone || '',
            linkedin: optionalUserInfo.linkedinUrl,
            occupationList: [
                ...(person?.occupationList || []),
                {
                    title: optionalUserInfo.lastRole,
                    companyName: optionalUserInfo.lastCompany,
                    startDate: DateUtils.formatDate(lastDateSelect.month, lastDateSelect.year)
                }
            ],
            educationList: [{universityName: optionalUserInfo.collegeName}]
        }).then( () => {
            navigate('/profile/resume');
          })
      }
      
    };

    const handleSelectDateChange = (e: ChangeEvent<HTMLSelectElement>) => {
        setLastDateSelect({...lastDateSelect, [e.target.name]: e.target.value});
      };
  
    return (
      <Container fluid >
                <Row >
                    <Col md={4} style={{ padding: 0 }}>
                        <img src={optionalInfoPicture} className='profile-image' alt="optionalInfoPicture" />
                    </Col>
                    <Col md={8} style={{ paddingLeft: "10%", paddingRight: "10%", paddingTop: "10%" }}>
                        <Row>
                            <img src={logo} style={{ width: "50%", height: "100%", objectFit: "cover" }} alt="Logo" />
                        </Row>
                        <Row>
                            <img src={stepperThree} style={{ width: "100%", height: "100%", objectFit: "cover", paddingTop: "20px" }} alt="stepper" />
                        </Row>
                        <Row style={{paddingTop: "34px"}}>
                            <Row>
                                <h4>We need just a bit of info before we <span className="main-color">Get Started</span></h4>
                            </Row>
                            <Row style={{paddingTop: "10px"}}>
                                <p>You’re just a few steps away from finding your meeting.</p>
                            </Row>
                        </Row>
                        <Row>
                      <Form onSubmit={handleSubmit}>
                        <Form.Group style={{paddingTop: "16px"}}>
                              <StyledInput 
                                  type="text"
                                  name="collegeName"
                                  value={optionalUserInfo.collegeName || ''}
                                  onChange={handleChange}
                                  placeholder='College name'   
                              />
                          </Form.Group>
                        <Form.Group style={{paddingTop: "16px"}}>
                              <StyledInput 
                                  type="text"
                                  name="linkedinUrl"
                                  value={optionalUserInfo.linkedinUrl || ''}
                                  onChange={handleChange}
                                  placeholder='Your Linkedin Profile URL'   
                              />
                          </Form.Group>
                        <Form.Group style={{paddingTop: "16px"}}>
                              <StyledInput 
                                  type="text"
                                  name="lastRole"
                                  value={optionalUserInfo.lastRole || ''}
                                  onChange={handleChange}
                                  placeholder='Last Role'     
                              />
                          </Form.Group>
                        <Row>
                        <Form.Group style={{paddingTop: "16px"}}>
                              <StyledInput 
                                  type="text"
                                  name="lastCompany"
                                  value={optionalUserInfo.lastCompany || ''}
                                  onChange={handleChange}
                                  placeholder='Last Company'
                              />
                          </Form.Group>
                        </Row>
                        <Row>
                          <Col md={6} xs={12}>
                              <Form.Group style={{paddingTop: "16px"}}>
                                  <StyledSelect
                                      onChangeSelect={handleSelectDateChange}
                                      options={FORMATTED_MONTHS_LIST} 
                                      name={'month'} 
                                      value={lastDateSelect.month}  
                                      placeholder='Company Start Month'                        
                                    />
                              </Form.Group>
                          </Col>
                          <Col md={6} xs={12}>
                            <Form.Group style={{paddingTop: "16px"}}>
                                <StyledSelect
                                  onChangeSelect={handleSelectDateChange}
                                  options={FORMATTED_YEARS_LIST} 
                                  name={'year'} 
                                  value={lastDateSelect.year}  
                                  placeholder='Company Start Year'                            
                                />
                            </Form.Group>
                          </Col>
                        </Row>
                          <Row style={{paddingTop: "16px", paddingLeft: "12px", paddingRight: "12px", paddingBottom: '20px'}}>
                            <StyledActionButton label={'Submit'} type="submit" />
                          </Row>
                        </Form>
                        </Row>
                    </Col>
                </Row>
            </Container>
    );
}

export default OptionalDetailsNewUser;