import React from 'react';
import './AgencyShowdownPage.css';
import '../../../Main.css';
import { Col, Container, Row } from 'react-bootstrap';
import FooterAddition from '../../footer/FooterAddition';
import { GoDotFill } from 'react-icons/go';

import blogImage from '../../../content/blog/agencyshowdown/agencyShowdownImage.png';
import CircledButton from '../../atoms/button/CircledButton';
import { useNavigate } from 'react-router-dom';

const AgencyShowdownPage: React.FC = () => {
    const navigate = useNavigate();
    return (
        <>
        <Container fluid className='justify-content-center'>

            <Row style={{paddingLeft: "20px", paddingTop: "50px", paddingRight: "20px", paddingBottom: "40px"}} className='justify-content-center'>
                <Row>
                    <Col className='agency-showdown-title'><h2>Agency Showdown! <span className="main-color">PFP vs Traditional,</span> Who Ya Got?</h2></Col>
                </Row>
                <Row style={{ marginTop: '20px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <img src={blogImage} className="agency-showdown-title-image" alt="agencyShowdownImage" />
                </Row>
                <Row>
                    <h5>Choosing the Right Marketing Partner: PFP vs Traditional Agencies (feat. Introductable)</h5>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                        Hey there, digital go-getters! Picking the right marketing partner can make or break your business growth. Let's chat about two models: Pay-for-Performance (PFP) agencies and Traditional agencies. Both have their pros and cons, so let's break it down and see how they stack up against each other - and where our rising star, Introductable, fits into the mix.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>What's the Deal with Pay-for-Performance Agencies?</h5>
                    <p>
                        A Pay-for-Performance (PFP) marketing agency only charges clients based on the results they generate. This could mean paying for clicks, leads, conversions, or sales. The model aligns agency compensation directly with the outcomes they deliver, often incentivizing more aggressive and strategic marketing tactics.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>And Traditional Agencies?</h5>
                    <p>
                        Think of traditional agencies as the old-school cool kids. They usually work on a "you pay, we play" model with fixed fees, hourly rates, or retainers. They're all about the big picture - brand building, creative stuff, and long-term strategies.They generally have more experience on staff.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>The Good Stuff About PFP Agencies</h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        <GoDotFill className='main-color'/> <b>1. Less Risk, More Reward:</b> You're basically paying for guaranteed results. Nice, right?
                        <br/>
                        <GoDotFill className='main-color'/> <b>2. They're on Their Toes:</b> These guys are super motivated to perform because, well, their paycheck depends on it.
                        <br/>
                        <GoDotFill className='main-color'/> <b>3. Wallet-Friendly:</b> Great for smaller businesses or startups who want to keep things lean and mean.
                        <br/>
                        <GoDotFill className='main-color'/> <b>4. Innovation Nation:</b> PFP agencies are always cooking up new ideas to boost performance.
                        <br/>
                        <GoDotFill className='main-color'/> <b>5. Introductable</b> slots neatly into their model.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>The Not-So-Great Stuff About PFP Agencies</h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        <GoDotFill className='main-color'/> <b>1. Short-Term Focus:</b> They might be so focused on quick wins that they neglect long-term brand love.
                        <br/>
                        <GoDotFill className='main-color'/> <b>2. Metric Mayhem:</b> Sometimes, what they're measuring might not line up with what really matters to your business.
                        <br/>
                        <GoDotFill className='main-color'/> <b>3. Success Can Be Pricey:</b> If they're killing it, you might end up shelling out more than you bargained for.
                        <br/>
                        <GoDotFill className='main-color'/> <b>4. Jack of Some Trades:</b> They might not offer the full buffet of marketing services you're after.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>Why Traditional Agencies Rock</h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        <GoDotFill className='main-color'/> <b>1. Big Picture Thinking:</b> They're all about that long-term strategy and brand building.
                        <br/>
                        <GoDotFill className='main-color'/> <b>2. Creative Playground:</b> More room for out-of-the-box ideas that might not have an immediate payoff but could be game-changers.
                        <br/>
                        <GoDotFill className='main-color'/> <b>3. One-Stop Shop:</b> Need a bit of everything? Traditional agencies usually have you covered.
                        <br/>
                        <GoDotFill className='main-color'/> <b>4. Predictable Costs:</b> You know exactly what you're paying, come rain or shine.
                        <br/>
                        <GoDotFill className='main-color'/> <b>5. Introductable</b> works with some of the largest agencies out there, providing a very tangible and easy to see ROI.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>Why Traditional Agencies Might Not Be Your Jam</h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        <GoDotFill className='main-color'/> <b>1. No Guarantees:</b> You're paying whether the campaign is a hit or a miss.
                        <br/>
                        <GoDotFill className='main-color'/> <b>2. Upfront Costs:</b> They often need a chunk of change to get started, which can be tough for smaller businesses.
                        <br/>
                        <GoDotFill className='main-color'/> <b>3. Slow and Steady:</b> They might not be as quick to pivot when things aren't working out.
                        <br/>
                        <GoDotFill className='main-color'/> <b>4. Playing the Long Game:</b> If you need results like, yesterday, their focus on long-term strategies might feel too slow.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5> Enter Introductable: The Best of Both Worlds?</h5>
                    <p>
                        Now, let's talk about Introductable. These guys are shaking things up by blending the best of both worlds. They're like the cool new kid on the block who gets along with everyone.
                    </p>
                </Row>
                <Row>
                    <p>
                        Introductable offers the accountability and results-driven approach of a PFP model, but with a broader vision that traditional agencies bring to the table. They're all about driving immediate results while also keeping an eye on long-term brand building.
                    </p>
                </Row>
                <Row>
                    <p>
                        What sets Introductable apart is their focus on introductions - connecting businesses with potential clients or partners. It's not just about clicks or leads; it's about making meaningful connections that can drive real business growth.
                    </p>
                </Row>
                <Row>
                    <p>
                        So, Which One's Right for You?
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <h5>It really depends on what you're after:</h5>
                    <p style={{paddingLeft: '20px', paddingTop: '10px'}}>
                        <GoDotFill className='main-color'/> If you're a startup or small business looking for quick, measurable results without breaking the bank, a PFP agency might be your best bet.
                        <br/>
                        <GoDotFill className='main-color'/> If you're a bigger player looking for comprehensive marketing solutions and long-term brand building, a traditional agency could be the way to go.
                        <br/>
                        <GoDotFill className='main-color'/> But if you want a mix of both - immediate results and strategic thinking, plus a focus on making valuable business connections - there are agencies that can provide both. If they don’t include Introductable in their portfolio of offerings, they may not be investing in research and keeping up with the latest trends.
                    </p>
                </Row>
                <Row style={{ marginTop: '20px' }}>
                    <p>
                        Wrapping It Up
                    </p>
                </Row>
                <Row>
                    <p>
                        At the end of the day, both PFP and Traditional agencies have their strengths. It's all about what works for your business, your budget, and your goals. 
                    </p>
                </Row>
                <Row>
                    <p>
                        Remember, the right marketing partner is out there - it's just about finding the perfect match for your business's needs and ambitions. Happy hunting!
                    </p>
                </Row>
                <Row className='justify-items-start' style={{ marginTop: '35px', marginBottom: '25px' }}>
                    <CircledButton 
                        label={'Start an Account'} 
                        onClick={() => {navigate('/signup')}}
                        transitionX={'30px'} />
                </Row>
            </Row>
        </Container>
        <FooterAddition />
        </>
    );
    }

export default AgencyShowdownPage;
