export enum MeetingStatusEnum {
    PENDING = "PENDING",
    SUCCESS = "SUCCESS",
    REFUNDED = "REFUNDED",
    CANCELED = "CANCELED",
    FAILED = "FAILED",
}

export enum MeetingSubstatusEnum {
    PENDING_REQUESTED = "PENDING_REQUESTED",
    PENDING_INTRODUCTOR_ADDED = "PENDING_INTRODUCTOR_ADDED",
    PENDING_DETAILS_ADDED = "PENDING_DETAILS_ADDED",
    PENDING_MEETING_REQUESTED = "PENDING_MEETING_REQUESTED",
    PENDING_MEETING_CONFIRMED = "PENDING_MEETING_CONFIRMED",
    PENDING_MEETING_PAID = "PENDING_MEETING_PAID",
    PENDING_MEETING_PAID_NEW_PROPOSAL = "PENDING_MEETING_PAID_NEW_PROPOSAL",
    PENDING_DETAILS_ADDED_NEW_PROPOSAL = "PENDING_DETAILS_ADDED_NEW_PROPOSAL",
    PENDING_MEETING_RESCHEDULE_NEEDED = "PENDING_MEETING_RESCHEDULE_NEEDED",
    PENDING_MEETING_IN_PROGRESS = "PENDING_MEETING_IN_PROGRESS",
    SUCCESS_NO_REVIEW = "SUCCESS_NO_REVIEW",
    SUCCESS_REVIEWED = "SUCCESS_REVIEWED",
    CANCELED_INTRODUCTOR_ALTERNATIVE = "CANCELED_INTRODUCTOR_ALTERNATIVE",
    REFUND = "REFUND",
    CANCELED = "CANCELED",
    FAILED = "FAILED",
}


export function getUIText(status: MeetingSubstatusEnum): string {
    switch (status) {
        case MeetingSubstatusEnum.PENDING_REQUESTED:
            return "Meeting Requested";

        case MeetingSubstatusEnum.PENDING_MEETING_REQUESTED:
            return "Meeting Date Requested";

        case MeetingSubstatusEnum.PENDING_INTRODUCTOR_ADDED:
            return "Pay Now";

        case MeetingSubstatusEnum.PENDING_DETAILS_ADDED:
            return "All Meeting Details Added";

        case MeetingSubstatusEnum.PENDING_MEETING_PAID:
            return "Meeting Paid";

        case MeetingSubstatusEnum.PENDING_MEETING_PAID_NEW_PROPOSAL:
            return "Meeting Paid New Time Proposals";

        case MeetingSubstatusEnum.PENDING_DETAILS_ADDED_NEW_PROPOSAL:
            return "Meeting Paid New Time Proposals";

        case MeetingSubstatusEnum.PENDING_MEETING_CONFIRMED:
            return "Meeting Confirmed";

        case MeetingSubstatusEnum.PENDING_MEETING_IN_PROGRESS:
            return "Meeting in Progress";

        case MeetingSubstatusEnum.PENDING_MEETING_RESCHEDULE_NEEDED:
            return "Meeting Rescheduled";

        case MeetingSubstatusEnum.SUCCESS_NO_REVIEW:
            return "Meeting Proceed";

        case MeetingSubstatusEnum.SUCCESS_REVIEWED:
            return "Meeting Successful";

        case MeetingSubstatusEnum.REFUND:
            return "Refunded";

        case MeetingSubstatusEnum.CANCELED_INTRODUCTOR_ALTERNATIVE:
            return "Meeting Cancelled";

        case MeetingSubstatusEnum.CANCELED:
            return "Meeting Canceled";

        case MeetingSubstatusEnum.FAILED:
            return "Meeting Failed";

        default:
            return "Unknown Status";
    }
}