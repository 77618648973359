import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { ActionFormProps } from './ActionFormTypes';
import '../../../../Main.css';

const PendingAllGoodRequestorNewTimesForm: React.FC<ActionFormProps> = ({ isIntroductor }) => {
    return (
        <Container>
            <Row style={{marginTop: '20px'}}>
              <h4>You are all set for now</h4>  
            </Row>
            <Row style={{marginTop: '20px'}}>
              <p>
                Just need to double check with Introductor that the time is still good! 
                If the time still works, a meeting on your calendar! If the time doesn't work, look for new proposed times. 
              </p>
              <p>
                Isn't finding meeting times fun?
              </p>
            </Row>
        </Container>
    );
}

export default PendingAllGoodRequestorNewTimesForm;
